import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionsAtendimento from '../../domain/actions/actionsAtendimento';

class LogicaGraficos extends Component {
  componentDidMount() {
    this.props.pesquisarTotalAtendimentosPorTipos();
    this.props.pesquisarTotalMensalPorCliente();
  }

  sendData = () => {
    var totalAtendimentosPorDiaPorTipo = [];
    if (this.props.totalAtendimentosPesquisadosPorTipo)
      totalAtendimentosPorDiaPorTipo =
        this.props.totalAtendimentosPesquisadosPorTipo;

    var totalMensalPorCliente = [];
    if (this.props.totalMensalPorCliente)
      totalMensalPorCliente = this.props.totalMensalPorCliente;

    // console.log(
    //   'totalAtendimentosPorDiaPorTipo: ',
    //   totalAtendimentosPorDiaPorTipo
    // );
    // console.log('totalMensalPorCliente: ', totalMensalPorCliente);

    var pieChartData = [['X', 'Y']];
    for (let i = 0; i < totalMensalPorCliente.length; i++) {
      var temp = [];
      temp.push(totalMensalPorCliente[i].label, totalMensalPorCliente[i].valor);
      pieChartData.push(temp);
    }

    const uniqueLabels = [
      'Dias da semana',
      ...new Set(totalAtendimentosPorDiaPorTipo.map((item) => item.label)),
    ];
    const uniqueBars = [
      ...new Set(totalAtendimentosPorDiaPorTipo.map((item) => item.barra)),
    ];

    var chartData3 = [];
    chartData3.push(uniqueLabels);

    var arraysPorBarra = {};

    uniqueBars.forEach(function (bar) {
      var arrayBar = new Array(uniqueLabels.length);
      arrayBar.fill(0, 0, uniqueLabels.length);
      arrayBar[0] = bar;
      arraysPorBarra[bar] = arrayBar;
    });

    totalAtendimentosPorDiaPorTipo.forEach(function (item) {
      var indiceLabel = uniqueLabels.indexOf(item.label);
      arraysPorBarra[item.barra][indiceLabel] = item.valor;
    });

    var arraysSoltos = Object.values(arraysPorBarra);

    arraysSoltos.map((array) => chartData3.push(array));

    var comboMediaChartData = chartData3.map(function (arr) {
      return arr.slice();
    });

    comboMediaChartData[0].push('Media diária');
    var totalAtendimentosSemanal = 0;
    for (let i = 1; i < comboMediaChartData.length; i++) {
      var totalDia = 0;

      for (let j = 1; j < comboMediaChartData[i].length; j++) {
        totalDia += comboMediaChartData[i][j];
      }

      totalAtendimentosSemanal += totalDia;
    }
    var mediaSemanal = Math.ceil(totalAtendimentosSemanal / uniqueBars.length);

    for (let i = 1; i < comboMediaChartData.length; i++) {
      comboMediaChartData[i].push(mediaSemanal);
    }

    var mediaTotalSemanal = [['Dia da semana', 'Total', 'Média']];
    for (let i = 0; i < uniqueBars.length; i++) {
      mediaTotalSemanal.push([uniqueBars[i]]);
    }

    for (let i = 1; i < mediaTotalSemanal.length; i++) {
      let totalDia = 0;
      for (let j = 1; j < chartData3[i].length; j++) {
        totalDia += chartData3[i][j];
      }
      mediaTotalSemanal[i].push(totalDia);
      mediaTotalSemanal[i].push(mediaSemanal);
    }

    if (this.props.tipo === 'Barra' || this.props.tipo === 'Linha') {
      this.props.parentCallback(chartData3);
    } else if (this.props.tipo === 'Pizza') {
      this.props.parentCallback(pieChartData);
    } else if (this.props.tipo === 'ComboMedia') {
      this.props.parentCallback(mediaTotalSemanal);
    }
  };

  render() {
    return (
      <>
        <p onLoad={this.sendData()}></p>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    atendimento: state.atendimento.atendimentos,
    totalAtendimentosPesquisadosPorTipo:
      state.atendimento.totalAtendimentosPesquisadosPorTipo,
    totalMensalPorCliente: state.atendimento.totalMensalPorCliente,
  };
};

export default connect(mapStateToProps, actionsAtendimento)(LogicaGraficos);
