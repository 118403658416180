import React from 'react';
import { Button, Spinner } from 'reactstrap';

const BotaoLogin = (props) => {

    if (props.aguardando) {
        return (
            <>
                <Button 
                    className="button-login"
                    id="idEntrar"
                    block
                    disabled>
                    <Spinner className="ml-1" size="sm" color="light" />
                </Button>
            </>
        )
    }

    return (
        <>
            <Button
                className="button-login"
                type="submit"
                id="idEntrar"
                block>Entrar
            </Button>
        </>
    )

};

export default BotaoLogin;