import React from "react";
import { Button } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";

const BotaoCancelar = (props) => {
  return (
    <>
      <Button
        onClick={props.onClickCancelar}
        id="idCancelar"
        className="m-1 btn-sm botao-secundario"
      >
        <CloseIcon />
        <span> Cancelar</span>
      </Button>
    </>
  );
};

export default BotaoCancelar;
