import { BUSCAR_CLIENTE, LIMPAR_CLIENTE, LIMPAR_CLIENTES, LISTAR_CLIENTES, PESQUISAR_CLIENTES, PESQUISAR_CLIENTE_PAGE } from '../../core/store/types';

const reducersCliente = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_CLIENTE:
            return {
                ...state,
                cliente: action.payload,
            }
        case LIMPAR_CLIENTE:
            return {
                ...state,
                cliente: null,
            }
        case LIMPAR_CLIENTES:
            return {
                ...state,
                clientes: null,
                clientesPesquisados: null
            }
        case LISTAR_CLIENTES:
            return {
                ...state,
                clientes: action.payload._embedded.clientes,
            }
        case PESQUISAR_CLIENTES:
            return {
                ...state,
                clientesPesquisados: action.payload._embedded.clientes,
            }
        case PESQUISAR_CLIENTE_PAGE:
            return {
                ...state,
                clientesPesquisadosPage: action.payload,
            }    
        default:
            return state;
    }
}
export default reducersCliente;