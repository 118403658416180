import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { mask } from "./mask";
// import validator from 'validator';
import { connect } from "react-redux";
import * as actionsUsuario from "../../../domain/actions/actionsUsuario";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

import checkbox from "../../../assets/styles/checkbox.css";

const AplicacaoCadastrar = (props) => {
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [chaveAplicacao, setChaveAplicacao] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);
  const [formularioPronto, setFormularioPronto] = useState(false);
  const [padrao, setPadraoChecked] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  const cadastrarAplicacoes = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;
    setAguardando(true);
    props.salvarAplicacao(
      { nome, descricao, chaveAplicacao, padrao },
      (retorno) => {
        if (retorno.erro.mensagem) {
          setAtencao("");
          setSucesso("");
          setErro({ mensagem: retorno.erro.mensagem });
          setAguardando(false);
          setFormularioErro(true);
        } else {
          setAtencao("");
          setErro("");
          setSucesso({ mensagem: "Aplicação cadastrada com sucesso!" });
          setFormularioSucesso(true);
          setAguardando(false);
        }
      }
    );
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioPronto(true);
  };

  if (formularioPronto) {
    return <Redirect to={{ pathname: "/sac/aplicacoes" }} />;
  }

  const criticas = () => {
    if (!nome) return setAtencao({ mensagem: "Preencha o campo nome!" });
    if (!descricao)
      return setAtencao({ mensagem: "Preencha o campo descrição!" });
    if (!chaveAplicacao) return setAtencao({ mensagem: "Preencha o campo chave!" });
    return true;
  };

  function handleChangeMask(event) {
    const { value } = event.target;
    // event.target.value = onlyNumbers(value);

    setChaveAplicacao(mask(value));
  }

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Cadastrar Aplicação</h2>
        </div>
      </div>
      <hr />
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="name">Nome</Label>
            <Input
              type="text"
              value={nome}
              name="nome"
              id="nome"
              placeholder="Nome da Aplicação"
              autoComplete="nome"
              onChange={(ev) => setNome(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="descricao">Descrição</Label>
            <Input
              type="text"
              value={descricao}
              name="descricao"
              id="descricao"
              placeholder="Descrição da Aplicação"
              autoComplete="descricao"
              onChange={(ev) => setDescricao(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-3">
          <FormGroup>
            <Label for="chaveAplicacao">Chave</Label>
            <Input
              type="tel"
              maxLength={4}
              minLength={1}
              value={chaveAplicacao}
              name="chaveAplicacao"
              id="chaveAplicacao"
              placeholder="0000"
              autoComplete="chaveAplicacao"
              keyboardType="numeric"
              onChange={handleChangeMask}
            />
          </FormGroup>
        </div>
        <div className="col-sm-1">
          <FormGroup className="checkbox-wrapper">
            <Label className="container" for="padrao">
              Padrão
            </Label>
            <Input
              className={padrao ? "checked" : ""}
              type="checkbox"
              name="padrao"
              id="padrao"
              autoComplete="padrao"
              onChange={() => setPadraoChecked(!padrao)}
            />
          </FormGroup>
        </div>
      </div>
      <Link onClick={() => cadastrarAplicacoes()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"/sac/aplicacoes"}>
        <BotaoVoltar
          // onClick={console.log(nome, descricao, chaveAplicacao, padrao)}
        />
      </Link>
    </>
  );
};
export default connect(null, actionsUsuario)(AplicacaoCadastrar);
