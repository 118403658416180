import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionsContrato from '../../../domain/actions/actionsContrato';
import { FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import BotaoVoltar from '../../../components/BotaoVoltar';
import validator from 'validator';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalErro from '../../../components/ModalErro';
import ModalSucesso from '../../../components/ModalSucesso';

class ContratosAlterar extends Component{

    state={
        id: "",
        aplicacao: "",
        idAplicacao: "",
        tipoContrato : "",
        idTipoContrato: "",
        idCliente: "",
        copiasContratadas: "",
        diasRenovacao: "",
        erro: "",
        sucesso: "",
        atencao: "",
        aguardando: false,
        dadosContrato: false,
        formularioPronto: false,
        formularioSucesso: false,
        formularioAtencao: false,
        formularioErro: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getContrato(id);
        this.props.pesquisarTiposContrato();
        this.props.pesquisarAplicacoes();
        this.props.listarContratos();
        this.props.pesquisarClientes();
    }

    async componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.contrato && nextProps.contrato) this.props.getContrato(id);
        await this.receberDadosContrato();
    }

    receberDadosContrato(){
        const {id} = this.props.match.params;

        if(typeof this.props.contrato !== "undefined" && this.props.contrato !== null && this.props.contrato.id == id && !this.state.dadosContrato){
            this.setState({ id: this.props.contrato.id });
            this.setState({ aplicacao: this.props.contrato.aplicacao.nome });
            this.setState({ copiasContratadas: this.props.contrato.copiasContratadas });
            this.setState({ diasRenovacao : this.props.contrato.diasRenovacao });
            this.setState({ idTipoContrato : this.props.contrato.tipoContrato.id});
            this.setState({ idAplicacao : this.props.contrato.aplicacao.id})
            this.setState({ idCliente: this.props.contrato.cliente.id})
            this.setState({ dadosContrato: true });
        }
    }

    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    async alterarContrato(){
        this.setState({ erro : ""});
        await this.receberDadosFormulario();

        this.setState({ formularioAtencao: !this.criticas() });
        if (!this.criticas()) return;

        this.setState({ aguardando: true });

        const {id,copiasContratadas,diasRenovacao,idTipoContrato,idAplicacao, idCliente} = this.state;

        this.props.alterarContrato({id, aplicacao : {id: idAplicacao} ,copiasContratadas, diasRenovacao, tipoContrato :{id: idTipoContrato}, cliente:{id: idCliente}}, (retorno) =>{
            if (retorno.erro.mensagem) {
                this.setState({ erro: { mensagem: retorno.erro.mensagem } });
                this.setState({ aguardando: false });                
                this.setState({ formularioErro: true });
            } else {
                this.setState({ aguardando: false });
                this.setState({ sucesso: { mensagem: "Contrato alterado com sucesso!" } });
                this.setState({ formularioSucesso: true });
            }
        })
    }

    receberDadosFormulario() {
        this.setState({ id: document.querySelector("#id").value });
        this.setState({ diasRenovacao: document.querySelector("#diasRenovacao").value });
        this.setState({ copiasContratadas: document.querySelector("#copiasContratadas").value });
        this.setState({ idAplicacao: document.querySelector("#aplicacao").value });
        this.setState({ idTipoContrato: document.querySelector("#tipoContrato").value });
    
    }

    pesquisarContratos(){
        this.props.listarContratos();
    }

    criticas() {
        const { idAplicacao } = this.state;

        if (idAplicacao === "0" || idAplicacao === "") return this.setState({ atencao: { mensagem: "A aplicação deve ser escolhida." } });
        // if (!solicitante) return this.setState({ atencao: { mensagem: "Preencha o campo solicitante!" } });

        return true;
    }

    fecharFormularioAtencao = () => {
        this.setState({ formularioAtencao: false });
    }

    fecharFormularioErro = () => {
        this.setState({ formularioErro: false });
    }

    fecharFormularioSucesso = () => {        
        this.setState({ formularioPronto: true });
    }

    render(){
        const {id,copiasContratadas,diasRenovacao,idTipoContrato,idAplicacao, aguardando, erro, sucesso, atencao, dadosContrato, formularioPronto, formularioAtencao, formularioErro, formularioSucesso } = this.state;

        if (formularioPronto) {
            return <Redirect to={'/sac/contratos'} />
        }

        var contrato = []
        if (this.props.contrato) contrato = this.props.contrato;
        


        var aplicacoes = []
        if(this.props.aplicacoesPesquisados) aplicacoes = this.props.aplicacoesPesquisados;

        var clientes = []
        if(this.props.clientesPesquisados) clientes = this.props.clientesPesquisados;
        

        var contratos = []
        if(this.props.contratosPesquisados) contratos = this.props.contratosPesquisados;       

        var tiposContratos = []
        if(this.props.tiposContratoPesquisados) tiposContratos = this.props.tiposContratoPesquisados        

        return(
            <>
                <div className="d-flex">
                    <div className="mr-auto p-2">
                        <h2 className="display-4 titulo">Contrato</h2>
                    </div>
                </div>
                <hr />
                <ModalErro isOpen={formularioErro} toggle={() => this.fecharFormularioErro()} erro={erro} />
                <ModalAtencao isOpen={formularioAtencao} toggle={() => this.fecharFormularioAtencao()} atencao={atencao} />
                <ModalSucesso isOpen={formularioSucesso} toggle={() => this.fecharFormularioSucesso()} sucesso={sucesso} />
                <div className="form-group row">
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="id">ID</Label>
                            <Input
                                type="text"
                                value={id}
                                name="id"
                                id="id"
                                disabled={true}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="aplicacao">Aplicacao</Label>
                            <Input
                                value={idAplicacao}
                                type="select"
                                name="aplicacao"
                                id="aplicacao"

                                className="form-control"
                                placeholder={dadosContrato ? "" : "Carregado..."}
                                disabled={dadosContrato ? false : true}
                                onChange={(ev) => this.onChangeInput("idAplicacao", ev)}
                            >
                                {
                                    aplicacoes.map(
                                        (aplicacao) => (
                                            <option value={aplicacao.id}>
                                                {aplicacao.nome}
                                            </option>
                                        )
                                    )
                                }
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="tipoContrato">Tipo de Contrato</Label>
                            <Input
                                value={idTipoContrato}
                                type="select"
                                name="tipoContrato"
                                id="tipoContrato"

                                className="form-control"
                                placeholder={dadosContrato ? "" : "Carregado..."}
                                disabled={dadosContrato ? false : true}
                                onChange={(ev) => this.onChangeInput("idTipoContrato", ev)}
                            >
                                {
                                    tiposContratos.map(
                                        (tipoContrato) => (
                                            <option value={tipoContrato.id}>
                                                {tipoContrato.descricao}
                                            </option>
                                        )
                                    )
                                }
                            </Input>
                        </FormGroup>
                    </div>
                    </div>
                <div className="form-group row">
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="copiasContratadas">Copias Contratadas</Label>
                            <Input
                                type="text"
                                value={copiasContratadas}
                                name="copiasContratadas"
                                id="copiasContratadas"
                                className="form-control"
                                placeholder={dadosContrato ? "" : "Carregado..."}
                                disabled={dadosContrato ? false : true}
                                autoComplete="copiasContratadas"
                                onChange={(ev) => this.onChangeInput("copiasContratadas", ev)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="diasRenovacao">Dias para renovação</Label>
                            <Input
                                type="text"
                                value={diasRenovacao}
                                name="diasRenovacao"
                                id="diasRenovacao"
                                className="form-control"
                                placeholder={dadosContrato ? "" : "Carregado..."}
                                disabled={dadosContrato ? false : true}
                                autoComplete="diasRenovacao"
                                onChange={(ev) => this.onChangeInput("diasRenovacao", ev)}
                            />
                        </FormGroup>
                    </div>
                </div>
                <br />
                <Link onClick={() => this.alterarContrato()} to="#">
                    <BotaoConfirmar aguardando={aguardando} />
                </Link>
                <Link to={{ pathname: "/sac/contratos"}} 
                                      style={{ textDecoration: 'none' }}  
                                >
                <BotaoVoltar></BotaoVoltar>
                </Link>
            </>
        )

    }

}

const mapStateToProps = state =>{    
    return({
    contrato: state.contrato.contrato,
    aplicacoesPesquisados : state.aplicacao.aplicacoesPesquisados,
    clientesPesquisados : state.cliente.clientesPesquisados,
    contratosPesquisados : state.contrato.contratos,
    tiposContratoPesquisados : state.contrato.tiposContratoPesquisados
    
})}

export default connect(mapStateToProps, actionsContrato)(ContratosAlterar);