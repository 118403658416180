import { BUSCAR_ATENDIMENTO, LIMPAR_ATENDIMENTO, LIMPAR_ATENDIMENTOS, LISTAR_ATENDIMENTOS, PESQUISAR_ATENDIMENTOS, PESQUISAR_ATENDIMENTOS_POR_STATUS, PESQUISAR_TOTAL_ATENDIMENTOS,PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO,PESQUISAR_TOTAL_MENSAL_POR_CLIENTE } from '../../core/store/types';

const reducersAtendimento = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_ATENDIMENTO:
            return {
                ...state,
                atendimento: action.payload,
            }
        case LIMPAR_ATENDIMENTO:
            return {
                ...state,
                atendimento: null,
            }
        case LIMPAR_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: null,
                atendimentosPesquisados: null
            }
        case LISTAR_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: action.payload._embedded.servicos,
            }
        case PESQUISAR_ATENDIMENTOS:
            return {
                ...state,
                atendimentosPesquisados: action.payload,
            }
        case PESQUISAR_ATENDIMENTOS_POR_STATUS:
            return {
                ...state,
                atendimentosPesquisadosPorStatus: action.payload, 
            }
        case PESQUISAR_TOTAL_ATENDIMENTOS:
            return{
                ...state,
                totalAtendimentosPesquisados: action.payload,
            }
        case PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO:
            return{
                ...state,
                totalAtendimentosPesquisadosPorTipo: action.payload,
            }
        case PESQUISAR_TOTAL_MENSAL_POR_CLIENTE:
            return{
                ...state,
                totalMensalPorCliente: action.payload,
            }
        default:
            return state; 
    }
}
export default reducersAtendimento;