import axios from "axios";
import {
  BUSCAR_CLIENTE,
  LIMPAR_CLIENTE,
  LIMPAR_CLIENTES,
  // LISTAR_CLIENTES,
  PESQUISAR_CLIENTES,
  PESQUISAR_CLIENTE_PAGE
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const limparCliente = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CLIENTE });
  };
};

export const limparClientes = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CLIENTES });
  };
};

export const removerCliente = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/clientes/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const pesquisarClientes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/clientes`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CLIENTES, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarClientesPagination = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/clientes/pagination?nome=${dadosFiltro.nome}&nomeReduzido=${dadosFiltro.nomeReduzido}&size=50&page=${dadosFiltro.page}&sort=${dadosFiltro.sort}`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CLIENTE_PAGE, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarClientesPaginationBotao = (dadosFiltro) => {
  return (dispatch) => {        
      axios
        .get(`${api}/v1/clientes/pagination?nome=${dadosFiltro.nome}&nomeReduzido=${dadosFiltro.nomeReduzido}&size=50&page=${dadosFiltro.page}&sort=${dadosFiltro.sort}`, headers())
        .then((response) => {
          dispatch({ type: PESQUISAR_CLIENTE_PAGE, payload: response.data });
        })
        .catch(errorHandler);
  }
}

export const salvarCliente = (dadosCliente, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/clientes`, dadosCliente, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getCliente = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/clientes/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_CLIENTE, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarCliente = (dadosCliente, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/clientes/${dadosCliente.id}`,
        { nome: dadosCliente.nome, 
          nomeReduzido: dadosCliente.nomeReduzido, 
          email: dadosCliente.email, 
          valorInadimplencia: dadosCliente.valorInadimplencia, 
          ...(dadosCliente.valorInadimplencia > 0 ? {dataInadimplencia: dadosCliente.dataInadimplencia + 'T12:00:00.000000Z'} : {}) 
        },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const ativarCliente = (dadosCliente, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/clientes/${dadosCliente.id}/ativa`,
        { ativo: dadosCliente.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarCliente = (dadosCliente, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/clientes/${dadosCliente.id}/desativa`,
        { ativo: dadosCliente.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
