import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsLiberacao from "../../domain/actions/actionsLiberacao";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../components/BotaoConfirmar";
import BotaoVoltar from "../../components/BotaoVoltar";
import ModalAtencao from "../../components/ModalAtencao";
import ModalErro from "../../components/ModalErro";
import ModalSucesso from "../../components/ModalSucesso";
import ContentCopy from "@mui/icons-material/FileCopy";
import "../../assets/styles/copybutton.css";

// import BotaoCopiar from "../../components/BotaoCopiar";

const LiberarChave = (props) => {
  const [numeroSerie, setNumeroSerie] = useState("");
  const [chave, setChave] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  const [fill, setFill] = useState(true);
  const [active, setActive] = useState(false);

  const cadastrarLiberacao = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;
    setAguardando(true);
    props.salvarLiberacao({ numeroSerie }, (retorno) => {
      if (retorno.erro.mensagem) {
        setAtencao("");
        setSucesso("");
        setErro({ mensagem: retorno.erro.mensagem });
        setFormularioErro(true);
        setAguardando(false);
      } else {
        setAtencao("");
        setErro("");
        setChave(retorno.erro.chaveLiberacao);
        setSucesso({ mensagem: "Chave gerada com sucesso!" });
        setFill(false);
        setActive(true);
        setFormularioSucesso(true);
        setAguardando(false);
      }
    });
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioSucesso(false);
  };

  const criticas = () => {
    if (!numeroSerie)
      return setAtencao({
        mensagem: "Preencha o campo com o número de série!",
      });
    return true;
  };

  const mudaBtn = active
    ? "copy_button btn-sm botao-secundario"
    : "copy_button btn-sm";

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Gerar Liberação</h2>
        </div>
      </div>
      <hr />
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-8">
          <FormGroup>
            <Label for="numeroSerie">Número de série</Label>
            <Input
              type="text"
              value={numeroSerie}
              name="numeroSerie"
              id="numeroSerie"
              autoComplete="numeroSerie"
              onChange={(ev) => setNumeroSerie(ev.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-8">
          <FormGroup>
            <Label for="chave">Chave</Label>
            <Input
              type="text"
              value={chave}
              name="chave"
              id="chave"
              disabled={true}
            />
          </FormGroup>
        </div>
        <div className="col-sm-1">
          <FormGroup>
            {/* Rotina anterior: */}
            {/* <BotaoCopiar onClick={() => {navigator.clipboard.writeText(chave)}} /> */}

            {/* Nova rotina, usando CopyToClipboard API: */}
            <CopyToClipboard text={chave}>
              <button className={mudaBtn} disabled={fill}>
                <ContentCopy />
              </button>
            </CopyToClipboard>
          </FormGroup>
        </div>
      </div>
      <Link onClick={() => cadastrarLiberacao()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"/sac/painel-de-controle"}>
        <BotaoVoltar />
      </Link>
    </>
  );
};

export default connect(null, actionsLiberacao)(LiberarChave);
