import {
  BUSCAR_DISPOSITIVO,
  LISTAR_DISPOSITIVO,
  PESQUISAR_DISPOSITIVOS,
} from "../../core/store/types";

const reducersDispositivo = (state = {}, action) => {
  switch (action.type) {
    case BUSCAR_DISPOSITIVO:
      return {
        ...state,
        dispositivo: action.payload,
      };
    case LISTAR_DISPOSITIVO:
      return {
        ...state,
        dispositivos: action.payload._embedded.dispositivos,
      };
    case PESQUISAR_DISPOSITIVOS:
      return {
        ...state,
        dispositivosPesquisados: action.payload,
      };
    default:
      return state;
  }
};

export default reducersDispositivo;
