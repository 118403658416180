import { Button } from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BotaoVoltarAlt = (props) => {
  return (
    <>
      <Button
        id="idVoltar"
        className="btn-sm botao-secundario"
        onClick={props.onClickVoltar}
      >
        <ArrowBackIcon />
        <span>Voltar</span>
      </Button>
    </>
  );
};

export default BotaoVoltarAlt;
