import React from 'react';
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';

// import { Modal as ModalMui } from '@mui/material';

import BotaoCancelar from '../BotaoCancelar';
import BotaoConfirmar from '../BotaoConfirmar';

const ModalLogout = ({ open, onConfirm, toggle, isWaiting }) => {
  return (
    <Modal isOpen={open} toggle={toggle} zIndex={999} centered>
      <ModalHeader>Sair</ModalHeader>
      <ModalBody>
        <FormGroup>
          <div>
            <Alert color='warning'>
              <p className='mb-0'>Deseja realmente sair do sistema?</p>
            </Alert>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <span onClick={onConfirm}>
          <BotaoConfirmar aguardando={isWaiting} />
        </span>
        <BotaoCancelar className='m-1' onClickCancelar={toggle} />
      </ModalFooter>
    </Modal>
  );
};

export default ModalLogout;
