import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import errorHandler from "../../core/store/errorHandler";

import { FormGroup, Label, Input, Card, Button } from "reactstrap";
import { StyleSheet, css } from "aphrodite";

import BotaoConfirmar from "../../components/BotaoConfirmar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import ModalAtencao from "../../components/ModalAtencao";
import ModalErro from "../../components/ModalErro";
import ModalSucesso from "../../components/ModalSucesso";
import ContentCopy from "@mui/icons-material/FileCopy";
import ModalEmailEnviado from "../../components/ModalEmailEnviado";

import "../../assets/styles/copybutton.css";
import "../../assets/styles/liberaExterno.css";
import logo_ivitech from "../../assets/images/logoivitech.png";
import bgImg from "../../assets/images/background_sac.png";

import axios from "axios";
import { api } from "../../core/config";

const LiberacaoExterna = (props) => {
  const loc = useLocation();
  const getParam = new URLSearchParams(loc.search);
  const id = getParam.get("id");

  const [dummy, setDummy] = useState(null);

  const [numeroSerie, setNumeroSerie, numeroSerieRef] = useState(id);
  const [chave, setChave, chaveRef] = useState("");
  const [erro, setErro, erroRef] = useState("");
  const [sucesso, setSucesso, sucessoRef] = useState("");
  const [atencao, setAtencao, atencaoRef] = useState("");
  const [aguardando, setAguardando, aguardandoRef] = useState(false);
  const [invalidSerie, setInvalidSerie, invalidSerieCur] = useState(false);

  const [formularioSucesso, setFormularioSucesso, formularioSucessoRef] =
    useState(false);
  const [formularioAtencao, setFormularioAtencao, formularioAtencaoRef] =
    useState(false);
  const [formularioErro, setFormularioErro, formulaioErroRef] = useState(false);
  const [supEmail, setSupEmail, supEmailCur] = useState(false);

  // const errorMessage = `  O número de série informado é invalido!

  //                           Verifique o campo e tente novamente,
  //                           ou entre em contato com o suporte pelo botão de ajuda abaixo.
  //                           `;

  const retornaErro = (erro) => {
    setErro({ mensagem: erro.erro.mensagem });
  };

  const [fill, setFill] = useState(true);
  const [active, setActive] = useState(false);

  const gerarLiberacao = () => {
    setAguardando(true);
    if (!criticas()) return;
    axios
      .post(`${api}/v1/chaves-geradas`, { numeroSerie: numeroSerieRef.current })
      .then((response) => {
        setChave(response.data.chaveLiberacao);
        setFill(false);
        setActive(true);
        setAguardando(false);
        console.log(window.location.search);
        console.log(active);
      })
      .catch((err) => {
        // setErro({ mensagem: `${errorMessage}` });
        console.log(errorHandler(err));
        console.log(formularioErro);
        retornaErro(errorHandler(err));
        setFormularioErro(true);
        setAguardando(false);
        setInvalidSerie(true);
      });
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioSucesso(false);
  };

  const criticas = () => {
    if (!numeroSerie) {
      setAtencao({ mensagem: "Preencha o campo com o Número de série!" });
      setFormularioAtencao(true);
      return false;
    }
    return true;
  };

  const [redirect, setRedirect] = useState(false);

  function redirectHandler() {
    setRedirect(true);
    window.location.replace(
      "https://api.whatsapp.com/send?phone=5585987805800"
    );
  }

  const mudaBtn = active
    ? "copy_button btn-sm botao-secundario"
    : "copy_button btn-sm";

  return (
    <>
      <div className="container-libera">
        <br />
        <div className={css(styles.container)}>
          <Card className={"col-lg-10 col-md-10 "}>
            <div className={css(styles.mainDiv)}>
              <div className={css(styles.divImg)}>
                <img src={logo_ivitech} />
              </div>
              <br />
              <div className={css(styles.divImg)}>
                <p>
                  <b>Liberação de acesso ao sistema</b>
                </p>
              </div>
              <br />
              <ModalErro
                isOpen={formularioErro}
                toggle={() => fecharFormularioErro()}
                erro={erro}
              />
              <ModalAtencao
                isOpen={formularioAtencao}
                toggle={() => {
                  fecharFormularioAtencao();
                  setAguardando(!aguardando);
                }}
                atencao={atencao}
              />
              <ModalSucesso
                isOpen={formularioSucesso}
                toggle={() => fecharFormularioSucesso()}
                sucesso={sucesso}
              />
              <ModalEmailEnviado
                isOpen={supEmailCur.current}
                toggle={() => setSupEmail(!supEmailCur.current)}
                numSerie={numeroSerieRef.current}
              />
              <div className="form-group row">
                <div className="col-sm-11">
                  <FormGroup>
                    <Label for="numeroSerie">Número de série</Label>
                    <Input
                      type="text"
                      value={numeroSerie}
                      name="numeroSerie"
                      id="numeroSerie"
                      autoComplete="numeroSerie"
                      onChange={(ev) => setNumeroSerie(ev.target.value)}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-11">
                  <FormGroup>
                    <Label for="chave">Chave</Label>
                    <Input
                      type="text"
                      value={chave}
                      name="chave"
                      id="chave"
                      disabled={true}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-1">
                  <FormGroup>
                    {/* Rotina anterior: */}
                    {/* <BotaoCopiar onClick={() => {navigator.clipboard.writeText(chave)}} /> */}

                    {/* Nova rotina, usando CopyToClipboard API: */}
                    <CopyToClipboard text={chave}>
                      <button className={mudaBtn} disabled={fill}>
                        <ContentCopy />
                      </button>
                    </CopyToClipboard>
                  </FormGroup>
                </div>
              </div>
              <div className={"form-group row " + css(styles.buttonsDiv)}>
                <Link onClick={() => gerarLiberacao()} to="#">
                  <BotaoConfirmar aguardando={aguardando} />
                </Link>
                {invalidSerieCur.current ? (
                  <Button
                    className={css(styles.callHelpButton)}
                    onClick={redirectHandler}
                  >
                    <WhatsAppIcon style={{ marginRight: 5 }} />
                    Envie para o suporte
                  </Button>
                ) : (
                  false
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 70,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Raleway",
  },
  mainDiv: {
    margin: 15,
  },
  pageHeader: {
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    color: "#FFF",
    fontFamily: "JetBrains Mono",
  },
  page: {
    backgroundImage: `url('${bgImg}')`,
    minHeight: "100vh",
    width: "100%",
  },
  divCopy: {
    display: "flex",
    alignItems: "center",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    fontSize: 20,
  },
  buttonsDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  callHelpButton: {
    marginLeft: 10,
    height: 34,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    backgroundColor: "#339966",
    borderColor: "#339966",
    color: "#fff",
    ":hover": {
      backgroundColor: "#FFF",
      borderColor: "#339966",
      color: "#339966",
    },
  },
});

export default LiberacaoExterna;
