import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import LogicaGraficos from '../LogicaGraficos';

class Linha extends Component{
    state ={
        chartData : []
    }

    callBackFunction = (childData) =>{
        this.setState({chartData:childData})
    }

    render(){
        return(
            <>
                <LogicaGraficos parentCallback={this.callBackFunction} tipo={'Linha'}/>
                <div className='card_graph'>
                    <center>
                        <p>Distribuição de atendimentos</p>
                        <p className='card_subtitle'>Dia da semana x tipo de atendimento</p>
                    </center>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Carregando Gráfico</div>}
                        data={this.state.chartData}
                        options={{
                            backgroundColor: 'rgb(255, 255, 255)',
                            chartArea:{
                                width:'94%'
                            },
                            legend:'none'
                            
                        }}
                        rootProps={{ 'data-testid': '1' }}
                        />
                    </div>

            </>        
        )
    }
}

export default Linha