import { Button } from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BotaoVoltar = (props) => {
  return (
    <>
      <Button id="idVoltar" className="btn-sm botao-secundario">
        <ArrowBackIcon />
        <span>Voltar</span>
      </Button>
    </>
  );
};

export default BotaoVoltar;
