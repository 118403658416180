import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const BotaoInfo = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <Button 
                onClick={props.onClick} 
                id="idInfo"
                className="btn-acoes botao-secundario btn-sm m-1">
                <VisibilityOutlinedIcon className="icon-acoes" />
            </Button>
            <Tooltip placement="top" isOpen={tooltipOpen} target="idInfo" toggle={toggle}>
                Visualizar
            </Tooltip>
        </>
    );
};

export default BotaoInfo;