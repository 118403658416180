import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsGrupos from "../../../domain/actions/actionsGrupos";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";

class GrupoAlterar extends Component {
  state = {
    id: "",
    nome: "",
    erro: "",
    sucesso: "",
    atencao: "",
    aguardando: false,
    formularioPronto: false,
    formularioSucesso: false,
    formularioAtencao: false,
    formularioErro: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getGrupo(id);
  }

  async componentDidUpdate(nextProps) {
    const { id } = this.props.match.params;
    if (!this.props.grupo && nextProps.grupo) this.props.getGrupo(id);
    await this.receberDadosGrupo();
  }

  receberDadosGrupo() {
    const { id } = this.props.match.params;

    if (
      typeof this.props.grupo !== "undefined" &&
      this.props.grupo !== null &&
      this.props.grupo.id == id &&
      !this.state.dadosGrupo
    ) {
      this.setState({ id: this.props.grupo.id });
      this.setState({ nome: this.props.grupo.nome });
      this.setState({ dadosGrupo: true });
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  async alterarGrupo() {
    this.setState({ erro: "" });
    await this.receberDadosFormulario();

    this.setState({ formularioAtencao: !this.criticas() });
    if (!this.criticas()) return;

    this.setState({ aguardando: true });
    const { id, nome } = this.state;
    console.log(this.state);

    this.props.alterarGrupo({ id, nome }, (err) => {
      if (err.erro.mensagem) {
        this.setState({ erro: { mensagem: err.erro.mensagem } });
        this.setState({ aguardando: false });
        this.setState({ formularioErro: true });
      } else {
        this.setState({ aguardando: false });
        this.setState({
          sucesso: { mensagem: "Aplicação alterada com sucesso!" },
        });
        this.setState({ formularioSucesso: true });
      }
    });
  }

  receberDadosFormulario() {
    // this.setState({ id: document.querySelector("#id").value });
    this.setState({ nome: document.querySelector("#nome").value });
  }

  criticas() {
    const { nome } = this.state;
    if (!nome)
      return this.setState({ atencao: { mensagem: "Preencha o campo nome!" } });
    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  fecharFormularioSucesso = () => {
    this.setState({ formularioPronto: true });
  };

  render() {
    const {
      id,
      nome,
      aguardando,
      erro,
      sucesso,
      atencao,
      dadosGrupo,
      formularioPronto,
      formularioAtencao,
      formularioErro,
      formularioSucesso,
    } = this.state;

    if (formularioPronto) {
      return <Redirect to={{ pathname: "/sac/grupos" }} />;
    }

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Grupo</h2>
          </div>
        </div>
        <hr />
        <ModalErro
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
          erro={erro}
        ></ModalErro>
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        >
          {" "}
        </ModalAtencao>
        <ModalSucesso
          isOpen={formularioSucesso}
          toggle={() => this.fecharFormularioSucesso()}
          sucesso={sucesso}
        >
          {" "}
        </ModalSucesso>
        <div className="form-group row">
          <div className="col-sm-6">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                className="form-control"
                placeholder={dadosGrupo ? "Nome do Grupo" : "Carregado..."}
                disabled={dadosGrupo ? false : true}
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
              />
            </FormGroup>
          </div>
        </div>
        <Link onClick={() => this.alterarGrupo()} to="#">
          <BotaoConfirmar aguardando={aguardando} />
        </Link>
        <Link to={"/sac/grupos"}>
          <BotaoVoltar />
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  grupo: state.grupo.grupo,
});

export default connect(mapStateToProps, actionsGrupos)(GrupoAlterar);
