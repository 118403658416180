import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsClientes from "../../../domain/actions/actionsClientes";
import { FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem, } from "reactstrap";
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';

// import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
import BotaoPesquisar from '../../../components/BotaoPesquisar';
import BotaoPdf from "./../../../components/BotaoPDF/index";
import BotaoAtivar from "../../../components/BotaoAtivar";
import BotaoDesativar from "../../../components/BotaoDesativar";
// import ModalApagar from "../../../components/ModalApagar";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalErroRetorna from "../../../components/ModalErroRetorna";

import { StyleSheet, css } from 'aphrodite'

import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

import clientesPDF from "../../../reports/clientesPDF";

class ListarClientes extends Component {
  state = {
    id: "",
    nome: "",
    nomeReduzido: "",
    sort: "nomeReduzido",
    valorInadimplencia: "",
    dataInadimplencia: '',
    mensagem: "",
    erro: "",
    aguardando: false,
    // confirmarExclusao: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioErro: null,
    page: 0,
    numPages: 0,
  };

  //Início da rotina de pesquisa
  pesquisarClientes() {
    const { nome, nomeReduzido, page, sort } = this.state;

    this.props.pesquisarClientesPagination( { nome, nomeReduzido, page, sort } );
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro() {
    this.setState({ formularioErro: false });
  };

  //Inicio da rotina de ativação de Clientes
  ativarCliente() {
    this.setState({ aguardando: true });
    this.props.ativarCliente(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.mensagem) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarCliente();
          this.props.limparClientes();
          this.pesquisarClientes();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }

  //Fim da rotina de ativação de Clientes

  //Inicio da rotina de desativação de Clientes

  desativarCliente() {
    this.setState({ aguardando: true });
    this.props.desativarCliente(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarClientes();
          this.props.limparClientes();
        }
      }
    );
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }

  paginationHandler = () => {
    var pages = 1;
    if (this.props.clientesPesquisadosPage)
      pages = this.props.clientesPesquisadosPage.page.totalPages;
    var curPages = [];
    for (let i = 0; i < pages; i++) {
      curPages.push(i);
    }
    return curPages.map((page) => {
      return (
        <PaginationItem
          active={this.state.page === page ? true : false}
          className={"paginationItemStyle"}
        >
          <PaginationLink
            className={"paginationLinkStyle"}
            onClick={() =>
              this.setState({ page: page }, this.pesquisarClientesBotao)
            }
          >
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      );
    });
  };

  criticas() {
    const { dataInicial, dataFinal } = this.state;

    return true;
  }  

  pesquisarClientesBotao() {
    const { nome, nomeReduzido, page, sort } = this.state;

    this.props.pesquisarClientesPaginationBotao({
      nome, nomeReduzido, page, sort
      });
  }  

  componentDidMount() {
    this.pesquisarClientes();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.clientesPesquisadosPage && nextProps.clientesPesquisadosPage) {
      this.pesquisarClientes();
    }
  }

  componentDidUpdate(nextProps, prevState) {
    //if (this.state.nome !== '' && prevState.nome !== this.state.nome) {
      //this.pesquisarClientesBotao('');
    //} else {
      if (!this.props.clientesPesquisadosPage && nextProps.clientesPesquisadosPage) this.pesquisarClientesBotao('');
    //}
  }  

  componentWillUnmount() {
    actionsClientes.limparCliente();
    actionsClientes.limparClientes();
  }

  render() {
    const {
      nome,
      nomeReduzido,
      valorInadimplencia,  
      dataInadimplencia,
      mensagem,
      erro,
      aguardando,
      confirmarExclusao,
      confirmarAtivacao,
      confirmarDesativacao,
      formularioErro,
    } = this.state;

    var numPages = 1;
    if (
      this.props.clientesPesquisadosPage &&
      this.props.clientesPesquisadosPage.page.totalPages
    )
    numPages = this.props.clientesPesquisadosPage.page.totalPages;

    var clientes = [];
    if (this.props.clientesPesquisadosPage &&
        this.props.clientesPesquisadosPage._embedded) {
      clientes = this.props.clientesPesquisadosPage._embedded.clientes;
    }

    var qtde = clientes.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Cliente"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarCliente()}
        />
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Cliente"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarCliente()}
        />
        <ModalErroRetorna
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        />
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Clientes</h2>
          </div>
          <div>
            <FormGroup>
              <BotaoPesquisar
                onClickPesquisar={() => {
                  this.setState({ formularioAtencao: !this.criticas() });

                  {
                    if (this.criticas()) {
                      this.setState({ page: 0 });
                      this.props.limparClientes();
                      this.pesquisarClientesBotao("");
                    }
                  }
                }}
              />
            </FormGroup>
          </div>          
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/clientes-cadastrar"
                descricaoObjeto="Cliente"
              ></BotaoCadastrar>
            </FormGroup>
          </div>
          {/* {erro && <ModalErro erro={{ mensagem: erro }} />} */}
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-sm-3">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
                placeholder="Filtar pelo nome"
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="nomeReduzido">Nome Reduzido</Label>
              <Input
                id="nomeReduzido"
                name="nomeReduzido"
                onChange={(ev) => this.onChangeInput("nomeReduzido", ev)}
                type="text"
                value={nomeReduzido}
                autoComplete="nomeReduzido"
                placeholder="Filtrar por nome reduzido"
              />
            </FormGroup>
          </div>
        </div>
        {/* {!this.props.aplicacoesPesquisados && (
          <ModalErro isOpen={true} erro={{ mensagem: erro }}></ModalErro>
        )} */}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Nome</Th>
              <Th>Nome reduzido</Th>
              <Th className="text-center">CNPJ</Th>
              <Th>E-mail</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clientes.map((cliente) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={cliente.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{cliente.id}</Td>
                  <Td>
                    <Link to={`/sac/clientes-alterar/${cliente.id}`}>
                      {cliente.nome}
                    </Link>
                  </Td>
                  <Td>{cliente.nomeReduzido}</Td>
                  <Td className="text-center">{cliente.codigo}</Td>
                  <Td>{cliente.email}</Td>
                  <Td className="text-center">
                    <span>
                      {cliente.ativo ? (
                        <BotaoDesativar
                          onClick={() =>
                            this.abrirConfirmarDesativacao(cliente.id)
                          }
                        />
                      ) : (
                        <BotaoAtivar
                          onClick={() =>
                            this.abrirConfirmarAtivacao(cliente.id)
                          }
                        />
                      )}
                      {/* <BotaoExcluir onClick={() => this.abrirConfirmarExclusao(aplicacao.id)} /> */}
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <br />
        {this.props.clientesPesquisadosPage ?
          <div>
            <Pagination
              className={css(styles.paginationContainer)}
            >
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  first
                  className={'paginationLinkStyle'}
                  onClick={() => {
                    this.setState({ page: 0 }, this.pesquisarClientesBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page === 0 || this.state.page === '0' ? true : false}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  previous
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 }, this.pesquisarClientesBotao)
                  }}
                />
              </PaginationItem>
              {
                this.paginationHandler()
              }
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page !== numPages - 1 ? false : true}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  next
                  onClick={() => {
                    this.setState({ page: this.state.page + 1 }, this.pesquisarClientesBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  last
                  onClick={() => {
                    this.setState({ page: numPages - 1 }, this.pesquisarClientesBotao)
                  }}
                />
              </PaginationItem>
            </Pagination>
          </div>
          : false
        }        
      </>
    );
  }
}

const styles = StyleSheet.create({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  }
})

const mapStateToProps = (state) => ({
  clientesPesquisadosPage: state.cliente.clientesPesquisadosPage,
});

export default connect(mapStateToProps, actionsClientes)(ListarClientes);
