import React from "react";
// import { useState, useEffect, useRef } from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
// import { EnterClick } from "../../hooks/EnterClick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "../../assets/styles/liberaExterno.css";
import { StyleSheet, css } from "aphrodite";

// import { Redirect } from "react-router-dom";

import BotaoOk from "../BotaoOk";

const ModalErroAlt = (props) => {
  // const [retorna, setRetorna] = useState(false);
  // const [permanece, setPermanece] = useState(false);

  // function btnAction() {
  //   if (props.erro.mensagem.includes("permissão")) {
  //     console.log(props.erro.mensagem);
  //     setRetorna(true);
  //   } else {
  //     console.log(props.erro.mensagem);
  //     console.log(btnAction);
  //     setPermanece(true);
  //   }
  // }

  // if (retorna) {
  //   return <Redirect to="/sac/pagina-inicial" />;
  // }

  // if (permanece) {
  //   return props.toggle;
  // }

  // EnterClick(props.toggle, 'Enter')

  return (
    <div className={css(styles.container)}>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader className={css(styles.container)} toggle={props.toggle}>
          <b>Erro</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Alert color="danger">
                <p>{props.errorMessage}</p>
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalErroAlt;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
  },
});
