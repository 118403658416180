import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsAplicacao from "../../../domain/actions/actionsAplicacao";
import { FormGroup } from "reactstrap";

// import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
// import BotaoExcluir from "../../../components/BotaoExcluir";
import BotaoAtivar from "../../../components/BotaoAtivar";
import BotaoDesativar from "../../../components/BotaoDesativar";
// import ModalApagar from "../../../components/ModalApagar";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalErroRetorna from "../../../components/ModalErroRetorna";

import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

class ListarAplicacoes extends Component {
  state = {
    id: "",
    nome: "",
    descricao: "",
    mensagem: "",
    erro: "",
    aguardando: false,
    // confirmarExclusao: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioErro: null,
  };

  // abrirConfirmarExclusao(id) {
  //   this.setState({ confirmarExclusao: true });
  //   this.setState({ idParaExcluir: id });
  // }

  // fecharConfirmarExclusao() {
  //   this.setState({ confirmarExclusao: false });
  // }

  // apagarAplicacao() {
  //   this.setState({ aguardando: true });
  //   this.props.removerAplicacao(this.state.idParaExcluir, (retorno) => {
  //     if (retorno.erro.erro) {
  //       this.setState({ erro: retorno.erro.mensagem });
  //       this.setState({ aguardando: false });
  //     } else {
  //       this.setState({ mensagem: retorno.erro.mensagem });
  //       this.setState({ aguardando: false });
  //       this.fecharConfirmarExclusao();
  //       this.props.limparAplicacoes();
  //       this.pesquisarAplicacoes();
  //     }
  //   });
  // }

  // pesquisarAplicacoes(){
  //     this.props.pesquisarAplicacoes()
  //     if (this.props.location.state) {
  //         this.setState({ mensagem: this.props.location.state.mensagem });
  //     }
  // }

  //Início da rotina de pesquisa
  pesquisarAplicacoes() {
    this.props.pesquisarAplicacoes((retorno) => {
      if (retorno.erro.mensagem) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ formularioErro: true });
      } else if (this.props.location.state) {
        this.setState({ mensagem: this.props.location.state.mensagem });
      }
    });
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro() {
    this.setState({ formularioErro: false });
  };

  //Inicio da rotina de ativação da Aplicação
  ativarAplicacao() {
    this.setState({ aguardando: true });
    this.props.ativarAplicacao(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.mensagem) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarAtivacao();
          this.props.limparAplicacoes();
          this.pesquisarAplicacoes();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }

  //Fim da rotina de ativação da Aplicação

  //Inicio da rotina de desativação da Aplicação

  desativarAplicacao() {
    this.setState({ aguardando: true });
    this.props.desativarAplicacao(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarAplicacoes();
          this.props.limparAplicacoes();
        }
      }
    );
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }

  //Fim da rotina de desativação da Aplicação

  componentDidMount() {
    this.pesquisarAplicacoes();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.aplicacoesPesquisados && nextProps.aplicacoesPesquisados) {
      this.pesquisarAplicacoes();
    }
  }

  componentWillUnmount() {
    actionsAplicacao.limparAplicacao();
    actionsAplicacao.limparAplicacoes();
  }

  render() {
    const {
      // nome,
      // descricao,
      mensagem,
      erro,
      aguardando,
      // confirmarExclusao,
      confirmarAtivacao,
      confirmarDesativacao,
      formularioErro,
    } = this.state;
    var aplicacoes = [];
    if (this.props.aplicacoesPesquisados) {
      aplicacoes = this.props.aplicacoesPesquisados;
    }

    var qtde = aplicacoes.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Aplicacao"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarAplicacao()}
        />
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Aplicacao"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarAplicacao()}
        />
        <ModalErroRetorna
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        />
        {/* <ModalApagar
          isOpen={confirmarExclusao}
          toggle={() => this.fecharConfirmarExclusao()}
          apagar="aplicacao"
          aguardando={aguardando}
          apagarObjeto={() => this.apagarAplicacao()}
        ></ModalApagar> */}
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem Aplicações</h2>
          </div>
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/aplicacoes-cadastrar"
                descricaoObjeto="Aplicacao"
              ></BotaoCadastrar>
            </FormGroup>
          </div>
          {/* {erro && <ModalErro erro={{ mensagem: erro }} />} */}
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />

        {/* {!this.props.aplicacoesPesquisados && (
          <ModalErro isOpen={true} erro={{ mensagem: erro }}></ModalErro>
        )} */}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Nome</Th>
              <Th>Descrição</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {aplicacoes.map((aplicacao) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={aplicacao.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{aplicacao.id}</Td>
                  <Td>
                    <Link to={`/sac/aplicacoes-alterar/${aplicacao.id}`}>
                      {aplicacao.nome}
                    </Link>
                  </Td>
                  <Td>{aplicacao.descricao}</Td>
                  <Td className="text-center">
                    <span>
                      {aplicacao.ativo ? (
                        <BotaoDesativar
                          onClick={() =>
                            this.abrirConfirmarDesativacao(aplicacao.id)
                          }
                        />
                      ) : (
                        <BotaoAtivar
                          onClick={() =>
                            this.abrirConfirmarAtivacao(aplicacao.id)
                          }
                        />
                      )}
                      {/* <BotaoExcluir onClick={() => this.abrirConfirmarExclusao(aplicacao.id)} /> */}
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aplicacoesPesquisados: state.aplicacao.aplicacoesPesquisados,
});

export default connect(mapStateToProps, actionsAplicacao)(ListarAplicacoes);
