// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import BotaoAlterarSenha from '../../../components/BotaoAlterarSenha';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalErro from '../../../components/ModalErro';
import ModalSucesso from '../../../components/ModalSucesso';

const UsuarioAltearPerfil = (props) => {
    const { usuario } = useSelector((state) => state);

    const [id, setId] = useState("");
    const [foto, setFoto] = useState(null);
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [senhaAtual, setSenhaAtual] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");
    const [atencao, setAtencao] = useState("");
    const [erro, setErro] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [formularioSucesso, setFormularioSucesso] = useState(false);
    const [aguardando, setAguardando] = useState(false);
    const [formularioAtencao, setFormularioAtencao] = useState(null);
    const [formularioErro, setFormularioErro] = useState(null);

    const receberDadosApi = () => {
        if (usuario.usuarioLogado) {
            setId(usuario.usuarioLogado.id);
            setNome(usuario.usuarioLogado.nome);
            setEmail(usuario.usuarioLogado.email);
        }
    }

    const atualizarSenha = (e) => {
        e.preventDefault();
        setFormularioAtencao(!criticaSenha());
        if (!criticaSenha()) return;
        setAguardando(true);
        props.alterarSenha({ id, senhaAtual, senha }, (retorno) => {
            if (retorno.erro.mensagem) {
                setErro({ mensagem: retorno.erro.mensagem });
                setAguardando(false);
                setFormularioErro(true);
            } else {
                setSucesso({ mensagem: "Senha alterada com sucesso!" });
                setFormularioSucesso(true);
            }
        });
    }

    const criticas = () => {
        if (!nome) return setAtencao({ mensagem: "Preencha o campo nome!" });
        if (!email) return setAtencao({ mensagem: "Preencha o campo e-mail!" });
        if (!validator.isEmail(email)) return setAtencao({ mensagem: "Preencha com e-mail válido!" });

        return true;
    }

    const criticaSenha = () => {
        if (!senhaAtual) return setAtencao({ mensagem: "Preencha o campo senha atual!" });
        if (!senha) return setAtencao({ mensagem: "Preencha o campo senha!" });
        if (!confirmaSenha) return setAtencao({ mensagem: "Preencha o campo de confirmação de senha!" });
        if (senha !== confirmaSenha) return setAtencao({ mensagem: "As senhas não são iguais!" });
        if (senha.length < 6) return setAtencao({ mensagem: "A senha precisa ter pelo menos seis caracteres!" });

        return true;
    }

    const atualizarPerfil = () => {
        setFormularioAtencao(!criticas());
        if (!criticas()) return;
        setAguardando(true);
        props.alterarPerfil({ id, nome, email }, (retorno) => {
            if (retorno.erro.mensagem) {
                setErro({ mensagem: retorno.erro.mensagem });
                setFormularioErro(true);
                setAguardando(false);
            } else {
                setSucesso({ mensagem: "Perfil editado com sucesso!" });
                setFormularioSucesso(true);
                setAguardando(false);
            }
        });
    }

    const recarregaPerfil = async () => {
        await props.recarregaPerfil(id, () => {
            setErro({ erro: "" });
            setFormularioErro(false);
            setFormularioSucesso(false);
            setAguardando(false);
        })
    }

    //Ao renderizar componente
    useEffect(() => {
        receberDadosApi();
        // eslint-disable-next-line
    }, []);

    //Ao atualizar componente
    useEffect(() => {
        receberDadosApi();
        // eslint-disable-next-line
    }, [usuario.usuarioLogado]);

    if (formularioSucesso) {
    }

    const fecharFormularioAtencao = () => {
        setFormularioAtencao(false);
    }

    const fecharFormularioErro = () => {
        setFormularioErro(false);
    }

    const fecharFormularioSucesso = () => {
        recarregaPerfil();
    }

    return (
        <>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="display-4 titulo">Perfil</h2>
                </div>
            </div>
            <hr />
            <ModalErro isOpen={formularioErro} toggle={() => fecharFormularioErro()} erro={erro} />
            <ModalAtencao isOpen={formularioAtencao} toggle={() => fecharFormularioAtencao()} atencao={atencao} />
            <ModalSucesso isOpen={formularioSucesso} toggle={() => fecharFormularioSucesso()} sucesso={sucesso} />
            <Form>
                <Input type="hidden"
                    value={id}
                    name="id"
                    id="id"
                />

                <div className="form-group row">
                    <div className="col-sm-5">
                        <FormGroup>
                            <Label for="nome">Nome</Label>
                            <Input
                                type="text"
                                value={nome}
                                name="nome"
                                id="nome"
                                className="form-control"
                                autoComplete="nome"
                                onChange={(ev) => setNome(ev.target.value)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-5">
                        <FormGroup>
                            <Label for="email">E-mail</Label>
                            <Input
                                type="email"
                                value={email}
                                name="email"
                                id="email"
                                className="form-control"
                                autoComplete="email"
                                onChange={(ev) => setEmail(ev.target.value)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-2">
                        <FormGroup>
                            <Label for="foto">Foto</Label>
                            {/*<Input
                                accept="image/*"
                                name="foto"
                                id="foto"
                                width="50" 
                                height="50"
                                className="rounded-circle"
                                autoComplete="foto"
                                onChange={(ev) => setFoto(ev.target.files[0])}                                
                            >
                                {foto ? <img src={URL.createObjectURL(foto)} className="rounded-circle" width="50" height="50" alt="Foto Perfil" /> : ""}
                            </Input> */}   
                            
                        </FormGroup>                        
                    </div>
                </div>

                <Link onClick={() => atualizarPerfil()} to="#">
                    <BotaoConfirmar aguardando={aguardando} />
                </Link>
            </Form>

            <br />
            <br />
            <div>
                <h2 className="mr-auto p-2 display-4 titulo">Alterar Senha</h2>
            </div>
            <hr />
            <Form onSubmit={atualizarSenha}>
                <div className="form-group row">
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="senhaAtual">Senha atual</Label>
                            <Input
                                type="password"
                                value={senhaAtual}
                                name="senhaAtual"
                                id="senhaAtual"
                                autoComplete="senhaAtual"
                                onChange={(ev) => setSenhaAtual(ev.target.value)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="senha">Nova senha (Mínimo 6 caracteres)</Label>
                            <Input
                                type="password"
                                value={senha}
                                name="senha"
                                id="senha"
                                autoComplete="senha"
                                onChange={(ev) => setSenha(ev.target.value)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-4">
                        <FormGroup>
                            <Label for="confirmaSenha">Confirme a senha</Label>
                            <Input
                                type="password"
                                value={confirmaSenha}
                                name="confirmaSenha"
                                id="confirmaSenha"

                                autoComplete="confirmaSenha"
                                onChange={(ev) => setConfirmaSenha(ev.target.value)}
                            />
                        </FormGroup>
                    </div>
                </div>
                <BotaoAlterarSenha aguardando={aguardando} />
            </Form>
        </>
    );
}

export default UsuarioAltearPerfil;