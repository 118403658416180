import {PESQUISAR_CODIGO_LIBERACAO} from '../../core/store/types';

const reducersLiberacao = (state = {}, action) => {
    switch (action.type) {
        case PESQUISAR_CODIGO_LIBERACAO:
            return{
                ...state,
                chaveLiberacaoPesquisados: action.payload._embedded.chaveLiberacao,
            }
            
        default:
            return state;
    }
}

export default reducersLiberacao