import React, { useState } from 'react';
import logo_sistema from '../../../assets/images/logo_sistema.png';
import ModalErro from '../../../components/ModalErro';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalSucesso from '../../../components/ModalSucesso'
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import { Form, InputGroup, FormGroup, Input } from 'reactstrap';
import validator from 'validator';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const CriarConta = (props) => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");    
    const [erro, setErro] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [atencao, setAtencao] = useState(false);
    const [msgAtencao,setMsgAtencao] = useState("")
    const [msgErro, setMsgErro] = useState("")
    const [msgSucesso,setMsgSucesso] = useState("")
    const [aguardando, setAguardando] = useState(false);
    const [formularioPronto, setFormularioPronto] = useState(false);
    const [criticaEvaluation, setCriticaEvaluation] = useState(false)

    const criticas = () => {
        if (!nome) {
                setMsgAtencao({ mensagem: "Preencha o campo nome!" })
                setAtencao(true)
                return false
            };
        if (!email){
                setMsgAtencao({ mensagem: "Preencha o campo e-mail!" })
                setAtencao(true)
                return false
            };
        if (!validator.isEmail(email)){
                setMsgAtencao({ mensagem: "Preencha com e-mail válido!" })
                setAtencao(true)
                return false
            };
        if (!senha){
                setMsgAtencao({ mensagem: "Preencha o campo senha!" })
                setAtencao(true)
                return false
        };
        if (senha.length < 6){
                setMsgAtencao({ mensagem: "A senha precisa ter pelo menos seis caracteres!" })
                setAtencao(true)
                return false
            };
        return true;
    }

    const criarConta = (e) => {
        e.preventDefault();
        if (!criticas()) return;
        setAguardando(true);
        props.criarConta({ nome, email, senha }, (retorno) => {
            if (retorno.erro.mensagem) {
                setMsgAtencao("");
                setSucesso("");
                setErro(true)
                setMsgErro({ mensagem: retorno.erro.mensagem });
                setAguardando(false);
            } else {
                setMsgAtencao("");
                setMsgErro("");
                setSucesso(true)
                setMsgSucesso({ mensagem: "Conta criada com sucesso!" });
                setFormularioPronto(true);
                setAguardando(false);
            }
        })
    }

    if (formularioPronto) {
        return <Redirect to={{
            pathname: '/',
            state: { 
                    sucesso:sucesso,
                    msgSucesso:msgSucesso 
                }
        }} />
    }

    return (
        <div className="container-login">
            <div className="login card shadow">
                <Form onSubmit={criarConta} className="form-signin text-center">
                    <img className="mb-4" src={logo_sistema} alt="SAC" width="72" height="72" />
                    <h1 className="h3 mb-3 font-weight-normal">Criar Conta</h1>
                    <ModalErro isOpen={erro} toggle={()=>setErro(!erro)} erro={msgErro}/>
                    <ModalAtencao isOpen={atencao} toggle={() => setAtencao(!atencao)} atencao={msgAtencao} />
                    <ModalSucesso isOpen={sucesso} toggle={() => setSucesso(!sucesso)} sucesso={msgSucesso} />
                    <FormGroup>
                        <InputGroup>
                            <Input style={{ height: 40 }}
                                type="nome"
                                value={nome}
                                name="nome"
                                id="nome"
                                placeholder="Digite seu nome"
                                onChange={(ev) => setNome(ev.target.value)} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <Input style={{ height: 40 }}
                                type="email"
                                value={email}
                                name="email"
                                id="email"
                                placeholder="Digite seu email"
                                onChange={(ev) => setEmail(ev.target.value)} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <Input style={{ height: 40 }}
                                type="password"
                                value={senha}
                                name="senha"
                                id="senha"
                                placeholder="Digite sua senha"
                                onChange={(ev) => setSenha(ev.target.value)} required />
                        </InputGroup>
                    </FormGroup>
                    <BotaoConfirmar aguardando={aguardando} />
                    <br />
                    <Link to="/">Login</Link>
                </Form>
            </div>
        </div>
    );
}

export default CriarConta;