import {
  BUSCAR_CHAVEGERADA,
  LISTAR_CHAVEGERADA,
  PESQUISAR_CHAVEGERADA,
} from "../../core/store/types";

const reducersChavesGeradas = (state = {}, action) => {
  switch (action.type) {
    case BUSCAR_CHAVEGERADA:
      return {
        ...state,
        chaveGerada: action.payload,
      };
    case LISTAR_CHAVEGERADA:
      return {
        ...state,
        chavesGeradas: action.payload._embedded.chavesGeradas,
      };
    case PESQUISAR_CHAVEGERADA:
      return {
        ...state,
        chavesGeradasPesquisadas: action.payload,
      };
    default:
      return state;
  }
};

export default reducersChavesGeradas;
