import axios from "axios";
import {
  BUSCAR_ATENDIMENTO,
  LIMPAR_ATENDIMENTO,
  LIMPAR_ATENDIMENTOS,
  // LISTAR_ATENDIMENTOS,
  PESQUISAR_ATENDIMENTOS,
  PESQUISAR_ATENDIMENTOS_POR_STATUS,
  PESQUISAR_TOTAL_ATENDIMENTOS,
  PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO,
  PESQUISAR_TOTAL_MENSAL_POR_CLIENTE,
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

// axios.defaults.params={}
// axios.defaults.params['dataEmissaoInicio'] = '2000-01-01T00:00:00Z'

export const salvarAtendimento = (dadosAtendimento, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/servicos`, dadosAtendimento, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarAtendimentos = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(
        `${api}/v1/servicos?clienteNome=${dadosFiltro.clienteNome}&statusId=${dadosFiltro.id
        }&solicitante=${dadosFiltro.solicitante
        }&dataEmissaoInicio=${"2000-01-01T00:00:01Z"}&size=50&page=${dadosFiltro.page
        }&sort=id,desc`,
        headers()
      )
      .then((response) => {
        dispatch({ type: PESQUISAR_ATENDIMENTOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarAtendimentosBotao = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(
        `${api}/v1/servicos?clienteNome=${dadosFiltro.clienteNome}&statusId=${dadosFiltro.id
        }&solicitante=${dadosFiltro.solicitante}&dataEmissaoInicio=${dadosFiltro.dataInicial
        }&dataEmissaoFim=${dadosFiltro.dataFinal}&size=50&page=${dadosFiltro.page}&sort=id,desc`,
        headers()
      )
      .then((response) => {
        dispatch({ type: PESQUISAR_ATENDIMENTOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarAtendimentosPorStatus = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/estatisticas/servicos-por-status`, headers())
      .then((response) => {
        dispatch({
          type: PESQUISAR_ATENDIMENTOS_POR_STATUS,
          payload: response.data,
        });
      })
      .catch(errorHandler);
  };
};

export const limparAtendimento = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_ATENDIMENTO });
  };
};

export const limparAtendimentos = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_ATENDIMENTOS });
  };
};

export const removerAtendimento = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/servicos/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const getAtendimento = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/servicos/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_ATENDIMENTO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarAtendimento = (dadosAtendimento, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/servicos/${dadosAtendimento.id}`,
        {
          solicitante: dadosAtendimento.solicitante,
          problema: dadosAtendimento.problema,
          solucao: dadosAtendimento.solucao,
          status: { id: dadosAtendimento.status.id },
          cliente: { id: dadosAtendimento.cliente.id },
          natureza: { id: dadosAtendimento.natureza.id },
          usuario: { id: dadosAtendimento.usuario.id },
        },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarTotalAtendimentos = () => {
  return (dispatch) => {
    axios
      .get(
        `${api}/v1/estatisticas/servicos-ultima-semana?timeOffset=-03:00`,
        headers()
      )
      .then((response) => {
        dispatch({
          type: PESQUISAR_TOTAL_ATENDIMENTOS,
          payload: response.data,
        });
      })
      .catch(errorHandler);
  };
};

export const pesquisarTotalAtendimentosPorTipos = () => {
  return (dispatch) => {
    axios
      .get(
        `${api}/v1/estatisticas/servicos-ultima-semana-por-status?timeOffset=-03:00`,
        headers()
      )
      .then((response) => {
        dispatch({
          type: PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO,
          payload: response.data,
        });
      })
      .catch(errorHandler);
  };
};
export const pesquisarTotalMensalPorCliente = () => {
  return (dispatch) => {
    axios
      .get(
        `${api}/v1/estatisticas/servicos-mensal-por-cliente?timeOffset=-03:00`,
        headers()
      )
      .then((response) => {
        dispatch({
          type: PESQUISAR_TOTAL_MENSAL_POR_CLIENTE,
          payload: response.data,
        });
      })
      .catch(errorHandler);
  };
};
