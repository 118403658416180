export const INICIO_URLS = ['/sac/pagina-inicial'];

export const DISPOSITIVOS_URLS = ['/sac/dispositivos'];

export const ATENDIMENTO_URLS = [
  '/sac/painel-de-controle/:slug',
  '/sac/painel-de-controle/',
  '/sac/atendimentos',
  '/sac/atendimentos-cadastrar',
  '/sac/atendimentos-alterar/:slug',
];

export const APLICACAO_URLS = [
  '/sac/aplicacoes',
  '/sac/aplicacoes-cadastrar',
  '/sac/aplicacoes-alterar/:slug',
];
export const NATUREZA_URLS = [
  '/sac/naturezas',
  '/sac/naturezas-cadastrar',
  '/sac/naturezas-alterar/:slug',
];
export const USUARIO_URLS = [
  '/sac/usuarios',
  '/sac/usuarios-cadastrar',
  '/sac/usuarios-alterar/:slug',
];
export const CONTRATOS_URLS = [
  '/sac/Contratos',
  '/sac/contratos-cadastrar',
  '/sac/contratos-alterar/:slug',
];
export const LIBERACAO_URLS = [
  '/sac/liberacao',
  '/sac/liberacao-cadastrar',
  '/sac/liberacao-alterar/:slug',
];
export const CLIENTE_URLS = [
  '/sac/clientes',
  '/sac/clientes-cadastrar',
  '/sac/clientes-alterar/:slug',
];

export const PERFIL_URLS = ['/sac/usuarios-alterar-perfil'];

export const ASSOCIACOES_URLS = ['/sac/associacoes'];

export const LISTACHAVES_URLS = ['/sac/chaves-geradas']

export const GRUPOS_URLS = [
  '/sac/grupos',
  '/sac/grupos-cadastrar',
  '/sac/grupos-alterar/:slug',
];
