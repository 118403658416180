import React from 'react';
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';
import { EnterClick } from "../../hooks/EnterClick";
import BotaoCancelar from '../BotaoCancelar';
import BotaoConfirmar from '../BotaoConfirmar';

const ModalDesativar = (props) => {
  EnterClick(props.toggle, 'Enter')

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader toggle={props.toggle}>
          Desativar {props.desativar}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Alert color='warning'>
                <h4 className='alert-heading'>Atenção!</h4>
                <p>
                  Após a confirmação desta operação, os dados não poderão ser
                  ultilizados no sistema!
                </p>
                <hr />
                <p className='mb-0'>Tem certeza que quer desativar?</p>
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <span onClick={() => props.desativarObjeto()}>
            <BotaoConfirmar className='m-1' aguardando={props.aguardando} />
          </span>
          <BotaoCancelar className='m-1' onClickCancelar={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalDesativar;
