import axios from 'axios';
import {
    LIMPAR_NATUREZA,
    LIMPAR_NATUREZAS,
    PESQUISAR_NATUREZAS
} from '../../core/store/types';
import { api } from '../../core/config';
import { headers } from '../../core/store/localStorage';
import errorHandler from '../../core/store/errorHandler';

export const limparNatureza = () => {
    return (dispatch) => {
        dispatch({ type: LIMPAR_NATUREZA });
    }
}

export const limparNaturezas = () => {
    return (dispatch) => {
        dispatch({ type: LIMPAR_NATUREZAS });
    }
}

export const pesquisarNaturezas = (callback) => {
    return (dispatch) => {
        axios.get(`${api}/v1/naturezas`, headers())
            .then((response) => {
                dispatch({ type: PESQUISAR_NATUREZAS, payload: response.data });
            })
            .catch((err) => callback(errorHandler(err)));
    }
}

export const salvarNatureza = (dadosNatureza, callback) => {
    return (dispatch) => {
        axios.post(`${api}/v1/naturezas`, dadosNatureza, headers())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandler(err)));
    }
}