import axios from "axios";
// import { Base64 } from "js-base64";
import {
  BUSCAR_DISPOSITIVO,
  // LISTAR_DISPOSITIVO,
  PESQUISAR_DISPOSITIVOS,
  LIMPAR_DISPOSITIVO,
  LIMPAR_DISPOSITIVOS,
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const limparDispositivo = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_DISPOSITIVO });
  };
};

export const limparDispositivos = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_DISPOSITIVOS });
  };
};

export const getDispositivo = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/dispositivos/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_DISPOSITIVO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarDispositivos = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/dispositivos?mac=${dadosFiltro.mac}&nomeComputador=${dadosFiltro.nomeComputador}&clienteId=${dadosFiltro.clienteId}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_DISPOSITIVOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const ativarDispositivo = (dadosDispositivo, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/dispositivos/${dadosDispositivo.id}/ativa`,
        { ativo: dadosDispositivo.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarDispositivo = (dadosDispositivo, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/dispositivos/${dadosDispositivo.id}/desativa`,
        { ativo: dadosDispositivo.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarDispositivosBotao = (dadosFiltro) => {
  return (dispatch) => {
      // {console.log(dadosFiltro)}   
      axios.get(`${api}/v1/dispositivos?mac=${dadosFiltro.mac}&nomeComputador=${dadosFiltro.nomeComputador}&clienteId=${dadosFiltro.clienteId}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_DISPOSITIVOS, payload: response.data });
          })
          .catch(errorHandler);
  }
}
