import React from 'react';
import { Button } from 'reactstrap';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import '../../assets/styles/painelDeControle/index.css';

const BotaoPdf = (props) => {
    return (
        <>
            <Button
                onClick={props.onClickGeraPdf}
                id="idPdf"
                className="btn btn-sm mt-2 btn-exclui">
                <PictureAsPdfOutlinedIcon />
                <span> Gerar PDF</span>
            </Button>
        </>
    );
};

export default BotaoPdf;