import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import * as actionsGrupos from "../../../domain/actions/actionsGrupos";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

const GrupoCadastrar = (props) => {
  const [nome, setNome] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);
  const [formularioPronto, setFormularioPronto] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  const cadastrarGrupo = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;
    setAguardando(true);

    props.salvarGrupos({ nome }, (retorno) => {
      if (retorno.erro.mensagem) {
        setAtencao("");
        setSucesso("");
        setErro({ mensagem: retorno.erro.mensagem });
        setAguardando(false);
        setFormularioErro(true);
      } else {
        setAtencao("");
        setErro("");
        setSucesso({ mensagem: "Aplicação cadastrada com sucesso!" });
        setFormularioSucesso(true);
        setAguardando(false);
      }
    });
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioPronto(true);
  };

  if (formularioPronto) {
    return <Redirect to={{ pathname: "/sac/grupos" }} />;
  }

  const criticas = () => {
    if (!nome) return setAtencao({ mensagem: "Preencha o campo nome!" });
    return true;
  };

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Cadastrar Grupo</h2>
        </div>
      </div>
      <hr />
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="name">Nome</Label>
            <Input
              type="text"
              value={nome}
              name="nome"
              id="nome"
              placeholder="Nome do Grupo"
              autoComplete="nome"
              onChange={(ev) => setNome(ev.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <Link onClick={() => cadastrarGrupo()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"/sac/grupos"}>
        <BotaoVoltar />
      </Link>
    </>
  );
};
export default connect(null, actionsGrupos)(GrupoCadastrar);
