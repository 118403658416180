import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import LogicaGraficos from '../LogicaGraficos';

class Pizza extends Component{
    state ={
        chartData : []
    }

    callBackFunction = (childData) =>{
        this.setState({chartData:childData})
    }

    render(){
        return(
            <>
                <LogicaGraficos parentCallback={this.callBackFunction} tipo={'Pizza'}/>
                <div className='card_graph'>    
                    <center>
                        <p>Distribuição de atendimentos</p>
                        <p className='card_subtitle'>Por total por empresa</p>
                    </center>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Carregando Gráfico</div>}
                        data={this.state.chartData}
                        options={{
                                // Just add this option
                            legend: "none",
                            is3D: false,
                            backgroundColor: 'rgb(255, 255, 255)',
                            chartArea:{
                                width:'100%'
                            },
                        }}
                        rootProps={{ 'data-testid': '2' }}
                        />
                </div>
                    <br />
            </>        
        )
    }
}

export default Pizza