import React from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
// import { EnterClick } from '../../hooks/EnterClick';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import BotaoOk from "../BotaoOk";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/liberaExterno.css";

const ModalAtencaoAlt = (props) => {
  // EnterClick(props.toggle, 'Enter')

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={css(styles.container)}
        centered
      >
        <ModalHeader toggle={props.toggle}>Atenção</ModalHeader>
        <ModalBody>
          <FormGroup className="row mb-0">
            <div className="col-sm-12">
              {!props.passwordAttention && !props.emailAttention ? (
                <Alert color="warning">
                  <div style={{ display: "flex" }}>
                    <WarningRoundedIcon
                      style={{
                        color: "#F79400",
                        fontSize: 45,
                        display: "block",
                      }}
                    />
                    <p className="ml-2">
                      O campo <b>{props.field}</b> deve ser preenchido!
                    </p>
                  </div>
                  {/* <p className='ml-2'>Preencha este campo e tente novamente.</p> */}
                </Alert>
              ) : (
                false
              )}
              {props.passwordAttention ? (
                <Alert color="warning">
                  <p>As suas senhas não são iguais!</p>
                  <p>Verifique estes campos e tente novamente.</p>
                </Alert>
              ) : (
                false
              )}
              {props.emailAttention ? (
                <Alert color="warning">
                  <p>Este não é um e-mail valido!</p>
                  <p>
                    Preencha o campo com um e-mail válido e tente novamente.
                  </p>
                </Alert>
              ) : (
                false
              )}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAtencaoAlt;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    fontSize: 15,
  },
});
