import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import validator from "validator";
import { connect } from "react-redux";
import * as actionsUsuario from "../../../domain/actions/actionsUsuario";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

const NaturezaCadastrar = (props) => {
  const [nome, setNome] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);
  const [formularioPronto, setFormularioPronto] = useState(false);
  const [padrao, setPadraoChecked] = useState(false);
  const [informaConclusao, setInformaConclusao] = useState(false);
  const [invisivel, setInvisivelChecked] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  const cadastrarNaturezas = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;
    setAguardando(true);
    props.salvarNatureza({ nome, padrao, informaConclusao, invisivel }, (retorno) => {
      if (retorno.erro.mensagem) {
        setAtencao("");
        setSucesso("");
        setErro({ mensagem: retorno.erro.mensagem });
        setAguardando(false);
        setFormularioErro(true);
      } else {
        setAtencao("");
        setErro("");
        setSucesso({ mensagem: "Natureza cadastrada com sucesso!" });
        setFormularioSucesso(true);
        setAguardando(false);
      }
    });
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioPronto(true);
  };

  if (formularioPronto) {
    return <Redirect to={{ pathname: "/sac/naturezas" }} />;
  }

  const criticas = () => {
    if (!nome) return setAtencao({ mensagem: "Preencha o campo descrição!" });
    return true;
  };

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Cadastrar Natureza</h2>
        </div>
      </div>
      <hr />
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="name">Nome</Label>
            <Input
              type="text"
              value={nome}
              name="nome"
              id="nome"
              placeholder="Descrição da Natureza"
              autoComplete="nome"
              onChange={(ev) => setNome(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-1">
          <FormGroup className="checkbox-wrapper">
            <Label className="container" for="padrao">
              Padrão
            </Label>
            <Input
              className={padrao ? "checked" : ""}
              type="checkbox"
              name="padrao"
              id="padrao"
              autoComplete="padrao"
              onChange={() => setPadraoChecked(!padrao)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-2">
          <FormGroup className="checkbox-wrapper">
            <Label className="container" for="informaConclusao">
              Informa Conclusão
            </Label>
            <Input
              style={{marginLeft: "3.5rem"}}
              className={informaConclusao ? "checked" : ""}
              type="checkbox"
              name="informaConclusao"
              id="informaConclusao"
              autoComplete="informaConclusao"
              onChange={() => setInformaConclusao(!informaConclusao)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-2">
          <FormGroup className="checkbox-wrapper">
            <Label className="container" for="invisivel">
              Invisível
            </Label>
            <Input
              //style={{marginLeft: "1.5rem"}}
              className={invisivel ? "checked" : ""}
              type="checkbox"
              name="invisivel"
              id="invisivel"
              autoComplete="invisivel"
              onChange={() => setInvisivelChecked(!invisivel)}
            />
          </FormGroup>
        </div>        
      </div>
      <Link onClick={() => cadastrarNaturezas()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"/sac/naturezas"}>
        <BotaoVoltar />
      </Link>
    </>
  );
};
export default connect(null, actionsUsuario)(NaturezaCadastrar);
