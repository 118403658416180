import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../assets/styles/painelDeControle/index.css";

const BotaoExcluir = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        id="idApagar"
        className="btn-acoes btn-exclui btn-sm m-1"
      >
        <DeleteIcon className="icon-acoes" />
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="idApagar"
        toggle={toggle}
      >
        Excluir
      </Tooltip>
    </>
  );
};

export default BotaoExcluir;
