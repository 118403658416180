import React from "react";
import logoIvitech from "../../assets/images/logo_principal.png";

import "./logo.css";

// import { Jumbotron, Button } from 'reactstrap';

const PaginaInicial = ({ tickets }) => {
  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Seja bem vindo!</h2>
        </div>
      </div>
      <hr />
      <img src={logoIvitech} alt="logoPrincipal" className='logo'/>
    </>
  );
};

export default PaginaInicial;
