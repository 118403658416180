import React from "react";
import { useState } from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
// import { EnterClick } from "../../hooks/EnterClick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "../../assets/styles/liberaExterno.css";
import { StyleSheet, css } from "aphrodite";

import { Redirect } from "react-router-dom";

import BotaoOk from "../BotaoOk";

const ModalErroRetorna = (props) => {
  const [retorna, setRetorna] = useState(false);
  const [permanece, setPermanece] = useState(false);

  function btnAction() {
    if (props.erro.mensagem.includes("permissão")) {
      console.log(props.erro.mensagem);
      setRetorna(true);
    } else {
      console.log(props.erro.mensagem);
      console.log(btnAction);
      setPermanece(true);
    }
  }

  if (retorna) {
    return <Redirect to="/sac/pagina-inicial" />;
  }

  if (permanece) {
    return props.toggle;
  }

  //   EnterClick(props.toggle, 'Enter')

  return (
    <div className={css(styles.container)}>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader className={css(styles.container)} toggle={props.toggle}>
          <b>Erro</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="row mb-0">
            <div className="col-sm-12">
              <Alert
                className={css(styles.container)}
                color="danger"
                style={{ whiteSpace: "pre-line" }}
              >
                <ErrorOutlineIcon
                  style={{
                    color: "#C73101",
                    fontSize: 45,
                    marginRight: "1rem",
                  }}
                />
                {/* <span className="ml-2"> */}
                {props.erro.mensagem}
                {/* </span>*/}
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={btnAction} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalErroRetorna;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
  },
});
