import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import CheckIcon from "@mui/icons-material/Check";

const BotaoAtivar = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        id="idAtivar"
        className="btn-acoes btn-ativa btn-sm m-1"
      >
        <CheckIcon className="icon-acoes" />
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="idAtivar"
        toggle={toggle}
      >
        Ativar
      </Tooltip>
    </>
  );
};

export default BotaoAtivar;
