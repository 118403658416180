import React, { Component } from "react";
import { Chart } from "react-google-charts";
import LogicaGraficos from "../LogicaGraficos";
import "../../../assets/styles/card.css";

class Barra extends Component {
  state = {
    chartData: [],
  };

  callBackFunction = (childData) => {
    this.setState({ chartData: childData });
  };

  render() {
    return (
      <>
        <LogicaGraficos parentCallback={this.callBackFunction} tipo={"Barra"} />
        <div className="card_graph">
          <center>
            <p>Distribuição de atendimentos</p>
            <p className="card_subtitle">Dia da semana x tipo de atendimento</p>
          </center>
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="ColumnChart"
            loader={<div>Carregando Gráfico</div>}
            data={this.state.chartData}
            options={{
              // colors: ['black','green','blue'],
              // chartArea: {
              //     backgroundColor: {
              //     fill: 'red',
              //     fillOpacity: 0.2
              //     },

              // },
              chartArea: {
                width: "94%",
              },
              backgroundColor: "rgb(255, 255, 255)",
              legend: "none",
              colors: ["#27b2db", "#fb534e", "#339966"],
            }}
            // For tests
            rootProps={{ "data-testid": "2" }}
            // controls={[
            //     {
            //         controlType: 'StringFilter',
            //         options: {
            //         filterColumnIndex: 0,
            //         matchType: 'any', // 'prefix' | 'exact',
            //         ui: {
            //             label: 'Pesquisa Por Dia',
            //         },
            //         },
            // }
            // ]}
          />
        </div>
      </>
    );
  }
}

export default Barra;
