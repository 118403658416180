import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionsChavesGeradas from "../../../domain/actions/actionsChavesGeradas";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import ModalErro from "../../../components/ModalErro";

import { Link } from "react-router-dom";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

import BotaoInfo from "../../../components/BotaoInfo/index";
import BotaoPdf from "./../../../components/BotaoPDF/index";
import BotaoPesquisar from "../../../components/BotaoPesquisar";
import ModalCarregando from "../../../components/ModalCarregando";

import { StyleSheet, css } from "aphrodite";

import "../../../assets/styles/listarPagination.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

import chavesGeradasPDF from "../../../reports/chavesGeradasPDF";

class ChavesGeradas extends Component {
  state = {
    idCliente: "",
    mac: "",
    inicioLiberacao: "",
    finalLiberacao: "",
    mensagem: "",
    erro: "",
    atencao: "",
    aguardando: false,
    formularioAtencao: false,
    page: 0,
    numPages: 0,
  };

  ajustaInicioLiberacao = (dataOriginal) => {
    dataOriginal
      ? this.setState({ inicioLiberacao: dataOriginal })
      : this.setState({ inicioLiberacao: "" });
  };

  ajustaFinalLiberacao = (dataOriginal) => {
    dataOriginal
      ? this.setState({ finalLiberacao: dataOriginal })
      : this.setState({ finalLiberacao: "" });
  };

  criticas() {
    const { dataInicial, dataFinal } = this.state;

    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  //Início da rotina de pesquisa
  pesquisarChaveGerada() {
    const { mac, idCliente, inicioLiberacao, finalLiberacao, page } =
      this.state;

    if (this.props.location.state) {
      this.props.pesquisarChaveGerada({
        mac,
        inicioLiberacao,
        finalLiberacao,
        clienteId: idCliente,
        page,
      });
    } else {
      this.props.pesquisarChaveGerada({
        mac,
        inicioLiberacao,
        finalLiberacao,
        clienteId: idCliente,
      });
    }
  }

  pesquisarChavesBotao() {
    const { mac, inicioLiberacao, finalLiberacao, idCliente, page } =
      this.state;

    if (inicioLiberacao <= finalLiberacao) {
      this.props.pesquisarChavesBotao({
        mac,
        inicioLiberacao: inicioLiberacao ? inicioLiberacao + "T01:00:00Z" : "",
        finalLiberacao: finalLiberacao ? finalLiberacao + "T23:59:59Z" : "",
        clienteId: idCliente,
        page,
      });
    } else {
      this.props.pesquisarChavesBotao({
        mac,
        inicioLiberacao: inicioLiberacao ? inicioLiberacao + "T01:00:00Z" : "",
        finalLiberacao: finalLiberacao ? finalLiberacao + "T23:59:59Z" : "",
        clienteId: idCliente,
        page,
      });
    }
  }

  paginationHandler = () => {
    var pages = 1;
    if (this.props.chavesGeradasPesquisadas)
      pages = this.props.chavesGeradasPesquisadas.page.totalPages;
    var curPages = [];
    for (let i = 0; i < pages; i++) {
      curPages.push(i);
    }
    return curPages.map((page) => {
      return (
        <PaginationItem
          active={this.state.page === page ? true : false}
          className={"paginationItemStyle"}
        >
          <PaginationLink
            className={"paginationLinkStyle"}
            onClick={() =>
              this.setState({ page: page }, this.pesquisarChavesBotao)
            }
          >
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      );
    });
  };

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };
  //Fim da rotina de pesquisa

  componentDidMount() {
    const { location } = this.props;
    location.state
      ? this.pesquisarChaveGerada(location.state.statusId)
      : this.pesquisarChaveGerada("");
    this.props.pesquisarClientes();
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      this.state.clienteNome !== "" &&
      prevState.clienteNome !== this.state.clienteNome
    ) {
      this.pesquisarChavesBotao("");
    } else {
      if (
        !this.props.chavesGeradasPesquisadas &&
        nextProps.chavesGeradasPesquisadas
      )
        this.pesquisarChavesBotao("");
    }
  }

  componentWillUnmount() {
    this.props.limparChavesGeradas();
    this.props.limparChaveGerada();
  }

  render() {
    const {
      idCliente,
      mac,
      inicioLiberacao,
      finalLiberacao,
      mensagem,
      atencao,
      aguardando,
      formularioAtencao,
    } = this.state;
    console.log(this.props.chavesGeradasPesquisadas);

    var numPages = 1;
    if (
      this.props.chavesGeradasPesquisadas &&
      this.props.chavesGeradasPesquisadas.page.totalPages
    )
      numPages = this.props.chavesGeradasPesquisadas.page.totalPages;

    var clientes = [];
    if (this.props.clientesPesquisados)
      clientes = this.props.clientesPesquisados;

    var chavesGeradas = [];
    var pages = 0;

    if (
      this.props.chavesGeradasPesquisadas &&
      this.props.chavesGeradasPesquisadas._embedded
    ) {
      chavesGeradas =
        this.props.chavesGeradasPesquisadas._embedded.chavesGeradas;
    }
    var qtde = chavesGeradas.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        {/* <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        /> */}
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Chaves Geradas</h2>
          </div>
          <div>
            <FormGroup>
              <BotaoPesquisar
                onClickPesquisar={() => {
                  this.setState({ page: 0 });
                  this.props.limparChavesGeradas();
                  this.pesquisarChavesBotao("");
                }}
              />
            </FormGroup>
          </div>
          <div className="ml-1">
            <FormGroup style={{ marginTop: 1 }}>
              <BotaoPdf onClickGeraPdf={() => chavesGeradasPDF(chavesGeradas)} />
            </FormGroup>
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <div className="col-sm-3">
            <FormGroup>
              <Label for="cliente">Cliente</Label>
              <Input
                type="select"
                name="cliente"
                id="cliente"
                className="form-control"
                onChange={(ev) => this.onChangeInput("idCliente", ev)}
              >
                <option value="">{""}</option>
                {clientes.map((cliente) => (
                  <option value={cliente.id}>{cliente.nomeReduzido}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="mac">Mac</Label>
              <Input
                id="mac"
                name="mac"
                onChange={(ev) => this.onChangeInput("mac", ev)}
                type="text"
                value={mac}
                autoComplete="mac"
                placeholder="Filtrar por Mac"
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="inicioLiberacao">Início</Label>
              <Input
                type="date"
                name="inicioLiberacao"
                id="inicioLiberacao"
                placeholder="Data inicial"
                value={inicioLiberacao}
                onChange={(ev) => this.ajustaInicioLiberacao(ev.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="dataFinal">Fim</Label>
              <Input
                type="date"
                name="dataFinal"
                id="dataFinal"
                placeholder="Data final"
                value={finalLiberacao}
                onChange={(ev) => this.ajustaFinalLiberacao(ev.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        {!this.props.chavesGeradasPesquisadas && (
          <ModalCarregando isOpen={true} pagina="Chaves geradas" />
        )}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th className="text-center">Mac</Th>
              <Th className="text-center">Cliente</Th>
              <Th className="text-center">Início da Liberação</Th>
              <Th className="text-center">Fim da Liberação</Th>
              <Th className="text-center">Aplicação</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {chavesGeradas.map((chaveGerada) => {
              let parity = qtde % 2;
              qtde = qtde - 1;
              return (
                <Tr
                  key={chaveGerada.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{chaveGerada.id}</Td>
                  <Td className="text-center">{chaveGerada.mac}</Td>
                  <Td className="text-center">
                    {chaveGerada.contrato.cliente.nomeReduzido}
                  </Td>
                  <Td className="text-center">
                    {format(
                      new Date(chaveGerada.inicioLiberacao),
                      "dd-MM-yyyy HH:mm",
                      { locale: pt }
                    )}
                  </Td>
                  <Td className="text-center">
                    {format(
                      new Date(chaveGerada.finalLiberacao),
                      "dd-MM-yyyy HH:mm",
                      { locale: pt }
                    )}
                  </Td>
                  <Td className="text-center">{chaveGerada.aplicacao.nome}</Td>
                  <Td className="text-center">
                    <span>
                      <Link to={`/sac/chaves-geradas-info/${chaveGerada.id}`}>
                        <BotaoInfo></BotaoInfo>
                      </Link>
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <br />
        {this.props.chavesGeradasPesquisadas ? (
          <div>
            <Pagination className={css(styles.paginationContainer)}>
              <PaginationItem className={"paginationItemStyle"}>
                <PaginationLink
                  first
                  className={"paginationLinkStyle"}
                  onClick={() => {
                    this.setState({ page: 0 }, this.pesquisarChavesBotao);
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={"paginationItemStyle"}
                disabled={
                  this.state.page === 0 || this.state.page === "0"
                    ? true
                    : false
                }
              >
                <PaginationLink
                  className={"paginationLinkStyle"}
                  previous
                  onClick={() => {
                    this.setState(
                      { page: this.state.page - 1 },
                      this.pesquisarChavesBotao
                    );
                  }}
                />
              </PaginationItem>
              {this.paginationHandler()}
              <PaginationItem
                className={"paginationItemStyle"}
                disabled={this.state.page !== numPages - 1 ? false : true}
              >
                <PaginationLink
                  className={"paginationLinkStyle"}
                  next
                  onClick={() => {
                    this.setState(
                      { page: this.state.page + 1 },
                      this.pesquisarChavesBotao
                    );
                  }}
                />
              </PaginationItem>
              <PaginationItem className={"paginationItemStyle"}>
                <PaginationLink
                  className={"paginationLinkStyle"}
                  last
                  onClick={() => {
                    this.setState(
                      { page: numPages - 1 },
                      this.pesquisarChavesBotao
                    );
                  }}
                />
              </PaginationItem>
            </Pagination>
          </div>
        ) : (
          false
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

const mapStateToProps = (state) => ({
  clientesPesquisados: state.cliente.clientesPesquisados,
  chavesGeradasPesquisadas: state.chavesGeradas.chavesGeradasPesquisadas,
});

export default connect(mapStateToProps, actionsChavesGeradas)(ChavesGeradas);

// const ChavesGeradas = (props) => {
//   const [erro, setErro] = useState("");
//   const [modalInfo, setModalInfo] = useState("");
//   const [cliente, setCliente] = useState("");
//   const [mac, setMac] = useState("");
//   const [inicioLiberacao, setInicioLiberacao] = useState("");
//   const [finalLiberacao, setFinalLiberacao] = useState("");
//   const [page, setPage] = useState(0);
//   const [numPages, setNumPages] = useState("");
//   const [idCliente, setIdCliente] = useState("");
//   const [field, setField] = useState("");

//   const [formularioErro, setFormularioErro] = useState(0);
//   const [formularioAtencao, setFormularioAtencao] = useState(false);

//   useEffect(() => {
//     pesquisarChaveGerada();
//     pesquisarClientes();
//     console.log(chavesGeradas);
//   }, []);

//   const pesquisarChaveGerada = () => {
//     props.pesquisarChaveGerada();
//   };

//   const pesquisarClientes = () => {
//     props.pesquisarClientes();
//   };

//   function ajustaInicioLiberacao(dataOriginal) {
//     dataOriginal ? setInicioLiberacao(dataOriginal) : setInicioLiberacao("");
//   }

//   function ajustaFinalLiberacao(dataOriginal) {
//     dataOriginal ? setFinalLiberacao(dataOriginal) : setFinalLiberacao("");
//   }

//   let clientes = [];
//   if (props.clientesPesquisados) clientes = props.clientesPesquisados;

//   let chavesGeradas = [];
//   if (props.chavesGeradasPesquisadas) {
//     chavesGeradas = props.chavesGeradasPesquisadas._embedded.chavesGeradas;
//   }

//   let qtde = chavesGeradas.length;
//   if (qtde % 2 === 0) {
//     qtde += 1;
//   }

//   function atualizaPagina() {
//     setPage(page);
//   }

//   function modificaPagina(props) {
//     setPage(props);
//   }

//   function fecharFormularioErro() {
//     setFormularioErro(false);
//   }

//   function pesquisarChavesBotao() {
//     if (inicioLiberacao <= finalLiberacao) {
//       props.pesquisarChavesBotao({
//         mac,
//         inicioLiberacao: inicioLiberacao ? inicioLiberacao + "T01:00:00Z" : "",
//         finalLiberacao: finalLiberacao ? finalLiberacao + "T23:59:59Z" : "",
//         clienteId: idCliente,
//         page,
//       });
//     } else {
//       setInicioLiberacao("2000-01-01");
//       props.pesquisarChavesBotao({
//         mac,
//         inicioLiberacao: inicioLiberacao ? inicioLiberacao + "T01:00:00Z" : "",
//         finalLiberacao: finalLiberacao ? finalLiberacao + "T23:59:59Z" : "",
//         clienteId: idCliente,
//         page,
//       });
//     }
//   }

//   const paginationHandler = (props) => {
//     let pages = 1;
//     if (props.chavesGeradasPesquisadas) {
//       pages = props.chavesGeradasPesquisadas.page.totalPages;
//     }
//     let curPages = [];
//     for (let i = 0; i < pages; i++) {
//       curPages.push(i);
//     }
//     return curPages.map((page) => {
//       return (
//         <PaginationItem
//           active={page === page ? true : false}
//           className={"paginationItemStyle"}
//         >
//           <PaginationLink
//             className={"paginationLinkStyle"}
//             onClick={(atualizaPagina, pesquisarChavesBotao)}
//           >
//             {page + 1}
//           </PaginationLink>
//         </PaginationItem>
//       );
//     });
//   };

//   return (
//     <>
//       <ModalErro
//         isOpen={formularioErro}
//         toggle={() => fecharFormularioErro()}
//         erro={erro}
//       />
//       <div className="d-flex">
//         <div className="mr-auto p-2">
//           <h2 className="display-4 titulo">Chaves Geradas</h2>
//         </div>
//         <div>
//           <FormGroup>
//             <BotaoPesquisar
//               onClickPesquisar={() => {
//                 modificaPagina(0);
//                 props.limparChaveGeradas();
//                 pesquisarChavesBotao("");
//                 console.log(chavesGeradas);
//               }}
//             />
//           </FormGroup>
//         </div>
//       </div>
//       <hr />

//       <div className="form-group row">
//         <div className="col-sm-3">
//           <FormGroup>
//             <Label for="cliente">Cliente</Label>
//             <Input
//               type="select"
//               name="cliente"
//               id="cliente"
//               className="form-control"
//               onChange={(ev) => setIdCliente(ev.target.value)}
//             >
//               <option value="">{""}</option>
//               {clientes.map((cliente) => (
//                 <option value={cliente.id}>{cliente.nomeReduzido}</option>
//               ))}
//             </Input>
//           </FormGroup>
//         </div>
//         <div className="col-sm-3">
//           <FormGroup>
//             <Label for="mac">Mac</Label>
//             <Input
//               id="mac"
//               name="mac"
//               onChange={(ev) => setMac(ev.target.value)}
//               type="text"
//               value={mac}
//               autoComplete="mac"
//               placeholder="Filtrar por Mac"
//             />
//           </FormGroup>
//         </div>
//         <div className="col-sm-3">
//           <FormGroup>
//             <Label for="inicioLiberacao">Início</Label>
//             <Input
//               type="date"
//               name="inicioLiberacao"
//               id="inicioLiberacao"
//               placeholder="Data inicial"
//               value={inicioLiberacao}
//               onChange={(ev) => ajustaInicioLiberacao(ev.target.value)}
//             />
//           </FormGroup>
//         </div>
//         <div className="col-sm-3">
//           <FormGroup>
//             <Label for="dataFinal">Fim</Label>
//             <Input
//               type="date"
//               name="dataFinal"
//               id="dataFinal"
//               placeholder="Data final"
//               value={finalLiberacao}
//               onChange={(ev) => ajustaFinalLiberacao(ev.target.value)}
//             />
//           </FormGroup>
//         </div>
//       </div>

//       <Table>
//         <Thead className="table_head">
//           <Tr>
//             <Th className="text-center">Código</Th>
//             <Th className="text-center">Mac</Th>
//             <Th className="text-center">Cliente</Th>
//             <Th className="text-center">Início da Liberação</Th>
//             <Th className="text-center">Fim da Liberação</Th>
//             <Th className="text-center">Aplicação</Th>
//             <Th className="text-center">Ações</Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {chavesGeradas.map((chaveGerada) => {
//             let parity = qtde % 2;
//             qtde = qtde - 1;
//             return (
//               <Tr
//                 key={chaveGerada.id}
//                 className={parity === 1 ? "odd_row" : "even_row"}
//               >
//                 <Td className="text-center">{chaveGerada.id}</Td>
//                 <Td className="text-center">{chaveGerada.mac}</Td>
//                 <Td className="text-center">
//                   {chaveGerada.contrato.cliente.nomeReduzido}
//                 </Td>
//                 <Td className="text-center">
//                   {format(
//                     new Date(chaveGerada.inicioLiberacao),
//                     "dd-MM-yyyy HH:mm",
//                     { locale: pt }
//                   )}
//                 </Td>
//                 <Td className="text-center">
//                   {format(
//                     new Date(chaveGerada.finalLiberacao),
//                     "dd-MM-yyyy HH:mm",
//                     { locale: pt }
//                   )}
//                 </Td>
//                 <Td className="text-center">{chaveGerada.aplicacao.nome}</Td>
//                 <Td className="text-center">
//                   <span>
//                     <Link to={`/sac/chaves-geradas-info/${chaveGerada.id}`}>
//                       <BotaoInfo></BotaoInfo>
//                     </Link>
//                   </span>
//                 </Td>
//               </Tr>
//             );
//           })}
//         </Tbody>
//       </Table>

//       {props.chavesGeradasPesquisadas ? (
//         <div>
//           <Pagination className={css(styles.paginationContainer)}>
//             <PaginationItem className={"paginationItemStyle"}>
//               <PaginationLink
//                 first
//                 className={"paginationLinkStyle"}
//                 onClick={(modificaPagina(0), pesquisarChavesBotao)}
//               />
//             </PaginationItem>
//             <PaginationItem
//               className={"paginationItemStyle"}
//               disabled={page === 0 || page === "0" ? true : false}
//             >
//               <PaginationLink
//                 className={"paginationLinkStyle"}
//                 previous
//                 onClick={(modificaPagina(page - 1), pesquisarChavesBotao)}
//               />
//             </PaginationItem>
//             {paginationHandler()}
//             <PaginationItem
//               className={"paginationItemStyle"}
//               disabled={page !== numPages - 1 ? false : true}
//             >
//               <PaginationLink
//                 className={"paginationLinkStyle"}
//                 next
//                 onClick={(modificaPagina(page + 1), pesquisarChavesBotao)}
//               />
//             </PaginationItem>
//             <PaginationItem className={"paginationItemStyle"}>
//               <PaginationLink
//                 className={"paginationLinkStyle"}
//                 last
//                 onClick={(modificaPagina(numPages - 1), pesquisarChavesBotao)}
//               />
//             </PaginationItem>
//           </Pagination>
//         </div>
//       ) : (
//         false
//       )}
//     </>
//   );
// };

// const styles = StyleSheet.create({
//   paginationContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "row",
//   },
// });

// const mapStateToProps = (state) => ({
//   clientesPesquisados: state.cliente.clientesPesquisados,
//   chavesGeradasPesquisadas: state.chavesGeradas.chavesGeradasPesquisadas,
// });

// export default connect(mapStateToProps, actionsChavesGeradas)(ChavesGeradas);
