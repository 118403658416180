import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actionsUsuario from '../../../domain/actions/actionsUsuario';
import { FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import BotaoVoltar from '../../../components/BotaoVoltar';
import validator from 'validator';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalErro from '../../../components/ModalErro';
import ModalSucesso from '../../../components/ModalSucesso';

class UsuarioAlterar extends Component {

    state = {
        id: "",
        email: "",
        nome: "",
        erro: "",
        sucesso: "",
        atencao: "",
        aguardando: false,
        dadosUsuario: false,
        formularioPronto: false,
        formularioSucesso: false,
        formularioAtencao: false,
        formularioErro: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getUsuario(id);
    }

    async componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.usuario && nextProps.usuario) this.props.getUsuario(id);
        await this.receberDadosUsuario();
    }

    receberDadosUsuario() {
        const { id } = this.props.match.params;

        if (typeof this.props.usuario !== "undefined" && this.props.usuario !== null && this.props.usuario.id == id && !this.state.dadosUsuario) {
            this.setState({ id: this.props.usuario.id });
            this.setState({ nome: this.props.usuario.nome });
            this.setState({ email: this.props.usuario.email });
            this.setState({ dadosUsuario: true });
        }
    }

    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    async alterarUsuario() {
        this.setState({ erro: "" });

        await this.receberDadosFormulario();

        this.setState({ formularioAtencao: !this.criticas() });
        if (!this.criticas()) return;

        this.setState({ aguardando: true });

        const { id, nome, email } = this.state;

        this.props.alterarUsuario({ id, nome, email }, (err) => {
            if (err.erro.mensagem) {
                this.setState({ erro: { mensagem: err.erro.mensagem } });
                this.setState({ aguardando: false });
                this.setState({ formularioErro: true });
            } else {
                this.setState({ aguardando: false });
                this.setState({ sucesso: { mensagem: "Usuário alterado com sucesso!" } });
                this.setState({ formularioSucesso: true });
            }
        })
    }

    receberDadosFormulario() {
        this.setState({ id: document.querySelector("#id").value });
        this.setState({ nome: document.querySelector("#nome").value });
        this.setState({ email: document.querySelector("#email").value });
    }

    criticas() {
        const { nome, email } = this.state;
        if (!nome) return this.setState({ atencao: { mensagem: "Preencha o campo nome!" } });
        if (!email) return this.setState({ atencao: { mensagem: "Preencha o campo e-mail!" } });
        if (!validator.isEmail(email)) return this.setState({ atencao: { mensagem: "Preencha com e-mail válido!" } });
        return true;
    }

    fecharFormularioAtencao = () => {
        this.setState({ formularioAtencao: false });
    }

    fecharFormularioErro = () => {
        this.setState({ formularioErro: false });
    }

    fecharFormularioSucesso = () => {        
        this.setState({ formularioPronto: true });
    }

    render() {
        const { id, nome, email, aguardando, erro, sucesso, atencao, dadosUsuario, formularioPronto, formularioAtencao, formularioErro, formularioSucesso } = this.state;

        if (formularioPronto) {
            return <Redirect to={{ pathname: '/sac/usuarios' }} />
        }

        return (
            <>
                <div className="d-flex">
                    <div className="mr-auto p-2">
                        <h2 className="display-4 titulo">Usuário</h2>
                    </div>
                </div>
                <hr />
                <ModalErro isOpen={formularioErro} toggle={() => this.fecharFormularioErro()} erro={erro} />
                <ModalAtencao isOpen={formularioAtencao} toggle={() => this.fecharFormularioAtencao()} atencao={atencao} />
                <ModalSucesso isOpen={formularioSucesso} toggle={() => this.fecharFormularioSucesso()} sucesso={sucesso} />
                <div className="form-group row">
                    <div className="col-sm-2">
                        <FormGroup>
                            <Label for="id">ID</Label>
                            <Input
                                type="text"
                                value={id}
                                name="id"
                                id="id"
                                className="form-control"
                                disabled={true}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-5">
                        <FormGroup>
                            <Label for="nome">Nome</Label>
                            <Input
                                type="text"
                                value={nome}
                                name="nome"
                                id="nome"
                                className="form-control"
                                placeholder={dadosUsuario ? "Nome do usuário" : "Carregado..."}
                                disabled={dadosUsuario ? false : true}
                                autoComplete="nome"
                                onChange={(ev) => this.onChangeInput("nome", ev)}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-5">
                        <FormGroup>
                            <Label for="email">E-mail</Label>
                            <Input
                                type="email"
                                value={email}
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder={dadosUsuario ? "E-mail do usuário" : "Carregando.."}
                                disabled={dadosUsuario ? false : true}
                                autoComplete="email"
                                onChange={(ev) => this.onChangeInput("email", ev)}
                            />
                        </FormGroup>
                    </div>
                </div>

                <Link onClick={() => this.alterarUsuario()} to="#">
                    <BotaoConfirmar aguardando={aguardando} />
                </Link>
                <Link to={"/sac/usuarios"}>
                    <BotaoVoltar />
                </Link>
            </>
        )
    }
}

const mapStateToProps = state => ({
    usuario: state.usuario.usuario
})

export default connect(mapStateToProps, actionsUsuario)(UsuarioAlterar);