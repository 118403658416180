export const mask = (v) => {
  v = v.replace(/\D+/g, ""); // não deixa ser digitado nenhuma letra
  v = v.replace(/(\d{2})(\d)/, "$1.$2"); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1/$2"); // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
  v = v.replace(/(\d{4})(\d)/, "$1-$2");
  v = v.replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números

  return v;
};
