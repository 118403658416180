import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsUsuario from "../../../domain/actions/actionsUsuario";
import { FormGroup, Input, Label } from "reactstrap";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoAtivar from "../../../components/BotaoAtivar";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
import BotaoDesativar from "../../../components/BotaoDesativar";
import BotaoExcluir from "../../../components/BotaoExcluir";
import BotaoPesquisar from "../../../components/BotaoPesquisar";
import ModalApagar from "../../../components/ModalApagar";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalErro from "../../../components/ModalErro";
import ModalCarregando from "../../../components/ModalCarregando";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";

//style
import "../../../assets/styles/tabela.css";

class Listar extends Component {
  state = {
    nome: "",
    email: "",
    dataInicial: format(new Date(), "yyyy-MM-dd", { locale: pt }),
    dataFinal: format(new Date(), "yyyy-MM-dd", { locale: pt }),
    mensagem: "",
    erro: "",
    aguardando: false,
    confirmarExclusao: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioErro: null,
  };

  ajustaDataInicio = (dataOriginal) => {
    dataOriginal
      ? this.setState({
          dataInicial: format(new Date(dataOriginal), "yyyy-MM-dd", {
            locale: pt,
          }),
        })
      : this.setState({ dataInicial: "" });
  };

  ajustaDataFim = (dataOriginal) => {
    dataOriginal
      ? this.setState({
          dataFinal: format(new Date(dataOriginal), "yyyy-MM-dd", {
            locale: pt,
          }),
        })
      : this.setState({ dataFinal: "" });
  };

  //Início rotina de exclusão
  abrirConfirmarExclusao(id) {
    this.setState({ confirmarExclusao: true });
    this.setState({ idParaExcluir: id });
  }

  fecharConfirmarExclusao() {
    this.setState({ confirmarExclusao: false });
  }

  apagarUsuario() {
    this.setState({ aguardando: true });
    this.props.removerUsuario(this.state.idParaExcluir, (retorno) => {
      if (retorno.erro.erro) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ aguardando: false });
      } else {
        this.setState({ mensagem: retorno.erro.mensagem });
        this.setState({ aguardando: false });
        this.fecharConfirmarExclusao();
        this.props.limparUsuarios();
        this.pesquisarUsuarios();
      }
    });
  }
  //Fim rotina de exclusão

  //Início rotina de ativação
  ativarUsuario() {
    this.setState({ aguardando: true });
    this.props.ativarUsuario(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarAtivacao();
          this.props.limparUsuarios();
          this.pesquisarUsuarios();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }
  //Fim rotina de ativação

  //Início rotina de desativação
  desativarUsuario() {
    this.setState({ aguardando: true });
    this.props.desativarUsuario(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarUsuarios();
          this.props.limparUsuarios();
        }
      }
    );
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }
  //Fim rotina de desativação

  //Início da rotina de pesquisa
  pesquisarUsuarios() {
      const { nome, email } = this.state;
      this.props.pesquisarUsuarios(nome, email);
      if (this.props.location.state) {
          this.setState({ mensagem: this.props.location.state.mensagem });
      }
  }

//   pesquisarUsuarios() {
//     const { nome, email } = this.state;
//     this.props.pesquisarUsuarios((nome, email, retorno) => {
//       if (retorno.erro.mensagem) {
//         this.setState({ erro: retorno.erro.mensagem });
//         this.setState({ formularioErro: true });
//       } else if (this.props.location.state) {
//         this.setState({ mensagem: this.props.location.state.mensagem });
//       }
//     });
//   }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  //Fim da rotina de pesquisa

  componentDidMount() {
    this.pesquisarUsuarios();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.usuariosPesquisados && nextProps.usuariosPesquisados)
      this.pesquisarUsuarios();
  }

  componentWillUnmount() {
    this.props.limparUsuarios();
    this.props.limparUsuario();
  }

  render() {
    const {
      nome,
      email,
      mensagem,
      erro,
      aguardando,
      confirmarExclusao,
      confirmarAtivacao,
      confirmarDesativacao,
      formularioErro,
    } = this.state;

    var usuarios = [];
    if (this.props.usuariosPesquisados)
      usuarios = this.props.usuariosPesquisados;

    var qtde = usuarios.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        {/* Component modal apagar */}
        {/* <ModalApagar
          isOpen={confirmarExclusao}
          toggle={() => this.fecharConfirmarExclusao()}
          apagar="Usuário"
          aguardando={aguardando}
          apagarObjeto={() => this.apagarUsuario()}
        /> */}
        {/* Component modal ativar */}
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Usuário"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarUsuario()}
        />
        {/* Component modal desativar */}
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Usuário"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarUsuario()}
        />
        {/* <ModalErro
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        /> */}
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Usuários</h2>
          </div>
          <div>
            <FormGroup>
              <BotaoPesquisar
                onClickPesquisar={() => {
                  this.props.limparUsuarios();
                  this.pesquisarUsuarios();
                }}
              />
            </FormGroup>
          </div>
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/usuarios-cadastrar"
                descricaoObjeto="Usuario"
              />
            </FormGroup>
          </div>

          {erro && <AlertaErro erro={{ mensagem: erro }} />}
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-sm-4">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
                placeholder="Filtar pelo nome"
              />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="usuarioEmail">Email</Label>
              <Input
                id="email"
                name="email"
                onChange={(ev) => this.onChangeInput("email", ev)}
                type="email"
                value={email}
                autoComplete="email"
                placeholder="Filtrar por email"
              />
            </FormGroup>
          </div>
        </div>
        {!this.props.usuariosPesquisados && (
          <ModalCarregando isOpen={true} pagina="Listagem de Usuários" />
        )}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Nome</Th>
              <Th>E-mail</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {usuarios.map((usuario) => {
              var parity = qtde % 2;
              qtde = qtde - 1;
              return (
                <Tr
                  key={usuario.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{usuario.id}</Td>
                  <Td>
                    <Link to={`/sac/usuarios-alterar/${usuario.id}`}>
                      {usuario.nome}
                    </Link>
                  </Td>
                  <Td>{usuario.email}</Td>
                  <Td className="text-center">
                    <span>
                      {usuario.ativo ? (
                        <BotaoDesativar
                          onClick={() =>
                            this.abrirConfirmarDesativacao(usuario.id)
                          }
                        />
                      ) : (
                        <BotaoAtivar
                          onClick={() =>
                            this.abrirConfirmarAtivacao(usuario.id)
                          }
                        />
                      )}
                      {/* <BotaoExcluir onClick={() => this.abrirConfirmarExclusao(usuario.id)} /> */}
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //usuarios: state.usuario.usuarios,
  usuario: state.usuario.usuarioLogado,
  usuariosPesquisados: state.usuario.usuariosPesquisados,
});

export default connect(mapStateToProps, actionsUsuario)(Listar);
