import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import SyncIcon from "@mui/icons-material/Sync";

const BotaoAtualizar = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClickPesquisar}
        id="idAtualizar"
        className="btn-sm mt-2 botao-secundario"
      >
        <SyncIcon />
      </Button>

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="idAtualizar"
        toggle={toggle}
      >
        Atualizar
      </Tooltip>
    </>
  );
};

export default BotaoAtualizar;
