import { BUSCAR_CONTRATO, LIMPAR_CONTRATO, LIMPAR_CONTRATOS, LISTAR_CONTRATOS,PESQUISAR_CONTRATOS,PESQUISAR_TIPOS_CONTRATOS } from '../../core/store/types';

const reducersContrato = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_CONTRATO:
            return {
                ...state,
                contrato: action.payload,
            }
        case LIMPAR_CONTRATO:
            return {
                ...state,
                contrato: null,
            }
        case LIMPAR_CONTRATOS:
            return {
                ...state,
                contratos: null,
                contratosPesquisados: null
            }
        case LISTAR_CONTRATOS:
            return {
                ...state,
                contratos: action.payload._embedded.contratos,
            }
        case PESQUISAR_CONTRATOS:
            return {
                ...state,
                contratosPesquisados: action.payload,
            }
        case PESQUISAR_TIPOS_CONTRATOS:
            return{
                ...state,
                tiposContratoPesquisados: action.payload._embedded.tipoContrato,
            }
            
        default:
            return state;
    }
}
export default reducersContrato;