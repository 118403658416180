import axios from "axios";
import {
  BUSCAR_CONTRATO,
  LIMPAR_CONTRATO,
  LIMPAR_CONTRATOS,
  LISTAR_CONTRATOS,
  PESQUISAR_APLICACOES,
  PESQUISAR_CONTRATOS,
  PESQUISAR_TIPOS_CONTRATOS,
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const salvarContrato = (dadosContrato, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/contratos`, dadosContrato, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const listarContratos = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/contratos`, headers())
      .then((response) => {
        dispatch({ type: LISTAR_CONTRATOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

// export const pesquisarContratos = (dadosFiltro) => {
//     return (dispatch) => {
//         axios.get(`${api}/v1/contratos?clienteNome=${dadosFiltro.clienteNome}&emissao=${dadosFiltro.dataInicial+'T01:00:01Z'}`, headers())
//             .then((response) => {
//                 dispatch({ type: PESQUISAR_CONTRATOS, payload: response.data });
//             })
//             .catch(errorHandler);
//     }
// }

export const limparContrato = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CONTRATO });
  };
};

export const limparContratos = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CONTRATOS });
  };
};

export const removerContrato = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/contratos/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const getContrato = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/contratos/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_CONTRATO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarContrato = (dadosContrato, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/contratos/${dadosContrato.id}`,
        {
          copiasContratadas: dadosContrato.copiasContratadas,
          diasRenovacao: dadosContrato.diasRenovacao,
          aplicacao: { id: dadosContrato.aplicacao.id },
          tipoContrato: { id: dadosContrato.tipoContrato.id },
          cliente: { id: dadosContrato.cliente.id },
        },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarTiposContrato = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/tipos-contrato`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_TIPOS_CONTRATOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarContratos = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/contratos?clienteId=${dadosFiltro.clienteId}&aplicacaoId=${dadosFiltro.aplicacaoId}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CONTRATOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarContratosBotao = (dadosFiltro) => {
  return (dispatch) => {       
      axios.get(`${api}/v1/contratos?clienteId=${dadosFiltro.clienteId}&aplicacaoId=${dadosFiltro.aplicacaoId}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_CONTRATOS, payload: response.data });
          })
          .catch(errorHandler);
  }
}

export const pesquisarAplicacoes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/aplicacoes`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_APLICACOES, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const ativarContrato = (dadosContrato, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/contratos/${dadosContrato.id}/ativa`,
        { ativo: dadosContrato.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarContrato = (dadosContrato, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/contratos/${dadosContrato.id}/desativa`,
        { ativo: dadosContrato.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
