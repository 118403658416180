import axios from "axios";

import {
  BUSCAR_CHAVEGERADA,
  PESQUISAR_CHAVEGERADA,
  LIMPAR_CHAVEGERADA,
  LIMPAR_CHAVESGERADAS,
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const limparChaveGerada = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CHAVEGERADA });
  };
};

export const limparChavesGeradas = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CHAVESGERADAS });
  };
};

export const getChaveGerada = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/chaves-geradas/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_CHAVEGERADA, payload: response.data });
      })
      .catch(errorHandler);
  };
};

// export const pesquisarChaveGerada = (callback) => {
//   return (dispatch) => {
//     axios
//       .get(`${api}/v1/chaves-geradas`, headers())
//       .then((response) => {
//         dispatch({ type: PESQUISAR_CHAVEGERADA, payload: response.data });
//       })
//       .catch((err) => callback(errorHandler(err)));
//   };
// };

export const pesquisarChaveGerada = (dadosFiltro) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/chaves-geradas?mac=${dadosFiltro.mac}&clienteId=${dadosFiltro.clienteId}&inicioLiberacao=${dadosFiltro.inicioLiberacao}&finalLiberacao=${dadosFiltro.finalLiberacao}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CHAVEGERADA, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarChavesBotao = (dadosFiltro) => {
  return (dispatch) => {
      {console.log(dadosFiltro)}   
      axios.get(`${api}/v1/chaves-geradas?mac=${dadosFiltro.mac}&clienteId=${dadosFiltro.clienteId}&inicioLiberacao=${dadosFiltro.inicioLiberacao}&finalLiberacao=${dadosFiltro.finalLiberacao}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_CHAVEGERADA, payload: response.data });
          })
          .catch(errorHandler);
  }
}