import React from 'react';
import '../../../assets/styles/painelDeControle/index.css';
import { connect } from 'react-redux';
import * as actionsUsuario from '../../../domain/actions/actionsUsuario';
import MiniDrawer from '../../../components/MainLayout';

class ContainerPainelDeControle extends React.Component {
  render() {
    return (
      <>
        <MiniDrawer
          onLogout={this.props.handleLogout}
          dadosUsuario={this.props}
        >
          {this.props.children}
        </MiniDrawer>
      </>
    );
  }
}

export default connect(null, actionsUsuario)(ContainerPainelDeControle);
