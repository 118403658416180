import { BUSCAR_APLICACAO, LIMPAR_APLICACAO, LIMPAR_APLICACOES, LISTAR_APLICACOES, PESQUISAR_APLICACOES } from '../../core/store/types';

const reducersAplicacao = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_APLICACAO:
            return {
                ...state,
                aplicacao: action.payload,
            }
        case LIMPAR_APLICACAO:
            return {
                ...state,
                aplicacao: null,
            }
        case LIMPAR_APLICACOES:
            return {
                ...state,
                aplicacoes: null,
                aplicacoesPesquisados: null
            }
        case LISTAR_APLICACOES:
            return {
                ...state,
                clientes: action.payload._embedded.aplicacoes,
            }
        case PESQUISAR_APLICACOES:
            return {
                ...state,
                aplicacoesPesquisados: action.payload._embedded.aplicacoes,
            }
        default:
            return state;
    }
}
export default reducersAplicacao;