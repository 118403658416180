import axios from "axios";
// import { PESQUISAR_CODIGO_LIBERACAO } from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const salvarLiberacao = (dadosLiberacao, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/chaves-geradas`, dadosLiberacao, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarChaveLiberacao = (dadosLiberacao, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/chaves-geradas`, dadosLiberacao, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
