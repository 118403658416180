import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import * as actionsAtendimento from "../../../domain/actions/actionsAtendimento";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

class AtendimentoAlterar extends Component {
  state = {
    id: "",
    solicitante: "",
    problema: "",
    solucao: "",
    idSituacao: "",
    idCliente: "",
    idNatureza: "",
    idUsuario: "",
    erro: "",
    sucesso: "",
    atencao: "",
    aguardando: false,
    redirect: "#",
    dadosAtendimento: false,
    formularioPronto: false,
    formularioSucesso: false,
    formularioAtencao: false,
    formularioErro: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAtendimento(id);

    this.props.pesquisarUsuarios("", "");
    this.props.pesquisarNaturezas();
    this.props.pesquisarClientes();
    this.props.pesquisarStatusAtendimento();
  }

  async componentDidUpdate(nextProps) {
    const { id } = this.props.match.params;
    if (!this.props.atendimento && nextProps.atendimento)
      this.props.getAtendimento(id);
    await this.receberDadosAtendimento();
  }

  receberDadosAtendimento() {
    const { id } = this.props.match.params;

    if (
      typeof this.props.atendimento !== "undefined" &&
      this.props.atendimento !== null &&
      this.props.atendimento.id == id &&
      !this.state.dadosAtendimento
    ) {
      this.setState({ id: this.props.atendimento.id });
      this.setState({ solicitante: this.props.atendimento.solicitante });
      this.setState({ problema: this.props.atendimento.problema });
      this.setState({ solucao: this.props.atendimento.solucao });
      this.setState({ idSituacao: this.props.atendimento.status.id });
      this.setState({ idCliente: this.props.atendimento.cliente.id });
      this.setState({ idNatureza: this.props.atendimento.natureza.id });
      this.setState({ idUsuario: this.props.atendimento.usuario.id });
      this.setState({ dadosAtendimento: true });
    }
  }

  pesquisarAtendimentos() {
    const { clienteNome, solicitante, dataInicial, dataFinal } = this.state;

    if (this.props.location.state) {
      this.props.pesquisarAtendimentos({
        clienteNome,
        solicitante,
        dataInicial,
        dataFinal,
        id: this.props.location.state.statusId,
      });
      this.setState({ mensagem: this.props.location.state.mensagem });
    } else {
      this.props.pesquisarAtendimentos(4);
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  async alterarAtendimento() {
    this.setState({ erro: "" });

    await this.receberDadosFormulario();

    this.setState({ formularioAtencao: !this.criticas() });
    if (!this.criticas()) return;

    this.setState({ aguardando: true });

    const {
      id,
      solicitante,
      problema,
      solucao,
      idSituacao,
      idCliente,
      idNatureza,
      idUsuario,
    } = this.state;

    this.props.alterarAtendimento(
      {
        id,
        solicitante,
        problema,
        solucao,
        status: { id: idSituacao },
        cliente: { id: idCliente },
        natureza: { id: idNatureza },
        usuario: { id: idUsuario },
      },
      (retorno) => {
        if (retorno.erro.mensagem) {
          this.setState({ erro: { mensagem: retorno.erro.mensagem } });
          this.setState({ aguardando: false });
          this.setState({ formularioErro: true });
        } else {
          this.setState({ aguardando: false });
          this.setState({
            sucesso: { mensagem: "Atendimento alterado com sucesso!" },
          });
          this.setState({ formularioSucesso: true });
        }
      }
    );
  }

  receberDadosFormulario() {
    this.setState({ id: document.querySelector("#id").value });
    this.setState({
      solicitante: document.querySelector("#solicitante").value,
    });
    this.setState({ problema: document.querySelector("#problema").value });
    this.setState({ solucao: document.querySelector("#solucao").value });
    this.setState({ idSituacao: document.querySelector("#situacao").value });
    this.setState({ idCliente: document.querySelector("#cliente").value });
    this.setState({ idNatureza: document.querySelector("#natureza").value });
    this.setState({ idUsuario: document.querySelector("#tecnico").value });
  }

  criticas() {
    const { idCliente, solicitante } = this.state;

    if (idCliente === "0" || idCliente === "")
      return this.setState({
        atencao: { mensagem: "O cliente deve ser escolhido." },
      });
    if (!solicitante)
      return this.setState({
        atencao: { mensagem: "Preencha o campo solicitante!" },
      });

    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  fecharFormularioSucesso = () => {
    this.setState({ formularioPronto: true });
  };

  render() {
    const {
      id,
      solicitante,
      problema,
      solucao,
      idSituacao,
      idCliente,
      idNatureza,
      idUsuario,
      aguardando,
      erro,
      sucesso,
      atencao,
      redirect,
      dadosAtendimento,
      formularioPronto,
      formularioAtencao,
      formularioErro,
      formularioSucesso,
    } = this.state;

    window.onpopstate = () => {
      this.setState({ formularioPronto: true });
    };

    if (formularioPronto) {
      return (
        <Redirect
          to={{
            pathname: "/sac/atendimentos",
            state: { statusId: idSituacao },
          }}
        />
      );
    }

    var usuarios = [];
    if (this.props.usuariosPesquisados)
      usuarios = this.props.usuariosPesquisados;

    var naturezas = [];
    if (this.props.naturezasPesquisados)
      naturezas = this.props.naturezasPesquisados;

    var clientes = [];
    if (this.props.clientesPesquisados)
      clientes = this.props.clientesPesquisados;

    var statusAtendimento = [];
    if (this.props.statusAtendimentoPesquisados)
      statusAtendimento = this.props.statusAtendimentoPesquisados;

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Atendimento</h2>
          </div>
        </div>
        <hr />
        <ModalErro
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
          erro={erro}
        />
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        />
        <ModalSucesso
          isOpen={formularioSucesso}
          toggle={() => this.fecharFormularioSucesso()}
          sucesso={sucesso}
        />
        <div className="form-group row">
          <div className="col-sm-2">
            <FormGroup>
              <Label for="id">ID</Label>
              <Input type="text" value={id} name="id" id="id" disabled={true} />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="cliente">Cliente</Label>
              <Input
                value={idCliente}
                type="select"
                name="cliente"
                id="cliente"
                className="form-control"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                onChange={(ev) => this.onChangeInput("idCliente", ev)}
              >
                <option value="0">{""}</option>
                {clientes.map((cliente) => (
                  <option value={cliente.id}>{cliente.nomeReduzido}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="natureza">Natureza</Label>
              <Input
                value={idNatureza}
                type="select"
                name="natureza"
                id="natureza"
                className="form-control"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                onChange={(ev) => this.onChangeInput("idNatureza", ev)}
              >
                {naturezas.map((natureza) => (
                  <option value={natureza.id}>{natureza.nome}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="tecnico">Técnico</Label>
              <Input
                value={idUsuario}
                type="select"
                name="tecnico"
                id="tecnico"
                className="form-control"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                onChange={(ev) => this.onChangeInput("idUsuario", ev)}
              >
                {usuarios.map((usuario) => (
                  <option value={usuario.id}>{usuario.nome}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-4">
            <FormGroup>
              <Label for="solicitante">Solicitante</Label>
              <Input
                type="text"
                value={solicitante}
                name="solicitante"
                id="solicitante"
                className="form-control"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                autoComplete="solicitante"
                onChange={(ev) => this.onChangeInput("solicitante", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="situacao">Situacao</Label>
              <Input
                value={idSituacao}
                onChange={(ev) => this.onChangeInput("idSituacao", ev)}
                type="select"
                name="situacao"
                id="situacao"
                className="form-control"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
              >
                {statusAtendimento.map((status) => (
                  <option value={status.id}>{status.descricao}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-6">
            <FormGroup>
              <Label for="problema">Problema</Label>
              <Input
                className="textarea"
                type="textarea"
                value={problema}
                onChange={(ev) => this.onChangeInput("problema", ev)}
                name="problema"
                id="problema"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                autoComplete="problema"
              />
            </FormGroup>
          </div>
          <div className="col-sm-6">
            <FormGroup>
              <Label for="solucao">Solução</Label>
              <Input
                className="textarea"
                type="textarea"
                value={solucao}
                onChange={(ev) => this.onChangeInput("solucao", ev)}
                name="solucao"
                id="solucao"
                placeholder={dadosAtendimento ? "" : "Carregado..."}
                disabled={dadosAtendimento ? false : true}
                autoComplete="solucao"
              />
            </FormGroup>
          </div>
        </div>

        <br />
        <Link onClick={() => this.alterarAtendimento()} to={redirect}>
          <BotaoConfirmar aguardando={aguardando} />
        </Link>
        <Link
          to={{
            pathname: "/sac/atendimentos",
            state: { statusId: idSituacao },
          }}
          style={{ textDecoration: "none" }}
        >
          <BotaoVoltar></BotaoVoltar>
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  atendimento: state.atendimento.atendimento,
  usuariosPesquisados: state.usuario.usuariosPesquisados,
  naturezasPesquisados: state.natureza.naturezasPesquisados,
  clientesPesquisados: state.cliente.clientesPesquisados,
  statusAtendimentoPesquisados:
    state.statusAtendimento.statusAtendimentoPesquisados,
});

export default connect(mapStateToProps, actionsAtendimento)(AtendimentoAlterar);
