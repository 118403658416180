import { useEffect } from "react";

export const EnterClick = (callback, targetKey) => {
  useEffect(() => {
    function keyPressHandler(event) {
      if (event.key === targetKey) {
        callback();
      }
    }
    window.addEventListener("keydown", keyPressHandler);
    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, [callback, targetKey]);
}
