import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import * as actionsContrato from '../../../domain/actions/actionsContrato';
import { FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import BotaoVoltar from '../../../components/BotaoVoltar';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalErro from '../../../components/ModalErro';
import ModalSucesso from '../../../components/ModalSucesso';

const ContratoCadastrar = (props) => {
    //const { usuario } = useSelector((state) => state);   

    const [idAplicacao, setIdAplicacao] = useState('');
    const [idCliente, setIdCliente] = useState("");
    const [idTipoContrato, setIdTipoContrato] = useState('');
    const [copiasContratadas, setCopiasContratadas] = useState("");
    const [diasRenovacao, setDiasRenovacao] = useState("");
    const [erro, setErro] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [atencao, setAtencao] = useState("");
    const [aguardando, setAguardando] = useState(false);
    const [formularioPronto, setFormularioPronto] = useState(false);

    const [formularioSucesso, setFormularioSucesso] = useState(false);
    const [formularioAtencao, setFormularioAtencao] = useState(null);
    const [formularioErro, setFormularioErro] = useState(null);

    // //Ao montar/entrar componente
    useEffect(() => {
        pesquisarUsuarios();
        pesquisarClientes();
        pesquisarAplicacoes();
        pesquisarTiposContrato();
        pesquisarAplicacaoPadrao();
        pesquisarTiposContratoPadrao();
    }, []);

    //Ao desmontar/sair componente
    useEffect(() => {
        return props.limparUsuario();
    }, []);

    const pesquisarUsuarios = () => {
        props.pesquisarUsuarios("", "");
    }

    const pesquisarAplicacoes = () => {
        props.pesquisarAplicacoes();
    }

    const pesquisarClientes = () => {
        props.pesquisarClientes();
    }

    const pesquisarTiposContrato = () =>{
        props.pesquisarTiposContrato();
    }

    const pesquisarAplicacaoPadrao = () => {
        var aplicacoesEncontradas = [];
        if (props.aplicacoesPesquisados) aplicacoesEncontradas = props.aplicacoesPesquisados;

        aplicacoesEncontradas.map(
            (aplicacao) => (
                aplicacao.padrao ? setIdAplicacao(aplicacao.id) : '0'
            )
        )
    }

    const pesquisarTiposContratoPadrao = () => {
        var tiposContratosEncontrados = [];
        if (props.tiposContratoPesquisados) tiposContratosEncontrados = props.tiposContratoPesquisados;

        tiposContratosEncontrados.map(
            (tipoContrato) => (
                tipoContrato.padrao ? setIdTipoContrato(tipoContrato.id) : '0'
            )
        )
    }    

    const fecharFormularioAtencao = () => {
        setFormularioAtencao(false);
    }

    const fecharFormularioErro = () => {
        setFormularioErro(false);
    }

    const fecharFormularioSucesso = () => {
        setFormularioPronto(true);
    }

    const cadastrarContrato = () => {
        setFormularioAtencao(!criticas());
        if (!criticas()) return;

        setAguardando(true);
        props.salvarContrato(
            { 
                copiasContratadas, 
                diasRenovacao, 
                aplicacao: { id: idAplicacao }, 
                cliente: { id: idCliente }, 
                tipoContrato: { id: idTipoContrato } 
            },
            (retorno) => {
            if (retorno.erro.mensagem) {
                setAtencao("");
                setSucesso("");
                setErro({ mensagem: retorno.erro.mensagem });
                setAguardando(false);
                setFormularioErro(true);
            } else {
                setAtencao("");
                setErro("");
                setSucesso({ mensagem: "Contrato inserido com sucesso!" });
                setFormularioSucesso(true);
                setAguardando(false);
            }
        })
    }

    const criticas = () => {
        if (idCliente === "0" || idCliente === "") return setAtencao({ mensagem: "O cliente deve ser escolhido." });
        if (idAplicacao === "0" || idAplicacao === "") return setAtencao({ mensagem: "A aplicação deve ser escolhida." });
        if (idTipoContrato === "0" || idTipoContrato === "") return setAtencao({ mensagem: "O tipo de contrato deve ser escolhido." });
        if (!diasRenovacao) return setAtencao({ mensagem: "Preencha o campo com dias para renovação!" });
        if (!copiasContratadas) return setAtencao({ mensagem: "Preencha o campo com a quantidade de copias contratadas!" });

        return true;
    }

    if (formularioPronto) {
        return <Redirect to={{
            pathname: '/sac/contratos'
        }} />
    }

    var aplicacoes = [];
    if (props.aplicacoesPesquisados) aplicacoes = props.aplicacoesPesquisados;
    

    var clientes = [];
    if (props.clientesPesquisados) clientes = props.clientesPesquisados;

    var tiposContrato = []
    if(props.tiposContratoPesquisados) tiposContrato = props.tiposContratoPesquisados


    return (
        <>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="display-4 titulo">Cadastrar Contrato</h2>
                </div>
            </div>
            <hr />
            {/* Component modal apagar */}
            <ModalErro isOpen={formularioErro} toggle={() => fecharFormularioErro()} erro={erro} />
            <ModalAtencao isOpen={formularioAtencao} toggle={() => fecharFormularioAtencao()} atencao={atencao} />
            <ModalSucesso isOpen={formularioSucesso} toggle={() => fecharFormularioSucesso()} sucesso={sucesso} />
            <div className="form-group row">
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="cliente">Cliente</Label>
                        <Input
                            onChange={(ev) => setIdCliente(ev.target.value)}
                            type="select"
                            name="cliente"
                            id="cliente"
                        >
                            <option value="0">{""}</option>
                            {
                                clientes.map(
                                    (cliente) => (
                                        <option value={cliente.id}>{cliente.nomeReduzido}</option>
                                    )
                                )
                            }
                        </Input>
                    </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="aplicacao">Aplicação</Label>
                        <Input
                            value={idAplicacao}
                            onChange={(ev) => setIdAplicacao(ev.target.value)}
                            type="select"
                            name="aplicacao"
                            id="aplicacao"
                        >
                            <option value="0">{""}</option>
                            {
                                aplicacoes.map(
                                    (aplicacao) => {
                                            return<option value={aplicacao.id}>{aplicacao.nome}</option>
                                    }
                                )
                            }
                        </Input>
                    </FormGroup>
                </div>
                {/* <div className="col-sm-4">
                    <FormGroup>
                        <Label for="tecnico">Técnico</Label>
                        <Input
                            value={idUsuario}
                            onChange={(ev) => setIdUsuario(ev.target.value)}
                            type="select"
                            name="tecnico"
                            id="tecnico"
                        >
                            {
                                usuarios.map(
                                    (usuario) => {
                                        if (usuario.ativo == true) {
                                           return <option value={usuario.id}>{usuario.nome}</option>
                                        }
                                    }
                                    
                                )
                            }
                        </Input>
                    </FormGroup>
                </div> */}
            </div>

            <div className="form-group row">
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="solicitante">Cópias Contratadas</Label>
                        <Input
                            type="number"
                            value={copiasContratadas}
                            name="copiasContratadas"
                            id="copiasContratadas"
                            onChange={(ev) => setCopiasContratadas(ev.target.value)} />
                    </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="diasRenovacao">Dias para renovação</Label>
                        <Input
                            value={diasRenovacao}
                            onChange={(ev) => setDiasRenovacao(ev.target.value)}
                            type="number"
                            name="diasRenovacao"
                            id="diasRenovacao"
                        >
                        </Input>
                    </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="tiposContrato">Tipo de contrato</Label>
                        <Input
                            value={idTipoContrato}
                            onChange={(ev) => setIdTipoContrato(ev.target.value)}
                            type="select"
                            name="tiposContrato"
                            id="tiposContrato"
                        >
                            <option value="0">{""}</option>
                            {
                                tiposContrato.map(
                                    (tipoContrato) => {
                                           return <option value={tipoContrato.id}>{tipoContrato.descricao}</option>
                                    }
                                    
                                )
                            }
                        </Input>
                    </FormGroup>
                </div>
            </div>

            {/* <div className="form-group row">
                <div className="col-sm-6">
                    <FormGroup>
                        <Label for="problema">Problema</Label>
                        <Input
                            className="textarea"
                            type="textarea"
                            name="problema"
                            id="problema"
                            onChange={(ev) => setProblema(ev.target.value)} />
                    </FormGroup>
                </div>
                <div className="col-sm-6">
                    <FormGroup>
                        <Label for="solucao">Solução</Label>
                        <Input
                            className="textarea"
                            type="textarea"
                            name="solucao"
                            id="solucao"
                            onChange={(ev) => setSolucao(ev.target.value)} />
                    </FormGroup>
                </div>
            </div> */}

            <Link onClick={() => cadastrarContrato()} to="#">
                <BotaoConfirmar aguardando={aguardando} />
            </Link>
            <Link to={"painel-de-controle"}>
                <BotaoVoltar to="painel-de-controle" />
            </Link>
        </>
    )
}

const mapStateToProps = state => ({
    tiposContratoPesquisados: state.contrato.tiposContratoPesquisados,
    aplicacoesPesquisados: state.aplicacao.aplicacoesPesquisados,
    clientesPesquisados: state.cliente.clientesPesquisados,
})

export default connect(mapStateToProps, actionsContrato)(ContratoCadastrar);