import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { mask } from "./mask";

// import validator from "validator";
import { connect } from "react-redux";
import * as actionsClientes from "../../../domain/actions/actionsClientes";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

// function onlyNumbers(value) {
//   return value.replace(/\D/g, "");
// }

const ClienteCadastrar = (props) => {
  const [nome, setNome] = useState("");
  const [nomeReduzido, setNomeReduzido] = useState("");
  const [codigo, setCodigo] = useState("");
  const [email, setEmail] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);
  const [formularioPronto, setFormularioPronto] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  const cadastrarClientes = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;
    setAguardando(true);
    // const codigo = "";
    // if (cnpj) {
    //   const codigo = onlyNumbers(cnpj);
    //   console.log(codigo);
    // }
    props.salvarCliente({ nome, nomeReduzido, codigo, email }, (retorno) => {
      if (retorno.erro.mensagem) {
        setAtencao("");
        setSucesso("");
        setErro({ mensagem: retorno.erro.mensagem });
        setAguardando(false);
        setFormularioErro(true);
      } else {
        setAtencao("");
        setErro("");
        setSucesso({ mensagem: "Aplicação cadastrada com sucesso!" });
        setFormularioSucesso(true);
        setAguardando(false);
      }
    });
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioPronto(true);
  };

  if (formularioPronto) {
    return <Redirect to={{ pathname: "/sac/clientes" }} />;
  }

  const criticas = () => {
    if (!nome) return setAtencao({ mensagem: "Preencha o campo nome!" });
    if (!nomeReduzido)
      return setAtencao({ mensagem: "Preencha o campo nome reduzido!" });
    if (!codigo) return setAtencao({ mensagem: "Preencha o campo CNPJ!" });
    if (!email) return setAtencao({ mensagem: "Preencha o campo e-mail!" });
    return true;
  };

  function handleChangeMask(event) {
    const { value } = event.target;
    // event.target.value = onlyNumbers(value);

    setCodigo(mask(value));
  }

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Cadastrar Cliente</h2>
        </div>
      </div>
      <hr />
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="codigo">CNPJ</Label>
            <Input
              // maxLength={14}
              value={codigo}
              name="codigo"
              id="codigo"
              placeholder="00.000.000/0000-00"
              autoComplete="codigo"
              onChange={handleChangeMask}
              keyboardType="numeric"
            />
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="name">Nome</Label>
            <Input
              type="text"
              value={nome}
              name="nome"
              id="nome"
              placeholder="Nome do Cliente"
              autoComplete="nome"
              onChange={(ev) => setNome(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="nomeReduzido">Nome Reduzido</Label>
            <Input
              type="text"
              value={nomeReduzido}
              name="nomeReduzido"
              id="nomeReduzido"
              placeholder="Nome Reduzido"
              autoComplete="nomeReduzido"
              onChange={(ev) => setNomeReduzido(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="email">E-mail</Label>
            <Input
              type="text"
              value={email}
              name="email"
              id="email"
              placeholder="E-mail"
              autoComplete="email"
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <Link onClick={() => cadastrarClientes()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"/sac/clientes"}>
        <BotaoVoltar />
      </Link>
    </>
  );
};
export default connect(null, actionsClientes)(ClienteCadastrar);
