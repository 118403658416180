import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsContrato from "../../../domain/actions/actionsContrato";
import { FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem } from "reactstrap";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";

// import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoPesquisar from '../../../components/BotaoPesquisar';
import BotaoAtivar from "../../../components/BotaoAtivar";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
import BotaoDesativar from "../../../components/BotaoDesativar";
// import BotaoExcluir from "../../../components/BotaoExcluir";
// import ModalApagar from "../../../components/ModalApagar";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalErroRetorna from "../../../components/ModalErroRetorna";
import ModalCarregando from "../../../components/ModalCarregando";

import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from 'aphrodite'

//style
import '../../../assets/styles/listarPagination.scss'
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

class ListarContratos extends Component {
  state = {
    clienteNome: "",
    aplicacao: "",
    aplicacaoId: "",
    clienteId: "",
    mensagem: "",
    erro: "",
    aguardando: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    avisoErro: false,
    idParaDesativar: null,
    formularioErro: null,
    page: 0,
    numPages: 0
  };

  //Inicio da rotina de ativação
  ativarContrato() {
    this.setState({ aguardando: true });
    this.props.ativarContrato(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarAtivacao();
          this.props.limparContratos();
          this.pesquisarContratos();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }
  //Fim rotina de ativação

  //Inicio rotina de desativação

  desativarContrato() {
    this.setState({ aguardando: true });
    this.props.desativarContrato(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarContratos();
          this.props.limparContratos();
        }
      }
    );
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }
  //Fim rotina de desativação

  //Inicio da rotina de pesquisa
  pesquisarContratos() {
    const { clienteId, aplicacaoId, page } = this.state;

    if (this.props.location.state) {
      this.props.pesquisarContratos({ clienteId, aplicacaoId, page });
    }
    else {
      this.props.pesquisarContratos({ clienteId, aplicacaoId });
    }
  }

  pesquisarContratosBotao() {
    const { clienteId, aplicacaoId, page } = this.state;
    this.props.pesquisarContratosBotao({ clienteId, aplicacaoId, page });
  } 
  
  paginationHandler = () => {
    var pages = 1
    if (this.props.contratosPesquisados) pages = this.props.contratosPesquisados.page.totalPages
    var curPages = []
    for (let i = 0; i < pages; i++) {
      curPages.push(i)
    }
    return curPages.map((page) => {
      return (
        <PaginationItem
          active={this.state.page === page ? true : false}
          className={'paginationItemStyle'}
        >
          <PaginationLink
            className={'paginationLinkStyle'}
            onClick={() => this.setState({ page: page }, this.pesquisarContratosBotao)}
          >
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      )
    })

  } 
  
  criticas() {
    const { dataInicial, dataFinal } = this.state;

    return true;
  }  

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro () {
    this.setState({ formularioErro: false });
  };

  //Fim da rotina de pesquisa

  abreErro() {
    this.setState({ avisoErro: true });
  }

  fecharErro() {
    this.setState({ avisoErro: false });
  }

  componentDidMount() {
    this.pesquisarContratos();
    this.props.pesquisarClientes();
    this.props.pesquisarAplicacoes();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.contratosPesquisados && nextProps.contratosPesquisados) {
      this.pesquisarContratosBotao();
    }
  }

  componentWillUnmount() {
    actionsContrato.limparContrato();
    actionsContrato.limparContratos();
  }

  render() {
    const {
      mensagem,
      erro,
      aguardando,
      confirmarExclusao,
      confirmarExclusaoErro,
      confirmarAtivacao,
      confirmarDesativacao,
      avisoErro,
      formularioErro,
      clienteId,
      aplicacaoId
    } = this.state;

    var numPages = 1
    if (this.props.contratosPesquisados && this.props.contratosPesquisados.page.totalPages) numPages = this.props.contratosPesquisados.page.totalPages

    var clientes = [];
    if (this.props.clientesPesquisados)
      clientes = this.props.clientesPesquisados;

    var aplicacoes = [];
    if (this.props.aplicacoesPesquisados) {
      aplicacoes = this.props.aplicacoesPesquisados;
    }      

    var contratos = [];
    if (this.props.contratosPesquisados && this.props.contratosPesquisados._embedded) {
      contratos = this.props.contratosPesquisados._embedded.contratos
    };

    var qtde = contratos.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Contrato"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarContrato()}
        />
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Contrato"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarContrato()}
        />
        <ModalErroRetorna
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        />
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Contratos</h2>
          </div>
          <div >
            <FormGroup>
              <BotaoPesquisar onClickPesquisar={() => {
                this.setState({ formularioAtencao: !this.criticas() });

                {
                  if (this.criticas()) {
                    this.setState({ page: 0 });
                    this.props.limparContratos();
                    this.pesquisarContratosBotao('');
                  }
                }
              }} />
            </FormGroup>
          </div>
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/contratos-cadastrar"
                descricaoObjeto="Contrato"
              />
            </FormGroup>
          </div>
          {/* {erro && <ModalErro erro={{ mensagem: erro }} />} */}
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-sm-4">
            <FormGroup>
              <Label for="cliente">Cliente</Label>
              <Input
                type="select"
                name="cliente"
                id="cliente"
                className="form-control"
                onChange={(ev) => this.onChangeInput("clienteId", ev)}
              >
                <option value="">{""}</option>
                {clientes.map((cliente) => (
                  <option value={cliente.id}>{cliente.nomeReduzido}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="aplicacao">Aplicacao</Label>
              <Input
                type="select"
                name="aplicacao"
                id="aplicacao"
                className="form-control"
                onChange={(ev) => this.onChangeInput("aplicacaoId", ev)}
              >
                <option value="">{""}</option>
                {aplicacoes.map((aplicacao) => (
                  <option value={aplicacao.id}>{aplicacao.nome}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
        </div>        
        {!this.props.contratosPesquisados && (
          <ModalCarregando
            isOpen={true}
            pagina="Listagem de Contratos"
          ></ModalCarregando>
        )}
        {/* {!this.props.contratosPesquisados && (
          <ModalErro isOpen={avisoErro} erro={{ mensagem: erro }} toggle={() => this.fecharErro()}></ModalErro>
        )} */}
        <Table>
          <Thead className="table_head">
            <Tr className="tabela_td">
              <Th className="text-center">ID</Th>
              <Th>Cliente</Th>
              <Th>Aplicação</Th>
              <Th className="text-center">Copias Contratadas</Th>
              <Th className="text-center">Dt. Emissão</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {contratos.map((contrato) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={contrato.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{contrato.id}</Td>
                  <Td>
                    <Link to={`/sac/contratos-alterar/${contrato.id}`}>
                      {contrato.cliente.nome}
                    </Link>
                  </Td>
                  <Td>{contrato.aplicacao.nome}</Td>
                  <Td className="text-center">{contrato.copiasContratadas}</Td>
                  <Td className="text-center">
                    {format(new Date(contrato.emissao), "dd-MM-yyyy HH:mm", {
                      locale: pt,
                    })}
                  </Td>
                  <Td className="text-center">
                    <span>
                      {contrato.ativo ? (
                        <BotaoDesativar
                          onClick={() =>
                            this.abrirConfirmarDesativacao(contrato.id)
                          }
                        />
                      ) : (
                        <BotaoAtivar
                          onClick={() =>
                            this.abrirConfirmarAtivacao(contrato.id)
                          }
                        />
                      )}
                      {/* <BotaoExcluir onClick={() => this.abrirConfirmarExclusao(contrato.id)} /> */}
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <br />
        {this.props.contratosPesquisados ?
          <div>
            <Pagination
              className={css(styles.paginationContainer)}
            >
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  first
                  className={'paginationLinkStyle'}
                  onClick={() => {
                    this.setState({ page: 0 }, this.pesquisarContratosBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page === 0 || this.state.page === '0' ? true : false}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  previous
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 }, this.pesquisarContratosBotao)
                  }}
                />
              </PaginationItem>
              {
                this.paginationHandler()
              }
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page !== numPages - 1 ? false : true}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  next
                  onClick={() => {
                    this.setState({ page: this.state.page + 1 }, this.pesquisarContratosBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  last
                  onClick={() => {
                    this.setState({ page: numPages - 1 }, this.pesquisarContratosBotao)
                  }}
                />
              </PaginationItem>
            </Pagination>
          </div>
          : false
        }        
      </>
    );
  }
}

const styles = StyleSheet.create({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  }
})

const mapStateToProps = (state) => ({
  clientesPesquisados: state.cliente.clientesPesquisados,
  aplicacoesPesquisados: state.aplicacao.aplicacoesPesquisados,  
  contratosPesquisados: state.contrato.contratosPesquisados,
});

export default connect(mapStateToProps, actionsContrato)(ListarContratos);
