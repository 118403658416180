import axios from "axios";
import { Base64 } from "js-base64";
import {
  BUSCAR_USUARIO,
  LIMPAR_USUARIO,
  LIMPAR_USUARIOS,
  LISTAR_USUARIOS,
  LOGIN_USUARIO,
  LOGOUT_USUARIO,
  PESQUISAR_USUARIOS,
  BUSCAR_NATUREZA,
  LIMPAR_NATUREZA,
  LIMPAR_NATUREZAS,
  // LISTAR_NATUREZAS,
  PESQUISAR_NATUREZAS,

  // BUSCAR_CLIENTE,
  LIMPAR_CLIENTE,
  LIMPAR_CLIENTES,
  // LISTAR_CLIENTES,
  PESQUISAR_CLIENTES,
  PESQUISAR_STATUSATENDIMENTO,
  LIMPAR_STATUSATENDIMENTO,
  PESQUISAR_ATENDIMENTOS_POR_STATUS,
  PESQUISAR_ATENDIMENTOS,
  BUSCAR_APLICACAO,
  LIMPAR_APLICACAO,
  LIMPAR_APLICACOES,
  // LISTAR_APLICACOES,
  PESQUISAR_APLICACOES,
  PESQUISAR_CONTRATOS,
  PESQUISAR_TIPOS_CONTRATOS,
  PESQUISAR_DISPOSITIVOS,
  BUSCAR_DISPOSITIVO,
  // LISTAR_DISPOSITIVO,
  LIMPAR_DISPOSITIVO,
  LIMPAR_DISPOSITIVOS,
  LISTAR_GRUPOS,
  PESQUISAR_GRUPOS,

  BUSCAR_CHAVEGERADA,
  PESQUISAR_CHAVEGERADA,
  LIMPAR_CHAVEGERADA,
} from "../../core/store/types";
import { api } from "../../core/config";
import {
  salvarToken,
  buscarToken,
  headers,
  removerToken,
  buscarId,
} from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

//Actions Usuario
export const alterarFotoPerfil = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(`${api}/v1/usuarios/perfil-imagem`, dadosUsuario, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const alterarPerfil = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/usuarios/perfil/${dadosUsuario.id}`,
        { nome: dadosUsuario.nome, email: dadosUsuario.email },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const alterarSenha = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(`${api}/v1/usuarios/${dadosUsuario.id}/senha`, {
        senhaAtual: dadosUsuario.senhaAtual,
        novaSenha: dadosUsuario.senha,
      })
      .then((response) => {
        removerToken();
        dispatch({ type: LOGOUT_USUARIO });
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const alterarUsuario = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/usuarios/${dadosUsuario.id}`,
        { nome: dadosUsuario.nome, email: dadosUsuario.email },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const criarConta = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/usuarios`, dadosUsuario)
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const ativarUsuario = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/usuarios/${dadosUsuario.id}/ativa`,
        { ativo: dadosUsuario.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarUsuario = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/usuarios/${dadosUsuario.id}/desativa`,
        { ativo: dadosUsuario.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getPerfil = (callback) => {
  return (dispatch) => {
    if (buscarToken()) {
      axios
        .get(`${api}/v1/usuarios/perfil/${buscarId()}`, headers())
        .then((response) => {
          salvarToken(response.data);
          dispatch({ type: LOGIN_USUARIO, payload: response.data });
        })
        .catch((err) => {
          removerToken();
          dispatch({ type: LOGOUT_USUARIO });
          errorHandler(err);
        });
    } else {
      removerToken();
      dispatch({ type: LOGOUT_USUARIO });
    }
  };
};

export const getUsuario = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/usuarios/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_USUARIO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const getUsuarios = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/usuarios`, headers())
      .then((response) => {
        dispatch({ type: LISTAR_USUARIOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const headersPersonalizado = () => {
  return {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    Accept: "application/json",
  }
}

export const handleLogin = ({ email, senha }, callback) => {
  var details = {
    username: email,
    password: senha,
    grant_type: "password",
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const requestOptions = {
    headers: {
      Authorization: "Basic " + Base64.encode("sac-web:@iv#262"),
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    Accept: "application/json",
  };

  return (dispatch) => {
    axios
      .post(`${api}/oauth/token`, formBody, requestOptions)
      .then((response) => {
        salvarToken(response.data);
        dispatch({ type: LOGIN_USUARIO, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const handleLogout = () => {
  removerToken();
  window.history.replaceState(null, null, "/");
  return { type: LOGOUT_USUARIO };
};

export const limparUsuario = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_USUARIO });
  };
};

export const limparUsuarios = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_USUARIOS });
  };
};

export const pesquisarUsuarios = (nome, email) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/usuarios?nome=${nome}&email=${email}`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_USUARIOS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const recarregaPerfil = (id, callback) => {
  return function (dispatch) {
    axios
      .get(`${api}/v1/usuarios/perfil/${id}`)
      .then((response) => {
        dispatch({ type: LOGIN_USUARIO, payload: response.data });
        callback({ erro: response.data });
      })
      .catch((erro) => {
        dispatch({ type: LOGOUT_USUARIO });
        callback(errorHandler(erro));
      });
  };
};

export const recuperarSenha = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .put(`${api}/v1/usuarios/recuperar-senha`, dadosUsuario)
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const removerUsuario = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/usuarios/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const salvarUsuario = (dadosUsuario, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/usuarios`, dadosUsuario, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const validacaoRecuperarSenha = (recuperarSenha, callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/usuarios/recuperar-senha/${recuperarSenha}`)
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
//Fim Actions Usuario

//Actions Natureza
export const limparNatureza = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_NATUREZA });
  };
};

export const limparNaturezas = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_NATUREZAS });
  };
};

export const removerNatureza = (id, callback) => {
  return (dispatch) => {
    axios
      .delete(`${api}/v1/naturezas/${id}`, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarNaturezas = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/naturezas`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_NATUREZAS, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const salvarNatureza = (dadosNatureza, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/naturezas`, dadosNatureza, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getNatureza = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/naturezas/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_NATUREZA, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarNatureza = (dadosNatureza, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/naturezas/${dadosNatureza.id}`,
        {
          nome: dadosNatureza.nome,
          padrao: dadosNatureza.padrao,
          informaConclusao: dadosNatureza.informaConclusao,
          invisivel: dadosNatureza.invisivel,
        },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const ativarNatureza = (dadosNatureza, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/naturezas/${dadosNatureza.id}/ativa`,
        { ativo: dadosNatureza.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarNatureza = (dadosNatureza, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/naturezas/${dadosNatureza.id}/desativa`,
        { ativo: dadosNatureza.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
//Fim Actions Natureza

//Actions Cliente
export const limparCliente = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CLIENTE });
  };
};

export const limparClientes = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CLIENTES });
  };
};

export const pesquisarClientes = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/clientes`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CLIENTES, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarCliente = (dadosFiltro) => {
  return (dispatch) => {
      axios.get(`${api}/v1/clientes?nomeReduzido=${dadosFiltro.nomeReduzido}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_CLIENTES, payload: response.data });
          })
          .catch(errorHandler);
  }
}
//Fim Actions Cliente

//Actions StatusAtendimento
export const pesquisarStatusAtendimento = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/status`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_STATUSATENDIMENTO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const limparStatusAtendimento = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_STATUSATENDIMENTO });
  };
};
//Fim Actions StatusAtendimento

//Actions Atendimento
export const pesquisarAtendimentosPorStatus = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/estatisticas/servicos-por-status`, headers())
      .then((response) => {
        dispatch({
          type: PESQUISAR_ATENDIMENTOS_POR_STATUS,
          payload: response.data,
        });
      })
      .catch(errorHandler);
  };
};
//Fim Actions Atendimento

//Actions Aplicações
export const limparAplicacao = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_APLICACAO });
  };
};

export const limparAplicacoes = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_APLICACOES });
  };
};

export const removerAplicacao = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/aplicacoes/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const pesquisarAplicacoes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/aplicacoes`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_APLICACOES, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const salvarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/aplicacoes`, dadosAplicacao, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getAplicacao = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/aplicacoes/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_APLICACAO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/aplicacoes/${dadosAplicacao.id}`,
        {
          nome: dadosAplicacao.nome,
          descricao: dadosAplicacao.descricao,
          chaveAplicacao: dadosAplicacao.chaveAplicacao,
          padrao: dadosAplicacao.padrao,
        },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

//Fim Actions Aplicações

//Action para pesquisar todos os atendimentos

export const getAllAtendimentos = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/servicos`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_ATENDIMENTOS, payload: response.data });
        console.log(response.data);
      })
      .catch(errorHandler);
  };
};

//Action para pesquisar contratos
export const pesquisarTiposContrato = () => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/tipos-contrato`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_TIPOS_CONTRATOS, payload: response.data });
        console.log(response.data);
      })
      .catch(errorHandler);
  };
};

export const pesquisarContrato = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/contrato`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CONTRATOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

//Action para pesquisar dispositivos
export const getDispositivo = (id, callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/dispositivos/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_DISPOSITIVO, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarDispositivos = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/dispositivos`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_DISPOSITIVOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarDispositivo = (dadosFiltro) => {
  return (dispatch) => {
      axios.get(`${api}/v1/dispositivos?id=${dadosFiltro.id}&dispositivos?mac=${dadosFiltro.mac}&nomeComputador=${dadosFiltro.nomeComputador}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_DISPOSITIVOS, payload: response.data });
          })
          .catch(errorHandler);
  }
}

export const ativarDispositivo = (dadosDispositivo, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/dispositivos/${dadosDispositivo.id}/ativa`,
        { ativo: dadosDispositivo.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarDispositivo = (dadosDispositivo, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/dispositivos/${dadosDispositivo.id}/desativa`,
        { ativo: dadosDispositivo.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const limparDispositivo = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_DISPOSITIVO });
  };
};

export const limparDispositivos = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_DISPOSITIVOS });
  };
};

//Action para pesquisar grupos

export const getGrupos = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        callback(response.data._embedded.grupos);
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getGrupo = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        dispatch({ type: LISTAR_GRUPOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getGrp = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        callback(response.data._embedded.grupos);
      })
      .catch((err) => callback(errorHandler(err)));
  };
};


export const associateGrp = (userId, grpId, callback) => {
  return () => {
    axios
      .put(`${api}/v1/usuarios/${userId}/grupos`, { grupos: grpId }, headersPersonalizado())
      .then((response) => {
        console.log(response);
        callback({ erro: response.data });
      })
      .catch((err) => {
        callback(errorHandler(err));
      });
  };
};


export const deleteGrp = (codGrp, callback) => {
  axios
    .delete(`${api}/v1/grupos/${codGrp}`)
    .then((response) => {
      callback({ success: true });
    })
    .catch((err) => {
      callback(errorHandler(err));
    });
};

export const pesquisarGrupos = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_GRUPOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};


//Action para pesquisar permissões

export const getPermissoes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/permissoes`, headers())
      .then((response) => {
        callback(response.data._embedded.permissoes);
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const deletePermits = (grpId, callback) => {
  return () => {
    axios
      .delete(`${api}/v1/grupos/${grpId}/permissoes`, headers())
      .then((results) => {
        results.push("true");
        callback(results);
      })
      .catch((err) => {
        callback(errorHandler(err));
      });
  };
};

export const associatePermits = (grpId, permitId, callback) => {
  return () => {
    axios
    .put(`${api}/v1/grupos/${grpId}/permissoes`, { permissoes: permitId })
    .then((response) => {
      console.log(response);
      callback({ erro: response.data });
    })
    .catch((err) => {
      callback(errorHandler(err));
    });
  }
};

//Actions chaves geradas:

export const limparChaveGerada = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_CHAVEGERADA });
  };
};

export const getChaveGerada = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/chaves-geradas/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_CHAVEGERADA, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const pesquisarChaveGerada = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/chaves-geradas`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_CHAVEGERADA, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const pesquisarChavesBotao = (dadosFiltro) => {
  return (dispatch) => {
      axios.get(`${api}/v1/servicos?mac=${dadosFiltro.mac}&inicioLiberacao=${dadosFiltro.inicioLiberacao}&finalLiberacao=${dadosFiltro.finalLiberacao}&size=50&page=${dadosFiltro.page}&sort=id`, headers())
          .then((response) => {
              dispatch({ type: PESQUISAR_CHAVEGERADA, payload: response.data });
          })
          .catch(errorHandler);
  }
}