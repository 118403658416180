import React from "react";
import { Button, Spinner } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";

const BotaoApagar = (props) => {
  if (props.aguardando) {
    return (
      <>
        <Button
          id="idApagar"
          className="btn-sm mr-1 botao-primario-exclusao"
          disabled
        >
          <Spinner className="ml-1" size="sm" color="light" />
          Apagando...
        </Button>
      </>
    );
  }

  return (
    <>
      <Button id="idApagar" className="btn-sm mr-1 botao-primario-exclusao">
        <DeleteIcon />
        Excluir
      </Button>
    </>
  );
};

export default BotaoApagar;
