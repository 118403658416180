import React from "react";
// import useState from 'react-usestateref';
import { StyleSheet, css } from "aphrodite";

import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import BotaoOk from "../BotaoOk";

import "../../assets/styles/liberaExterno.css";

const ModalEmailEnviado = (props) => {
  // const [numSerie, setNumeroSerie, numSerieCur] = useState(props.numSerie);

  const msg = `Aguarde o contato.`;

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={css(styles.container)}
        centered
      >
        <ModalHeader toggle={props.toggle}>Enviado</ModalHeader>
        <ModalBody>
          <FormGroup className="row mb-0">
            <div className="col-sm-12">
              <Alert color="warning">
                <WarningRoundedIcon
                  style={{ color: "#F79400", fontSize: 45 }}
                />
                Email com sua solicitação enviado ao suporte.
                <br />
                <center>{msg}</center>
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEmailEnviado;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    fontSize: 15,
  },
});
