import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import "../../assets/styles/painelDeControle/index.css";

const BotaoCadastrar = (props) => {
  return (
    <>
      <Link to={props.uri}>
        <Button id="idCadastrar" className="btn-sm mt-2 botao-primario">
          <AddIcon />
        </Button>
      </Link>
    </>
  );
};

export default BotaoCadastrar;
