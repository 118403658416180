import axios from 'axios'

import { api } from '../../core/config'
import { headers } from '../../core/store/localStorage'
import errorHandler from '../../core/store/errorHandler'
import { BUSCAR_GRUPO, LIMPAR_GRUPO, LIMPAR_GRUPOS, PESQUISAR_GRUPOS } from '../../core/store/types';

export const limparGrupo = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_GRUPO });
  };
};

export const limparGrupos = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_GRUPOS });
  };
};

export const pesquisarGrupos = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_GRUPOS, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const salvarGrupos = (dadosGrupo, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/grupos`, dadosGrupo, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getGrupos = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos`, headers())
      .then((response) => {
        callback(response.data._embedded.grupos)
      })
      .catch((err) => callback(errorHandler(err)))
  }
}

export const getGrupo = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/grupos/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_GRUPO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarGrupo = (dadosGrupo, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/grupos/${dadosGrupo.id}`,
        { nome: dadosGrupo.nome },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const deleteAssociations = (userId, callback) => {
  return () => {
    axios
      .delete(`${api}/v1/usuarios/${userId}/grupos`, headers())
      .then((response) => {
        console.log('Resposta delete: ', response)
        callback({ success: true, response: response })
      })
      .catch((err) => {
        callback(errorHandler(err))
      })
  }
}

export const associateGrupo = (userId, depId, callback) => {
  return () => {
    axios
      .put(
        `${api}/v1/usuarios/${userId}/grupos/${depId}`,
        null,
        headers(),
      )
      .then((response) => {
        console.log(response)
        callback({ success: true, response: response })
      })
      .catch((err) => {
        callback(errorHandler(err))
      })
  }
}

export const deleteGrupo = (codDep, callback) => {
  axios
    .delete(`${api}/v1/departamentos/${codDep}`)
    .then((response) => {
      callback({ success: true })
    })
    .catch((err) => {
      callback(errorHandler(err))
    })
}