export const header = (title) => {
    return [
        {
          text: title,
          fontSize: 16,
          bold: true,
          margin: [15, 20, 0, 45],
        },
      ];
}
