import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
// import LaunchIcon from '@mui/icons-material/LaunchOutlined';
import AddToHomeScreenOutlinedIcon from "@mui/icons-material/AddToHomeScreenOutlined";
// import NatureIcon from '@mui/icons-material/NatureOutlined';
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import BallotIcon from "@mui/icons-material/BallotOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKeyOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';

import {
  ATENDIMENTO_URLS,
  APLICACAO_URLS,
  CONTRATOS_URLS,
  LIBERACAO_URLS,
  NATUREZA_URLS,
  USUARIO_URLS,
  PERFIL_URLS,
  INICIO_URLS,
  DISPOSITIVOS_URLS,
  CLIENTE_URLS,
  ASSOCIACOES_URLS,
  LISTACHAVES_URLS,
  GRUPOS_URLS,
} from "./pagesUrls";

const DrawerIconList = ({ open }) => {
  const matchInicio = useRouteMatch(INICIO_URLS);
  const matchAtendimentos = useRouteMatch(ATENDIMENTO_URLS);
  const matchDispositivos = useRouteMatch(DISPOSITIVOS_URLS);
  const matchAplicacoes = useRouteMatch(APLICACAO_URLS);
  const matchClientes = useRouteMatch(CLIENTE_URLS);
  const matchContratos = useRouteMatch(CONTRATOS_URLS);
  const matchLiberacao = useRouteMatch(LIBERACAO_URLS);
  const matchNaturezas = useRouteMatch(NATUREZA_URLS);
  const matchUsuarios = useRouteMatch(USUARIO_URLS);
  const matchPerfil = useRouteMatch(PERFIL_URLS);
  const matchAssociacoes = useRouteMatch(ASSOCIACOES_URLS);
  const matchChavesGeradas = useRouteMatch(LISTACHAVES_URLS);
  const matchGrupos = useRouteMatch(GRUPOS_URLS);

  const [openClienteSubMenu, setOpenClienteSubMenu] = useState(false);
  const [openLiberacaoSubMenu, setOpenLiberacaoSubMenu] = useState(false);
  const [openUsuarioSubMenu, setOpenUsuarioSubMenu] = useState(false);

  function toggleClienteMenu() {
    setOpenClienteSubMenu(!openClienteSubMenu);
  }

  function toggleLiberacaoMenu() {
    setOpenLiberacaoSubMenu(!openLiberacaoSubMenu);
  }

  function toggleUsuarioMenu() {
    setOpenUsuarioSubMenu(!openUsuarioSubMenu);
  }

  return (
    <List>
      <Link to={"/sac/pagina-inicial"} className={css(styles.noDecoration)}>
        <ListItem key={"Início"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={!!matchInicio}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                marginLeft: 0.5,
              }}
            >
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Início"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={"/sac/painel-de-controle"} className={css(styles.noDecoration)}>
        <ListItem key={"Atendimentos"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={!!matchAtendimentos}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                marginLeft: 0.5,
              }}
            >
              <HeadsetMicOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Atendimentos"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={"/sac/aplicacoes"} className={css(styles.noDecoration)}>
        <ListItem key={"Aplicacoes"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={!!matchAplicacoes}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                marginLeft: 0.5,
              }}
            >
              <AddToHomeScreenOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Aplicações"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Link>

      <Link to={"/sac/naturezas"} className={css(styles.noDecoration)}>
        <ListItem key={"Naturezas"} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            selected={!!matchNaturezas}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                marginLeft: 0.5,
              }}
            >
              <FactCheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Naturezas"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Link>

      <ListItemButton
        onClick={toggleLiberacaoMenu}
        selected={!!matchLiberacao}
        key={"Liberação"}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            marginLeft: 0.5,
          }}
        >
          <LockOpenOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={"Liberação"} sx={{ opacity: open ? 1 : 0 }} />
        {openLiberacaoSubMenu ? (
          <ExpandLess color="action" />
        ) : (
          <ExpandMore color="action" />
        )}
      </ListItemButton>
      {openLiberacaoSubMenu && <Divider />}
      <Collapse in={openLiberacaoSubMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to={"/sac/liberacao"} className={css(styles.noDecoration)}>
            <ListItem
              key={"Gera_Liberacao"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                selected={!!matchLiberacao}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginLeft: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LockOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Gerar Liberação"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={"/sac/chaves-geradas"} className={css(styles.noDecoration)}>
            <ListItem
              key={"Chaves-geradas"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                selected={!!matchChavesGeradas}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginLeft: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Chaves Geradas"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider />

      <ListItemButton
        onClick={toggleUsuarioMenu}
        selected={!!matchUsuarios}
        key={"Usuarios"}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            marginLeft: 0.5,
          }}
        >
          <AccountCircleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={"Usuário"} sx={{ opacity: open ? 1 : 0 }} />
        {openUsuarioSubMenu ? (
          <ExpandLess color="action" />
        ) : (
          <ExpandMore color="action" />
        )}
      </ListItemButton>
      {openUsuarioSubMenu && <Divider />}
      <Collapse in={openUsuarioSubMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        <Link to={"/sac/usuarios"} className={css(styles.noDecoration)}>
            <ListItem key={"Usuarios"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!matchUsuarios}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: 2,
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Usuários"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to={"/sac/grupos"} className={css(styles.noDecoration)}>
            <ListItem key={"Grupos"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!matchGrupos}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: 2,
                  }}
                >
                  <SwitchAccountOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Grupos"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to={"/sac/associacoes"} className={css(styles.noDecoration)}>
            <ListItem
              key={"Associacoes"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!matchAssociacoes}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: 2,
                  }}
                >
                  <AddOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Associações"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link
            to={"/sac/usuarios-alterar-perfil"}
            className={css(styles.noDecoration)}
          >
            <ListItem
              key={"AlterarPerfil"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!matchPerfil}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: 2,
                  }}
                >
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Perfil"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Divider />
        </List>
      </Collapse>
      <Divider />

      <ListItemButton
        onClick={toggleClienteMenu}
        selected={!!matchClientes}
        key={"Clientes"}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            marginLeft: 0.5,
          }}
        >
          <PersonSearchOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={"Clientes"} sx={{ opacity: open ? 1 : 0 }} />
        {openClienteSubMenu ? (
          <ExpandLess color="action" />
        ) : (
          <ExpandMore color="action" />
        )}
      </ListItemButton>
      {openClienteSubMenu && <Divider />}
      <Collapse in={openClienteSubMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to={"/sac/clientes"} className={css(styles.noDecoration)}>
            <ListItem key={"Clientes"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={!!matchClientes}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginLeft: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PersonPinOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Clientes"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={"/sac/dispositivos"} className={css(styles.noDecoration)}>
            <ListItem
              key={"Dispositivos"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                selected={!!matchDispositivos}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginLeft: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DevicesOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Dispositivos"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to={"/sac/Contratos"} className={css(styles.noDecoration)}>
            <ListItem
              key={"Contratos"}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!matchContratos}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginLeft: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BallotIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Contratos"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </List>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerIconList;
