import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import LogicaGraficos from '../LogicaGraficos';

class Combo extends Component{
    state ={
        chartData : []
    }

    callBackFunction = (childData) =>{
        this.setState({chartData:childData})
    }

    render(){
        return(
            <>
                <LogicaGraficos parentCallback={this.callBackFunction} tipo={'ComboMedia'}/>
                <div className='card_graph'>
                <center>
                    <p>Total de atendimentos</p>
                    <p className='card_subtitle'>Total diário x Dia da semana(com média)</p>
                </center>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="ComboChart"
                        loader={<div>Carregando Gráfico</div>}
                        data={this.state.chartData}
                        options={{
                            
                            seriesType: 'bars',
                            series: { 1: { type: 'line' } },
                            // colors: ['Purple','Green'],
                            backgroundColor: 'rgb(255, 255, 255)',
                            legend: 'none',
                            chartArea:{
                                width:'94%'
                            },
                                }}
                            // For tests
                        rootProps={{ 'data-testid': '2' }}
                        />
                    </div>
            </>        
        )
    }
}

export default Combo