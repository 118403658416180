import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsAplicacao from "../../../domain/actions/actionsAplicacao";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

import checkbox from "../../../assets/styles/checkbox.css";

class AplicacoesAlterar extends Component {
  state = {
    id: "",
    nome: "",
    descricao: "",
    chaveAplicacao: "",
    padrao: false,
    erro: "",
    sucesso: "",
    atencao: "",
    click: false,
    aguardando: false,
    dadosNatureza: false,
    formularioPronto: false,
    formularioSucesso: false,
    formularioAtencao: false,
    formularioErro: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAplicacao(id);
  }

  async componentDidUpdate(nextProps) {
    const { id } = this.props.match.params;
    if (!this.props.aplicacao && nextProps.aplicacao)
      this.props.getAplicacao(id);
    await this.receberDadosAplicacao();
  }

  receberDadosAplicacao() {
    const { id } = this.props.match.params;

    if (
      typeof this.props.aplicacao !== "undefined" &&
      this.props.aplicacao !== null &&
      this.props.aplicacao.id == id &&
      !this.state.dadosAplicacao
    ) {
      this.setState({ id: this.props.aplicacao.id });
      this.setState({ nome: this.props.aplicacao.nome });
      this.setState({ descricao: this.props.aplicacao.descricao });
      this.setState({ chaveAplicacao: this.props.aplicacao.chaveAplicacao });
      this.setState({ padrao: this.props.aplicacao.padrao });
      this.setState({ dadosAplicacao: true });
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

//   onChangeClick = (padrao) => {
//     this.setState({ padrao: !padrao })
//   }

  async alterarAplicacao() { 
    this.setState({ click: true })
    this.setState({ erro: "" });
    await this.receberDadosFormulario();

    this.setState({ formularioAtencao: !this.criticas() });
    if (!this.criticas()) return;

    this.setState({ aguardando: true });
    const { id, nome, descricao, chaveAplicacao, padrao } = this.state;
    console.log(this.state);

    this.props.alterarAplicacao({ id, nome, descricao, chaveAplicacao, padrao }, (err) => {
      if (err.erro.mensagem) {
        this.setState({ erro: { mensagem: err.erro.mensagem } });
        this.setState({ aguardando: false });
        this.setState({ formularioErro: true });
      } else {
        this.setState({ aguardando: false });
        this.setState({
          sucesso: { mensagem: "Aplicação alterada com sucesso!" },
        });
        this.setState({ formularioSucesso: true });
      }
    });
  }

  receberDadosFormulario() {
    this.setState({ id: document.querySelector("#id").value });
    this.setState({ nome: document.querySelector("#nome").value });
    this.setState({ descricao: document.querySelector("#descricao").value });
    this.setState({
      chaveAplicacao: document.querySelector("#chaveAplicacao").value,
    });
    this.setState({ padrao: document.querySelector("#padrao").value });
  }

  criticas() {
    const { nome, descricao } = this.state;
    if (!nome)
      return this.setState({ atencao: { mensagem: "Preencha o campo nome!" } });
    if (!descricao)
      return this.setState({
        atencao: { mensagem: "Preencha o campo descrição!" },
      });
    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  fecharFormularioSucesso = () => {
    this.setState({ formularioPronto: true });
  };

  render() {
    const {
      id,
      nome,
      descricao,
      chaveAplicacao,
      padrao,
      click,
      aguardando,
      erro,
      sucesso,
      atencao,
      dadosAplicacao,
      formularioPronto,
      formularioAtencao,
      formularioErro,
      formularioSucesso,
    } = this.state;

    if (formularioPronto) {
      return <Redirect to={{ pathname: "/sac/aplicacoes" }} />;
    }

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Aplicação</h2>
          </div>
        </div>
        <hr />
        <ModalErro
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
          erro={erro}
        ></ModalErro>
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        >
          {" "}
        </ModalAtencao>
        <ModalSucesso
          isOpen={formularioSucesso}
          toggle={() => this.fecharFormularioSucesso()}
          sucesso={sucesso}
        >
          {" "}
        </ModalSucesso>
        <div className="form-group row">
          <div className="col-sm-1">
            <FormGroup>
              <Label for="id">ID</Label>
              <Input
                type="text"
                value={id}
                name="id"
                id="id"
                className="form-control"
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                className="form-control"
                placeholder={
                  dadosAplicacao ? "Nome da Aplicação" : "Carregado..."
                }
                disabled={dadosAplicacao ? false : true}
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="descricao">Descrição</Label>
              <Input
                type="text"
                value={descricao}
                name="descricao"
                id="descricao"
                className="form-control"
                placeholder={
                  dadosAplicacao ? "Descrição da Aplicação" : "Carregado..."
                }
                disabled={dadosAplicacao ? false : true}
                autoComplete="descricao"
                onChange={(ev) => this.onChangeInput("descricao", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2">
            <FormGroup>
              <Label for="chaveAplicacao">Chave</Label>
              <Input
                type="text"
                value={chaveAplicacao}
                name="chaveAplicacao"
                id="chaveAplicacao"
                className="form-control"
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-sm-1">
            <FormGroup className="checkbox-wrapper">
              <Label className="container" for="padrao">
                Padrão
              </Label>
              <Input
                className={padrao ? "checked" : ""}
                type="checkbox"
                value={padrao}
                name="padrao"
                id="padrao"
                disabled={click}
                onClick={() => this.setState({padrao: !this.state.padrao})}
              />
            </FormGroup>
          </div>
        </div>
        <Link onClick={() => this.alterarAplicacao()} to="#">
          <BotaoConfirmar aguardando={aguardando} />
        </Link>
        <Link to={"/sac/aplicacoes"}>
          <BotaoVoltar />
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  aplicacao: state.aplicacao.aplicacao,
});

export default connect(mapStateToProps, actionsAplicacao)(AplicacoesAlterar);
