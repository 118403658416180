import { BUSCAR_STATUSATENDIMENTO, LIMPAR_STATUSATENDIMENTO, LISTAR_STATUSATENDIMENTO, PESQUISAR_STATUSATENDIMENTO } from '../../core/store/types';

const reducersStatusAtendimento = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_STATUSATENDIMENTO:
            return {
                ...state,
                statusAtendimento: action.payload,
            }
        case LIMPAR_STATUSATENDIMENTO:
            return {
                ...state,
                statusAtendimento: null,
            }
        case LISTAR_STATUSATENDIMENTO:
            return {
                ...state,
                statusAtendimento: action.payload._embedded.status,
            }
        case PESQUISAR_STATUSATENDIMENTO:
            return {
                ...state,
                statusAtendimentoPesquisados: action.payload._embedded.status,
            }
        default:
            return state;
    }
}
export default reducersStatusAtendimento;