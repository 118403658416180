import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import AlertaErro from '../../../components/AlertaErro';
import AlertaAtencao from '../../../components/AlertaAtencao';

const UsuarioAlterarSenha = (props) => { 
    const { usuario } = useSelector((state) => state);

    const [id, setId] = useState("");      
    const [senhaAtual, setSenhaAtual] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");
    const [atencao, setAtencao] = useState("");
    const [erro, setErro] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [formularioSucesso, setFormularioSucesso] = useState(false);
    const [aguardando, setAguardando] = useState(false);

    const receberDadosApi = () => {
        if (usuario.usuarioLogado) {
            setId(usuario.usuarioLogado.id);
        }
    }

    const criticas = () => {
        if (!senhaAtual) return setAtencao({ mensagem: "Preencha o campo senha atual!" });
        if (!senha) return setAtencao({ mensagem: "Preencha o campo senha!" });
        if (!confirmaSenha) return setAtencao({ mensagem: "Preencha o campo de confirmação de senha!" });
        if (senha !== confirmaSenha) return setAtencao({ mensagem: "As senhas não são iguais!" });
        if (senha.length < 6) return setAtencao({ mensagem: "A senha precisa ter pelo menos seis caracteres!" });
        return true;
    }

    const atualizarSenha = (e) => {
        e.preventDefault();
        if (!criticas()) return;
        setAguardando(true);
        props.alterarSenha({ id, senhaAtual, senha }, (retorno) => {
            if (retorno.erro.mensagem) {
                setErro({ mensagem: retorno.erro.mensagem });
                setAguardando(false);
            } else {
                setSucesso("Senha alterada com sucesso!");
                setFormularioSucesso(true);
            }
        });
    }

    //Ao renderizar componente
    useEffect(() => {
        receberDadosApi();
        // eslint-disable-next-line
    }, []);

    //Ao atualizar componente
    useEffect(() => {
        receberDadosApi();
        // eslint-disable-next-line
    }, [usuario.usuarioLogado]);    

    if (formularioSucesso) {
        return <Redirect to={{
            pathname: '/sac/perfil',
            state: { sucesso }
        }} />
    }

    return (
        <>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="display-4 titulo">Alterar Senha</h2>
                </div>
                <Link to={"/sac/perfil"}>
                    <button className="btn btn-outline-success btn-sm">
                        Voltar
                    </button>
                </Link>
            </div>
            <hr />
            <AlertaErro erro={erro} />
            <AlertaAtencao atencao={atencao} />
            <Form onSubmit={atualizarSenha}>
                <FormGroup>
                    <Label for="senhaAtual">Senha atual*</Label>
                    <Input className="col-sm-4"
                        type="password"
                        value={senhaAtual}
                        name="senhaAtual"
                        id="senhaAtual"
                        autoComplete="senhaAtual"
                        onChange={(ev) => setSenhaAtual(ev.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="senha">Nova senha*</Label>
                    <Input className="col-sm-4"
                        type="password"
                        value={senha}
                        name="senha"
                        id="senha"
                        autoComplete="senha"
                        onChange={(ev) => setSenha(ev.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="confirmaSenha">Confirme a senha*</Label>
                    <Input className="col-sm-4"
                        type="password"
                        value={confirmaSenha}
                        name="confirmaSenha"
                        id="confirmaSenha"

                        autoComplete="confirmaSenha"
                        onChange={(ev) => setConfirmaSenha(ev.target.value)}
                    />
                </FormGroup>
                <BotaoConfirmar aguardando={aguardando} />
            </Form>
        </>
    );
}

export default UsuarioAlterarSenha;