import { PERMISSOES_TODOS, PESQUISAR_PERMISSOES } from "../../core/store/types";

const reducersPermissoes = (state = {}, action) => {
  switch (action.type) {
    case PERMISSOES_TODOS:
      return {
        ...state,
        permissoes: action.payload,
      }
    case PESQUISAR_PERMISSOES:
      return {
        ...state,
        permissoesPesquisados: action.payload._embedded.permissoes,
      };
    default:
      return state;
  }
};

export default reducersPermissoes;