export const api = process.env.PUBLIC_URL
  ? "https://aplicacao.ivitech.com.br/sac"
  : "https://aplicacao.ivitech.com.br/sac";

// Versão para o Hostgator:
// : 'https://aplicacao.ivitech.com.br/sac'

// Versão para testes:
// 'http://192.168.3.116:8080/sac'

// 'http://ivitechremoto.dyndns.org:3061'
