import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsNatureza from "../../../domain/actions/actionsNatureza";
import { FormGroup } from "reactstrap";
import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoAtivar from "../../../components/BotaoAtivar";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
import BotaoDesativar from "../../../components/BotaoDesativar";
import BotaoExcluir from "../../../components/BotaoExcluir";
import ModalApagar from "../../../components/ModalApagar";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalErroRetorna from "../../../components/ModalErroRetorna";
// import ModalErroExclusao from "../../../components/ModalErroExclusao";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

//style
import "../../../assets/styles/tabela.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";

class Listar extends Component {
  state = {
    nome: "",
    mensagem: "",
    erro: "",
    aguardando: false,
    // confirmarExclusao: false,
    // confirmarExclusaoErro: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioErro: null,
  };

  //Início rotina de exclusão
  // abrirConfirmarExclusao(id) {
  //   var atendimentos = this.props.atendimentosPesquisados;
  //   this.setState({ confirmarExclusao: true });
  //   this.setState({ idParaExcluir: id });
  // }

  // fecharConfirmarExclusao() {
  //   this.setState({ confirmarExclusao: false });
  // }

  // fecharConfirmarExclusaoErro() {
  //   this.setState({ confirmarExclusao: false });
  //   this.setState({ confirmarExclusaoErro: false });
  // }

  // apagarNatureza() {
  //   this.setState({ aguardando: true });
  //   this.props.removerNatureza(this.state.idParaExcluir, (retorno) => {
  //     if (retorno.erro.mensagem) {
  //       this.setState({ erro: retorno.erro.mensagem });
  //       this.setState({ aguardando: false });
  //       this.setState({ confirmarExclusaoErro: true });
  //     } else {
  //       this.setState({ mensagem: retorno.erro.mensagem });
  //       this.setState({ aguardando: false });
  //       this.fecharConfirmarExclusao();
  //       this.props.limparNaturezas();
  //       this.pesquisarNaturezas();
  //     }
  //   });
  // }
  //Fim rotina de exclusão

  //Início da rotina de pesquisa
  // pesquisarNaturezas() {
  //   const { nome } = this.state;
  //   this.props.pesquisarNaturezas();
  //   if (this.props.location.state) {
  //     this.setState({ mensagem: this.props.location.state.mensagem });
  //   }
  // }

  pesquisarNaturezas() {
    this.props.pesquisarNaturezas((retorno) => {
      if (retorno.erro.mensagem) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ formularioErro: true });
      } else if (this.props.location.state) {
        this.setState({ mensagem: this.props.location.state.mensagem });
      }
    });
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro() {
    this.setState({ formularioErro: false });
  };

  //Fim da rotina de pesquisa

  //Inicio da rotina de ativação
  ativarNatureza() {
    this.setState({ aguardando: true });
    this.props.ativarNatureza(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.mensagem) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarAtivacao();
          this.props.limparNaturezas();
          this.pesquisarNaturezas();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }

  //Fim da rotina de ativação

  //Inicio da rotina de desativação
  desativarNatureza() {
    this.setState({ aguardando: true });
    this.props.desativarNatureza(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarNaturezas();
          this.props.limparNaturezas();
        }
      }
    );
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }
  //Fim da rotina de desativação

  componentDidMount() {
    this.pesquisarNaturezas();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.naturezasPesquisados && nextProps.naturezasPesquisados)
      this.pesquisarNaturezas();
  }

  componentWillUnmount() {
    actionsNatureza.limparNaturezas();
    actionsNatureza.limparNatureza();
  }
  render() {
    const {
      nome,
      mensagem,
      erro,
      aguardando,
      confirmarExclusao,
      confirmarExclusaoErro,
      confirmarAtivacao,
      confirmarDesativacao,
      formularioErro,
    } = this.state;
    var naturezas = [];
    if (this.props.naturezasPesquisados) {
      naturezas = this.props.naturezasPesquisados;
    }

    var qtde = naturezas.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }
    return (
      <>
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Natureza"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarNatureza()}
        />
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Natureza"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarNatureza()}
        />
        <ModalErroRetorna
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        />
        {/* <ModalApagar
          isOpen={confirmarExclusao}
          toggle={() => this.fecharConfirmarExclusao()}
          apagar="Natureza"
          aguardando={aguardando}
          apagarObjeto={() => this.apagarNatureza()}
        /> */}
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Naturezas</h2>
          </div>
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/naturezas-cadastrar"
                descricaoObjeto="Natureza"
              />
            </FormGroup>
          </div>
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />

        {/* {!this.props.naturezasPesquisados && (
          <ModalErro isOpen={true} erro={{ mensagem: erro }}></ModalErro>
        )} */}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Nome</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {naturezas.map((natureza) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={natureza.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{natureza.id}</Td>
                  <Td>
                    <Link to={`/sac/naturezas-alterar/${natureza.id}`}>
                      {natureza.nome}
                    </Link>
                  </Td>
                  <Td className="text-center">
                    <span>
                      {natureza.ativo ? (
                        <BotaoDesativar
                          onClick={() =>
                            this.abrirConfirmarDesativacao(natureza.id)
                          }
                        />
                      ) : (
                        <BotaoAtivar
                          onClick={() =>
                            this.abrirConfirmarAtivacao(natureza.id)
                          }
                        />
                      )}
                      {/* <BotaoExcluir
                        onClick={() => this.abrirConfirmarExclusao(natureza.id)}
                      /> */}
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  atendimentosPesquisados: state.natureza.atendimentosPesquisados,
  naturezasPesquisados: state.natureza.naturezasPesquisados,
});

export default connect(mapStateToProps, actionsNatureza)(Listar);
