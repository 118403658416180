import React from "react";
import { Button } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import "../../assets/styles/painelDeControle/index.css";

const BotaoPesquisar = (props) => {
  return (
    <>
      <Button
        onClick={props.onClickPesquisar}
        id="idPesquisar"
        className="btn btn-sm mt-2 botao-secundario"
      >
        <SearchIcon />
        <span> Pesquisar</span>
      </Button>
    </>
  );
};

export default BotaoPesquisar;
