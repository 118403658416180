export const
    BUSCAR_USUARIO = 'BUSCAR_USUARIO',
    LIMPAR_USUARIO = 'LIMPAR_USUARIO',
    LIMPAR_USUARIOS = 'LIMPAR_USUARIOS',
    LISTAR_USUARIOS = 'LISTAR_USUARIOS',
    LOGIN_USUARIO = 'LOGIN_USUARIO',
    LOGOUT_USUARIO = 'LOGOUT_USUARIO',
    PESQUISAR_USUARIOS = 'PESQUISAR_USUARIOS',

    BUSCAR_NATUREZA = 'BUSCAR_NATUREZA',
    LIMPAR_NATUREZA = 'LIMPAR_NATUREZA',
    LIMPAR_NATUREZAS = 'LIMPAR_NATUREZAS',
    LISTAR_NATUREZAS = 'LISTAR_NATUREZAS',
    PESQUISAR_NATUREZAS = 'PESQUISAR_NATUREZAS',

    BUSCAR_ATENDIMENTO = 'BUSCAR_ATENDIMENTO',
    LIMPAR_ATENDIMENTO = 'LIMPAR_ATENDIMENTO',
    LIMPAR_ATENDIMENTOS = 'LIMPAR_ATENDIMENTOS',
    LISTAR_ATENDIMENTOS = 'LISTAR_ATENDIMENTOS',
    PESQUISAR_ATENDIMENTOS = 'PESQUISAR_ATENDIMENTOS',
    PESQUISAR_ATENDIMENTOS_POR_STATUS = 'PESQUISAR_ATENDIMENTOS_POR_STATUS',

    PESQUISAR_TOTAL_ATENDIMENTOS = 'PESQUISAR_TOTAL_ATENDIMENTOS',
    PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO = 'PESQUISAR_TOTAL_ATENDIMENTOS_POR_TIPO',
    PESQUISAR_TOTAL_MENSAL_POR_CLIENTE = 'PESQUISAR_TOTAL_MENSAL_POR_CLIENTE',

    BUSCAR_CLIENTE = 'BUSCAR_CLIENTE',
    LIMPAR_CLIENTE = 'LIMPAR_CLIENTE',
    LIMPAR_CLIENTES = 'LIMPAR_CLIENTES',
    LISTAR_CLIENTES = 'LISTAR_CLIENTES',
    PESQUISAR_CLIENTES = 'PESQUISAR_CLIENTES',
    PESQUISAR_CLIENTE_PAGE = 'PESQUISAR_CLIENTE_PAGE',

    BUSCAR_STATUSATENDIMENTO = 'BUSCAR_STATUSATENDIMENTO',
    LIMPAR_STATUSATENDIMENTO = 'LIMPAR_STATUSATENDIMENTO',
    LISTAR_STATUSATENDIMENTO = 'LISTAR_STATUSATENDIMENTO',
    PESQUISAR_STATUSATENDIMENTO = 'PESQUISAR_STATUSATENDIMENTO',

    BUSCAR_APLICACAO = 'BUSCAR_APLICACAO',
    LIMPAR_APLICACAO = 'LIMPAR_APLICACAO',
    LIMPAR_APLICACOES = 'LIMPAR_APLICACOES',
    LISTAR_APLICACOES = 'LISTAR_APLICACOES',
    PESQUISAR_APLICACOES = 'PESQUISAR_APLICACOES',  
    
    BUSCAR_CONTRATO = 'BUSCAR_CONTRATO',
    LIMPAR_CONTRATO = 'LIMPAR_CONTRATO',
    LIMPAR_CONTRATOS = 'LIMPAR_CONTRATOS',
    LISTAR_CONTRATOS = 'LISTAR_CONTRATOS',
    PESQUISAR_CONTRATOS = 'PESQUISAR_CONTRATOS',
    PESQUISAR_TIPOS_CONTRATOS = 'PESQUISAR_TIPOS_CONTRATOS',
    
    PESQUISAR_CODIGO_LIBERACAO = 'PESQUISAR_CODIGO_LIBERACAO', 

    BUSCAR_DISPOSITIVO = 'BUSCAR_DISPOSITIVO',
    LISTAR_DISPOSITIVO = 'LISTAR_DISPOSITIVO',
    PESQUISAR_DISPOSITIVOS = 'PESQUISAR_DISPOSITIVOS',
    LIMPAR_DISPOSITIVO = 'LIMPAR_DISPOSITIVO',
    LIMPAR_DISPOSITIVOS = 'LIMPAR_DISPOSITIVOS',

    GRUPOS_TODOS = 'GRUPOS_TODOS',
    LISTAR_GRUPOS = 'LISTAR_GRUPOS',
    PESQUISAR_GRUPOS = 'PESQUISAR_GRUPOS',
    BUSCAR_GRUPO = 'BUSCAR_GRUPO',
    LIMPAR_GRUPO = 'LIMPAR_GRUPO',
    LIMPAR_GRUPOS = 'LIMPAR_GRUPOS',

    PERMISSOES_TODOS = 'PERMISSOES_TODOS',
    PESQUISAR_PERMISSOES = 'PESQUISAR_PERMISSOES',

    BUSCAR_CHAVEGERADA = 'BUSCAR_CHAVEGERADA',
    LISTAR_CHAVEGERADA = 'LISTAR_CHAVEGERADA',
    PESQUISAR_CHAVEGERADA = 'PESQUISAR_CHAVEGERADA',
    PESQUISAR_CHAVESGERADAS = 'PESQUISAR_CHAVESGERADAS',
    LIMPAR_CHAVEGERADA = 'LIMPAR_CHAVEGERADA',
    LIMPAR_CHAVESGERADAS = 'LIMPAR_CHAVESGERADAS';
    