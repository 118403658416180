import React, { useEffect, useLayoutEffect, useState } from "react";
import Select from "react-select";
import { axios } from "axios";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import * as actionsUsuario from "../../domain/actions/actionsUsuario";

import BotaoConfirmarAlt from "../../components/BotaoConfirmarAlt";
import BotaoVoltarAlt from "../../components/BotaoVoltarAlt";
import ModalAtencaoAlt from "../../components/ModalAtencaoAlt";
import ModalErroAlt from "../../components/ModalErroAlt";
import ModalSucessoAlt from "../../components/ModalSucessoAlt";

const Associacoes = (props) => {
  const [usuarioSelecionado, setUsuarioSelecionado] = useState("");
  const [grpSelecionado, setGrpSelecionado] = useState("");
  const [gruposSelecionados, setGruposSelecionados] = useState("");
  const [permissaoSelecionada, setPermissaoSelecionada] = useState("");

  const [associaUsuario, setAssociaUsuario] = useState(false);
  const [associaGrupo, setAssociaGrupo] = useState(false);

  const [grps, setGrps] = useState([]);
  const [permit, setPermit] = useState([]);

  const [field, setField] = useState("");
  const [aguardando, setAguardando] = useState("");
  const [erro, setErro] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [atencao, setAtencao] = useState("");

  useEffect(() => {
    getUsuarios();
    getGrupo();
    getGrupos();
    getPermissoes();
  }, []);

  const criticas = () => {
    console.log(associaUsuario);
    if (associaUsuario) {
      if (
        usuarioSelecionado === null ||
        !usuarioSelecionado ||
        usuarioSelecionado === undefined ||
        usuarioSelecionado === "invalid" ||
        usuarioSelecionado == 0
      ) {
        setField("Selecione um Usuário");
        setAtencao(true);
        return true;
      }
      if (
        gruposSelecionados === null ||
        gruposSelecionados === undefined ||
        gruposSelecionados === "invalid" ||
        !gruposSelecionados ||
        gruposSelecionados.length === 0
      ) {
        setField("Selecione um ou mais grupos");
        setAtencao(true);
        return true;
      }
    }
    if (associaGrupo) {
      if (!gruposSelecionados || gruposSelecionados === "invalid") {
        setField("Selecione um grupo");
        setAtencao(true);
        return true;
      }
      if (!permissaoSelecionada || permissaoSelecionada.length === 0) {
        setField("Selecione uma ou mais permissões");
        setAtencao(true);
        return true;
      }
    }
    return false;
  };

  var id = [];

  const modificaUsuario = () => {
    setAguardando(true);
    if (criticas()) return;
    var uId = JSON.parse(usuarioSelecionado);
    var grupos = [];
    gruposSelecionados.map((grp) => {
      grupos.push(parseInt(grp.value));
    });
    id = grupos;
    props.associateGrp(uId, id, (retorno) => {
      if (retorno.erro.erro) {
        setErro(true);
        setErrorMessage(retorno.erro.mensagem);
        setAguardando(false);
      } else {
        setSucesso(true);
        setSuccessMessage("Usuário modificado com sucesso.");
        setAguardando(false);
      }
    });
  };

  const modificaGrupo = () => {
    setAguardando(true);
    if (criticas()) return;
    const gId = JSON.parse(grpSelecionado);
    var permissoes = [];
    permissaoSelecionada.map((permit) => {
      permissoes.push(parseInt(permit.value));
    });
    id = permissoes;
    props.associatePermits(gId, id, (retorno) => {
      if (retorno.erro.erro) {
        setErro(true);
        setErrorMessage(retorno.erro.mensagem);
        setAguardando(false);
      } else {
        setSucesso(true);
        setSuccessMessage("Grupo modificado com sucesso.");
        setAguardando(false);
      }
    });
  };

  const getUsuarios = () => {
    props.getUsuarios();
  };

  const getGrupo = () => {
    props.getGrupo();
  };

  const getGrupos = () => {
    props.getGrupos((response) => {
      var grupos = [];
      response.map((grp) => {
        grupos.push({
          value: `${grp.id}`,
          label: grp.nome,
        });
      });
      setGrps(grupos);
    });
  };

  const getPermissoes = () => {
    props.getPermissoes((response) => {
      var permissoes = [];
      response.map((permissao) => {
        permissoes.push({
          value: `${permissao.id}`,
          label: permissao.descricao,
        });
      });
      setPermit(permissoes);
    });
  };

  var usuarios = [];
  if (props.usuarios) usuarios = props.usuarios;

  var grupos = [];
  if (props.grupos) grupos = props.grupos;

  function userHandler(event) {
    event.preventDefault();

    setAssociaUsuario(true);
    console.log(associaUsuario);
  }

  function groupHandler(event) {
    event.preventDefault();

    setAssociaGrupo(true);
    console.log(associaGrupo);
  }

  const handleMultiSelect = (e) => {
    setGruposSelecionados(e);
  };

  const converteUser = (ev) => {
    setUsuarioSelecionado(ev.target.value);
    // setSelectedUser(usuarioSelecionado);

    console.log(usuarioSelecionado);
  };

  const converteGrp = (ev) => {
    setGrpSelecionado(ev.target.value);
    // setSelectedUser(usuarioSelecionado);

    console.log(grpSelecionado);
  };

  const handleMultiPermits = (e) => {
    setPermissaoSelecionada(e);
  };

  const fechaModal = () => {
    setSucesso(!sucesso);
    setAssociaUsuario(false);
    setAssociaGrupo(false);
  };

  const cleanAllFields = () => {
    setAssociaUsuario(false);
    setAssociaGrupo(false);
    setPermissaoSelecionada(null);
    setUsuarioSelecionado("invalid");
    setGruposSelecionados("invalid");
  };

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Associações</h2>
        </div>
      </div>
      <hr />
      <ModalErroAlt
        isOpen={erro}
        errorMessage={errorMessage}
        toggle={() => setErro(!erro)}
      />
      <ModalAtencaoAlt
        isOpen={atencao}
        toggle={() => {
          setAtencao(!atencao);
          setAguardando(false);
        }}
        field={field}
      />
      <ModalSucessoAlt isOpen={sucesso} toggle={fechaModal} />

      <div>
        {!associaUsuario && !associaGrupo ? (
          <div>
            <p>Tipo de associação:</p>
            <br />
            <a onClick={userHandler} href="#">
              Associar usuário a grupo(s)
            </a>
            <br />
            <br />
            <a onClick={groupHandler} href="#">
              Associar permissões a grupo
            </a>
          </div>
        ) : (
          false
        )}
        {associaUsuario && !associaGrupo ? (
          <div>
            <p>Escolha um ou mais grupos para associar a um usuário:</p>
            <div className="form-group row">
              <div style={{ marginTop: "1rem" }} className="col-sm-3">
                <FormGroup>
                  <Label for="usuario">Usuario</Label>
                  <Input
                    type="select"
                    placeholder="Selecione um usuário"
                    value={usuarioSelecionado}
                    onChange={converteUser}
                  >
                    <option value="0">{""}</option>
                    {usuarios.map((usuario) => (
                      <option value={usuario.id}>{usuario.nome}</option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div style={{ marginTop: "1rem" }} className="col-sm-5">
                <Label for="grupos">Grupos</Label>
                <Select
                  placeholder="Selecione um ou mais grupos"
                  value={gruposSelecionados}
                  onChange={handleMultiSelect}
                  options={grps}
                  isMulti
                  allowSelectAll={true}
                ></Select>
              </div>
            </div>
            <BotaoConfirmarAlt
              aguardando={aguardando}
              title="Confirmar"
              onClickConfirma={modificaUsuario}
            />
            <BotaoVoltarAlt onClickVoltar={cleanAllFields} />
          </div>
        ) : (
          false
        )}
        {!associaUsuario && associaGrupo ? (
          <div>
            <p>Escolha uma ou mais permissões para associar a um grupo:</p>
            <div className="form-group row">
              <div style={{ marginTop: "1rem" }} className="col-sm-3">
                <FormGroup>
                  <Label for="grupos">Grupo</Label>
                  <Input
                    type="select"
                    placeholder="Selecione um grupo"
                    value={grpSelecionado}
                    onChange={converteGrp}
                  >
                    <option value="0">{""}</option>
                    {grupos.map((grupo) => (
                      <option value={grupo.id}>{grupo.nome}</option>
                    ))}
                  </Input>

                  {/* <Select
                    placeholder="Selecione um grupo"
                    value={gruposSelecionados}
                    onChange={handleMultiSelect}
                    options={grps}
                  ></Select> */}
                </FormGroup>
              </div>
              <div style={{ marginTop: "1rem" }} className="col-sm-5">
                <FormGroup>
                  <Label for="grupos">Permissões</Label>
                  <Select
                    placeholder="Selecione uma ou mais permissões"
                    value={permissaoSelecionada}
                    onChange={handleMultiPermits}
                    options={permit}
                    isMulti
                    allowSelectAll={true}
                  ></Select>
                </FormGroup>
              </div>
            </div>
            <BotaoConfirmarAlt
              aguardando={aguardando}
              title="Confirmar"
              onClickConfirma={modificaGrupo}
            />
            <BotaoVoltarAlt onClickVoltar={cleanAllFields} />
          </div>
        ) : (
          false
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  usuarios: state.usuario.usuarios,
  grupos: state.grupo.grupos
});

export default connect(mapStateToProps, actionsUsuario)(Associacoes);
