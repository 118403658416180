import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import BlockIcon from "@mui/icons-material/Block";

const BotaoDesativar = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        id="idDesativar"
        className="btn-acoes btn-desativa btn-sm m-1"
      >
        <BlockIcon className="icon-acoes" />
      </Button>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target="idDesativar"
        toggle={toggle}
      >
        Desativar
      </Tooltip>
    </>
  );
};

export default BotaoDesativar;
