import React from "react";
import { Button } from "reactstrap";
import { StyleSheet, css } from "aphrodite";

// import { useHover } from "react-aria";
// import { Redirect } from 'react-router-dom';
// import { useState } from "react";

import "../../assets/styles/liberaExterno.css";

const BotaoOk = (props) => {
  return (
    <>
      <Button
        onClick={props.onClickOk}
        id="idOk"
        className={"m-2 btn-sm botao-ok " + css(styles.container)}
      >
        Ok
      </Button>
    </>
  );
};

export default BotaoOk;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    fontSize: 15,
  },
});
