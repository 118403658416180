import React from "react";
import { Button, Spinner } from "reactstrap";
import CheckIcon from "@mui/icons-material/Check";

const BotaoConfirmarAlt = (props, { aguardando }) => {
  if (aguardando) {
    return (
      <>
        <Button id="idConfirmar" className="btn-sm botao-primario" disabled>
          Aguarde
          <Spinner className="ml-1" size="sm" color="light" />
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        onClick={props.onClickConfirma}
        id="idConfirmar"
        className="mr-1 btn-sm botao-primario"
      >
        <CheckIcon />
        <span> Confirmar</span>
      </Button>
    </>
  );
};

export default BotaoConfirmarAlt;
