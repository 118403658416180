import React from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
// import { EnterClick } from "../../hooks/EnterClick";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import BotaoOk from "../BotaoOk";
const ModalSucessoAlt = (props) => {
  // EnterClick(props.toggle, "Enter");

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader toggle={props.toggle}>Informação</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Alert color="success">
                <p>Operação realizada com sucesso!</p>
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalSucessoAlt;
