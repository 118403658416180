import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import validator from 'validator';
import { connect } from 'react-redux';
import * as actionsUsuario from '../../../domain/actions/actionsUsuario';
import { FormGroup, Label, Input } from 'reactstrap';
import BotaoConfirmar from '../../../components/BotaoConfirmar';
import BotaoVoltar from '../../../components/BotaoVoltar';
import ModalAtencao from '../../../components/ModalAtencao';
import ModalErro from '../../../components/ModalErro';
import ModalSucesso from '../../../components/ModalSucesso';

const UsuarioCadastrar = (props) => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [erro, setErro] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [atencao, setAtencao] = useState("");
    const [aguardando, setAguardando] = useState(false);
    const [formularioPronto, setFormularioPronto] = useState(false);

    const [formularioSucesso, setFormularioSucesso] = useState(false);
    const [formularioAtencao, setFormularioAtencao] = useState(null);
    const [formularioErro, setFormularioErro] = useState(null);    

    const cadastrarUsuario = () => {
        setFormularioAtencao(!criticas());
        if (!criticas()) return;

        setAguardando(true);

        props.salvarUsuario({ nome, email, senha }, (retorno) => {
            if (retorno.erro.erro) {
                setAtencao("");
                setSucesso("");
                setErro({ erro: { mensagem: retorno.erro.mensagem } });
                setAguardando(false);
                setFormularioErro(true);
            } else {
                setAtencao("");
                setErro("");
                setSucesso({ mensagem: "Usuário cadastrado com sucesso!" });
                setFormularioSucesso(true);
                setAguardando(false);
            }
        })
    }

    const criticas = () => {
        if (!nome) return setAtencao({ mensagem: "Preencha o campo nome!" });
        if (!email) return setAtencao({ mensagem: "Preencha o campo e-mail!" });
        if (!validator.isEmail(email)) return setAtencao({ mensagem: "Preencha com e-mail válido!" });
        if (!senha) return setAtencao({ mensagem: "Preencha o campo senha!" });
        if (senha.length < 6) return setAtencao({ mensagem: "A senha precisa ter pelo menos seis caracteres!" });
        return true;
    }

    const fecharFormularioAtencao = () => {
        setFormularioAtencao(false);
    }

    const fecharFormularioErro = () => {
        setFormularioErro(false);
    }

    const fecharFormularioSucesso = () => {
        setFormularioPronto(true);
    }    

    if (formularioPronto) {
        return <Redirect to={{pathname: '/sac/usuarios'}} />
    }

    return (
        <>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="display-4 titulo">Cadastrar Usuário</h2>
                </div>
            </div>
            <hr />
            <ModalErro isOpen={formularioErro} toggle={() => fecharFormularioErro()} erro={erro} />
            <ModalAtencao isOpen={formularioAtencao} toggle={() => fecharFormularioAtencao()} atencao={atencao} />
            <ModalSucesso isOpen={formularioSucesso} toggle={() => fecharFormularioSucesso()} sucesso={sucesso} />
            <div className="form-group row">
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="name">Nome</Label>
                        <Input
                            type="text"
                            value={nome}
                            name="nome"
                            id="nome"
                            placeholder="Nome completo do usuário"
                            autoComplete="nome"
                            onChange={(ev) => setNome(ev.target.value)} />
                    </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="email">E-mail</Label>
                        <Input
                            type="email"
                            value={email}
                            name="email"
                            id="email"
                            placeholder="Melhor e-mail do usuário"
                            autoComplete="email"
                            onChange={(ev) => setEmail(ev.target.value)} />
                    </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label for="senha">Senha</Label>
                        <Input
                            type="password"
                            value={senha}
                            name="senha"
                            id="senha"
                            placeholder="Informe a senha"
                            autoComplete="senha"
                            onChange={(ev) => setSenha(ev.target.value)} />
                    </FormGroup>
                </div>
            </div>

            <Link onClick={() => cadastrarUsuario()} to="#">
                <BotaoConfirmar aguardando={aguardando} />
            </Link>
            <Link to={"/sac/usuarios"}>
                <BotaoVoltar />
            </Link>

        </>
    )
}

export default connect(null, actionsUsuario)(UsuarioCadastrar);