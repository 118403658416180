import React from "react";
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
// import { EnterClick } from '../../hooks/EnterClick';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import BotaoOk from "../BotaoOk";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/liberaExterno.css";

const ModalAtencao = (props) => {
  // EnterClick(props.toggle, 'Enter')

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={css(styles.container)}
        centered
      >
        <ModalHeader toggle={props.toggle}>Atenção</ModalHeader>
        <ModalBody>
          <FormGroup className="row mb-0">
            <div className="col-sm-12">
              <Alert color="warning">
                <WarningRoundedIcon
                  style={{ color: "#F79400", fontSize: 45 }}
                />
                <span className="ml-2">{props.atencao.mensagem}</span>
              </Alert>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BotaoOk className="m-1" onClickOk={props.toggle} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAtencao;

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    fontSize: 15,
  },
});
