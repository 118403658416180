import React from 'react';
import { HashRouter, Switch, Route, Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store';
import { history } from '../history';
//Containers
import containerLogin from '../containers/login';
import painelDeControle from '../containers/painelDeControle';
//Páginas
import CriarConta from '../../pages/usuario/CriarConta';
import Login from '../../pages/usuario/Login';
import Perfil from '../../pages/usuario/Perfil';
import AtendimentoCadastrar from '../../pages/Atendimento/Cadastrar';
import AtendimentoAlterar from '../../pages/Atendimento/Alterar';
import AtendimentosListar from '../../pages/Atendimento/Listar';
import PainelDeControle from '../../pages/PainelDeControle';
import PaginaInexistente from '../../pages/PaginaInexistente';
import PaginaInicial from '../../pages/PaginaInicial';
import Dispositivos from '../../pages/Dispositivos';
import RecuperarSenha from '../../pages/usuario/RecuperarSenha';
import UsuarioAlterar from '../../pages/usuario/Alterar';
import UsuarioAlterarPerfil from '../../pages/usuario/AlterarPerfil';
import UsuarioAlterarFotoPerfil from '../../pages/usuario/AlterarFotoPerfil';
import UsuarioAlterarSenha from '../../pages/usuario/AlterarSenha';
import UsuarioCadastrar from '../../pages/usuario/Cadastrar';
import UsuarioNovaSenha from '../../pages/usuario/NovaSenha';
import UsuarioVisualizar from '../../pages/usuario/Visualizar';
import UsuariosListar from '../../pages/usuario/Listar';
import NaturezasListar from '../../pages/Naturezas/Listar'
import NaturezasCadastrar from '../../pages/Naturezas/Cadastrar'
import NaturezasAlterar from '../../pages/Naturezas/Alterar'
import AplicacoesListar from '../../pages/Aplicacoes/Listar'
import AplicacoesCadastrar from '../../pages/Aplicacoes/Cadastrar'
import AplicacoesAlterar from '../../pages/Aplicacoes/Alterar'
import ContratosCadastrar from '../../pages/Contratos/Cadastrar'
import ContratosListar from '../../pages/Contratos/Listar'
import ContratosAlterar from '../../pages/Contratos/Alterar'
import ChaveLiberacao from '../../pages/Liberacao'
import LiberacaoExterna from '../../pages/LiberacaoExterna'
import ListarClientes from '../../pages/Cliente/Listar'
import ClienteCadastrar from './../../pages/Cliente/Cadastrar/index';
import ClienteAlterar from './../../pages/Cliente/Alterar/index';
import Associacoes from '../../pages/Associacoes';
import ChavesGeradas from '../../pages/ChavesGeradas/Listar';
import ChavesGeradasInfo from '../../pages/ChavesGeradas/Info'
import ListarGrupos from './../../pages/Grupos/Listar';
import GrupoCadastrar from '../../pages/Grupos/Cadastrar'
import GrupoAlterar from './../../pages/Grupos/Alterar';

const Routes = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <HashRouter>
                    <Switch>
                        {/* Páginas sem autenticação */}
                        <Route path="/codigo-liberacao" exact component={containerLogin(LiberacaoExterna)}/>
                        <Route path="/" exact component={containerLogin(Login)} />
                        <Route path="/sac/recuperar-senha" exact component={containerLogin(RecuperarSenha)} />
                        <Route path="/sac/criar-conta" exact component={containerLogin(CriarConta)} />
                        <Route path="/sac/nova-senha/:recuperarSenha" exact component={containerLogin(UsuarioNovaSenha)} />
                        {/* Páginas com autenticação */}
                        <Route path="/sac/painel-de-controle" exact component={painelDeControle(PainelDeControle)} />
                        <Route path="/sac/pagina-inicial" exact component={painelDeControle(PaginaInicial)} />
                        <Route path="/sac/dispositivos" exact component={painelDeControle(Dispositivos)} />
                        <Route path="/sac/perfil" exact component={painelDeControle(Perfil)} />
                        <Route path="/sac/atendimentos" exact component={painelDeControle(AtendimentosListar)} />
                        <Route path="/sac/atendimentos-cadastrar" exact component={painelDeControle(AtendimentoCadastrar)} />                        
                        <Route path="/sac/atendimentos-alterar/:id" exact component={painelDeControle(AtendimentoAlterar)} />
                        <Route path="/sac/usuarios" exact component={painelDeControle(UsuariosListar)} />                        
                        <Route path="/sac/usuarios-alterar/:id" exact component={painelDeControle(UsuarioAlterar)} />
                        <Route path="/sac/usuarios-alterar-perfil" exact component={painelDeControle(UsuarioAlterarPerfil)} />
                        <Route path="/sac/usuarios-alterar-foto" exact component={painelDeControle(UsuarioAlterarFotoPerfil)} />
                        <Route path="/sac/usuarios-alterar-senha" exact component={painelDeControle(UsuarioAlterarSenha)} />
                        <Route path="/sac/usuarios-cadastrar" exact component={painelDeControle(UsuarioCadastrar)} />
                        <Route path="/sac/usuarios-visualizar/:id" exact component={painelDeControle(UsuarioVisualizar)} />
                        <Route path="/sac/pagina-inexistente" exact component={PaginaInexistente} />
                        <Route path="/sac/naturezas" exact component={painelDeControle(NaturezasListar)} />
                        <Route path="/sac/naturezas-cadastrar" exact component={painelDeControle(NaturezasCadastrar)} />
                        <Route path="/sac/naturezas-alterar/:id" exact component={painelDeControle(NaturezasAlterar)} />
                        <Route path="/sac/aplicacoes" exact component={painelDeControle(AplicacoesListar)} />
                        <Route path="/sac/aplicacoes-cadastrar" exact component={painelDeControle(AplicacoesCadastrar)} />
                        <Route path="/sac/aplicacoes-alterar/:id" exact component={painelDeControle(AplicacoesAlterar)} />
                        <Route path="/sac/contratos-cadastrar" exact component={painelDeControle(ContratosCadastrar)} />
                        <Route path="/sac/contratos" exact component={painelDeControle(ContratosListar)} />
                        <Route path="/sac/contratos-alterar/:id" exact component={painelDeControle(ContratosAlterar)} />
                        <Route path="/sac/liberacao" exact component={painelDeControle(ChaveLiberacao)} />
                        <Route path="/sac/clientes" exact component={painelDeControle(ListarClientes)} />
                        <Route path="/sac/clientes-cadastrar" exact component={painelDeControle(ClienteCadastrar)} />
                        <Route path="/sac/clientes-alterar/:id" exact component={painelDeControle(ClienteAlterar)} />
                        <Route path="/sac/associacoes" exact component={painelDeControle(Associacoes)} />
                        <Route path="/sac/chaves-geradas" exact component={painelDeControle(ChavesGeradas)} />
                        <Route path="/sac/chaves-geradas-info/:id" exact component={painelDeControle(ChavesGeradasInfo)} />
                        <Route path="/sac/grupos" exact component={painelDeControle(ListarGrupos)} />
                        <Route path="/sac/grupos-cadastrar" exact component={painelDeControle(GrupoCadastrar)} />
                        <Route path="/sac/grupos-alterar/:id" exact component={painelDeControle(GrupoAlterar)} />
                        <Redirect from="*" to="/sac/pagina-inexistente" />
                    </Switch>
                </HashRouter>
            </Router>
        </Provider>
    );
}

export default Routes;