import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsGrupos from "../../../domain/actions/actionsGrupos";
import { FormGroup } from "reactstrap";
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';

// import AlertaErro from "../../../components/AlertaErro";
import AlertaSucesso from "../../../components/AlertaSucesso";
import BotaoCadastrar from "../../../components/BotaoCadastrar";
import ModalErroRetorna from "../../../components/ModalErroRetorna";

import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";

class ListarGrupos extends Component {
  state = {
    id: "",
    nome: "",
    valorInadimplencia: "",
    dataInadimplencia: '',
    mensagem: "",
    erro: "",
    aguardando: false,
    formularioErro: null,
  };

  //Início da rotina de pesquisa
  pesquisarGrupos() {
    this.props.pesquisarGrupos((retorno) => {
      if (retorno.erro.mensagem) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ formularioErro: true });
      } else if (this.props.location.state) {
        this.setState({ mensagem: this.props.location.state.mensagem });
      }
    });
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  fecharFormularioErro() {
    this.setState({ formularioErro: false });
  };

  componentDidMount() {
    this.pesquisarGrupos();
  }

  componentDidUpdate(nextProps) {
    if (!this.props.gruposPesquisados && nextProps.gruposPesquisados) {
      this.pesquisarGrupos();
    }
  }

  componentWillUnmount() {
    actionsGrupos.limparGrupo();
    actionsGrupos.limparGrupos();
  }

  render() {
    const {
      nome,
      mensagem,
      erro,
      aguardando,
      formularioErro,
    } = this.state;
    var grupos = [];
    if (this.props.gruposPesquisados) {
      grupos = this.props.gruposPesquisados;
    }

    var qtde = grupos.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        <ModalErroRetorna
          erro={{ mensagem: erro }}
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
        />
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Grupos</h2>
          </div>
          <div className="ml-1">
            <FormGroup>
              <BotaoCadastrar
                uri="/sac/grupos-cadastrar"
                descricaoObjeto="Grupo"
              ></BotaoCadastrar>
            </FormGroup>
          </div>
          {/* {erro && <ModalErro erro={{ mensagem: erro }} />} */}
          {mensagem && <AlertaSucesso sucesso={{ mensagem: mensagem }} />}
        </div>
        <hr />

        {/* {!this.props.aplicacoesPesquisados && (
          <ModalErro isOpen={true} erro={{ mensagem: erro }}></ModalErro>
        )} */}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Nome</Th>
            </Tr>
          </Thead>
          <Tbody>
            {grupos.map((grupo) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={grupo.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{grupo.id}</Td>
                  <Td>
                    <Link to={`/sac/grupos-alterar/${grupo.id}`}>
                      {grupo.nome}
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  gruposPesquisados: state.grupo.gruposPesquisados,
});

export default connect(mapStateToProps, actionsGrupos)(ListarGrupos);
