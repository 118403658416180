import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsChavesGeradas from "../../../domain/actions/actionsChavesGeradas";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoVoltar from "../../../components/BotaoVoltar";

const ChavesGeradasInfo = (props) => {
  const [numeroSerie, setNumeroSerie] = useState("");
  const [chaveLiberacao, setChaveLiberacao] = useState("");

  useEffect(() => {
    const { id } = props.match.params;    
    getChaveGerada(id);
  }, []);

  const getChaveGerada = (id) => {
    props.getChaveGerada(id);
  };

  function receberChavesGeradas() {
    const { id } = props.match.params;
    if (
      typeof props.chaveGerada !== "undefined" &&
      props.chaveGerada !== null &&
      props.chaveGerada.id == id
    ) {
      setNumeroSerie(props.chaveGerada.numeroSerie);
      setChaveLiberacao(props.chaveGerada.chaveLiberacao);
    }
  }

  useEffect(() => {
    const { id } = props.match.params; 
  
    if (!props.chaveGerada) {
      props.getChaveGerada(id);     
    }

    receberChavesGeradas();
  }, [props.chaveGerada]);

  return (
    <div>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Chaves Geradas</h2>
        </div>
      </div>
      <hr />
      <div className="form-group row">
        <div className="col-sm-12">
          <FormGroup>
            <Label for="numeroSerie">Número de Série</Label>
            <Input
              type="text"
              value={numeroSerie}
              name="numeroSerie"
              id="numeroSerie"
              className="form-control"
              disabled={true}
            />
          </FormGroup>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-12">
          <FormGroup>
            <Label for="chaveLiberacao">Chave de Liberação</Label>
            <Input
              type="text"
              value={chaveLiberacao}
              name="chaveLiberacao"
              id="chaveLiberacao"
              className="form-control"
              disabled={true}
            />
          </FormGroup>
        </div>
      </div>
      <Link to={"/sac/chaves-geradas"}>
        <BotaoVoltar />
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chaveGerada: state.chavesGeradas.chaveGerada,
});

export default connect(
  mapStateToProps,
  actionsChavesGeradas
)(ChavesGeradasInfo);
