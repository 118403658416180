import { combineReducers } from 'redux';
import reducersUsuario from '../../domain/reducers/reducersUsuario';
import reducersNatureza from '../../domain/reducers/reducersNatureza';
import reducersCliente from '../../domain/reducers/reducersCliente';
import reducersAtendimento from '../../domain/reducers/reducersAtendimento';
import reducersStatusAtendimento from '../../domain/reducers/reducersStatusAtendimento';
import reducersAplicacao from '../../domain/reducers/reducersAplicacao';
import reducersContrato from '../../domain/reducers/reducersContrato';
import reducersLiberacao from '../../domain/reducers/reducersLiberacao'
import reducersDispositivo from '../../domain/reducers/reducersDispositivo';
import reducersGrupos from '../../domain/reducers/reducersGrupos';
import reducersPermissoes from './../../domain/reducers/reducersPermissoes';
import reducersChavesGeradas from '../../domain/reducers/reducersChavesGeradas';

const reducer = combineReducers({
    usuario: reducersUsuario,
    natureza: reducersNatureza,
    cliente: reducersCliente,
    atendimento: reducersAtendimento,
    statusAtendimento: reducersStatusAtendimento,
    aplicacao: reducersAplicacao,
    contrato: reducersContrato,
    chaveLiberacao : reducersLiberacao,
    dispositivo: reducersDispositivo,
    grupo: reducersGrupos,
    permissoes: reducersPermissoes,
    chavesGeradas: reducersChavesGeradas
});

export default reducer;