import React from "react";
import { Button, Spinner } from "reactstrap";
import SendIcon from "@mui/icons-material/Send";

const BotaoEnviar = (props) => {
  if (props.aguardando) {
    return (
      <>
        <Button id="idEnviar" className="btn-sm botao-primario" disabled>
          Aguarde
          <Spinner className="ml-1" size="sm" color="light" />
        </Button>
      </>
    );
  }

  return (
    <>
      <Button id="idEnviar" className="btn-sm botao-primario">
        <SendIcon />
        <span className="ml-1">Enviar</span>
      </Button>
    </>
  );
};

export default BotaoEnviar;
