import {
  BUSCAR_GRUPO,
  GRUPOS_TODOS,
  LIMPAR_GRUPO,
  LIMPAR_GRUPOS,
  LISTAR_GRUPOS,
  PESQUISAR_GRUPOS,
} from "../../core/store/types";

const reducersGrupos = (state = {}, action) => {
  switch (action.type) {
    case GRUPOS_TODOS:
      return {
        ...state,
        grupos: action.payload,
      };
    case BUSCAR_GRUPO:
      return {
        ...state,
        grupo: action.payload,
      };
    case LIMPAR_GRUPO:
      return {
        ...state,
        grupo: null,
      };
    case LIMPAR_GRUPOS:
      return {
        ...state,
        grupos: null,
        gruposPesquisados: null,
      };
    case LISTAR_GRUPOS:
      return {
        ...state,
        grupos: action.payload._embedded.grupos,
      };
    case PESQUISAR_GRUPOS:
      return {
        ...state,
        gruposPesquisados: action.payload._embedded.grupos,
      };
    default:
      return state;
  }
};

export default reducersGrupos;
