import React from "react";
import { Button, Spinner } from "reactstrap";
import CheckIcon from "@mui/icons-material/Check";

const BotaoAlterarSenha = (props) => {
  if (props.aguardando) {
    return (
      <>
        <Button id="idAlterarSenha" className="btn-sm botao-primario" disabled>
          Aguarde
          <Spinner className="ml-1" size="sm" color="light" />
        </Button>
      </>
    );
  }

  return (
    <>
      <Button id="idAlterarSenha" className="mr-1 btn-sm botao-primario">
        <CheckIcon />
        <span> Alterar Senha</span>
      </Button>
    </>
  );
};

export default BotaoAlterarSenha;
