import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsClientes from "../../../domain/actions/actionsClientes";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";

class ClienteAlterar extends Component {
  state = {
    id: "",
    nome: "",
    nomeReduzido: "",
    email: "",
    valorInadimplencia: "",
    dataInadimplencia: "",
    erro: "",
    sucesso: "",
    atencao: "",
    aguardando: false,
    dadosNatureza: false,
    formularioPronto: false,
    formularioSucesso: false,
    formularioAtencao: false,
    formularioErro: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCliente(id);
  }

  async componentDidUpdate(nextProps) {
    const { id } = this.props.match.params;
    if (!this.props.cliente && nextProps.cliente) this.props.getCliente(id);
    await this.receberDadosCliente();
  }

  receberDadosCliente() {
    const { id } = this.props.match.params;

    if (
      typeof this.props.cliente !== "undefined" &&
      this.props.cliente !== null &&
      this.props.cliente.id == id &&
      !this.state.dadosCliente
    ) {
      this.setState({ id: this.props.cliente.id });
      this.setState({ nome: this.props.cliente.nome });
      this.setState({ nomeReduzido: this.props.cliente.nomeReduzido });
      this.setState({ email: this.props.cliente.email });
      this.setState({
        valorInadimplencia: this.props.cliente.valorInadimplencia,
      });
      // console.log(this.props.cliente.valorInadimplencia);
      this.setState({
        dataInadimplencia: this.props.cliente.dataInadimplencia,
      });
      // console.log(this.props.cliente.dataInadimplencia);
      this.setState({ dadosCliente: true });
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  onlyNumbers = (field, ev) => {
    this.setState({ [field]: ev.target.value.replace(/\D/g, "") });
  };

  async alterarCliente() {
    this.setState({ erro: "" });
    await this.receberDadosFormulario();

    this.setState({ formularioAtencao: !this.criticas() });
    if (!this.criticas()) return;

    this.setState({ aguardando: true });
    const {
      id,
      nome,
      nomeReduzido,
      email,
      valorInadimplencia,
      dataInadimplencia,
    } = this.state;
    console.log(this.state);

    this.props.alterarCliente(
      { id, nome, nomeReduzido, email, valorInadimplencia, dataInadimplencia },
      (err) => {
        if (err.erro.mensagem) {
          this.setState({ erro: { mensagem: err.erro.mensagem } });
          this.setState({ aguardando: false });
          this.setState({ formularioErro: true });
        } else {
          this.setState({ aguardando: false });
          this.setState({
            sucesso: { mensagem: "Aplicação alterada com sucesso!" },
          });
          this.setState({ formularioSucesso: true });
        }
      }
    );
  }

  receberDadosFormulario() {
    // this.setState({ id: document.querySelector("#id").value });
    this.setState({ nome: document.querySelector("#nome").value });
    this.setState({
      nomeReduzido: document.querySelector("#nomeReduzido").value,
    });
    this.setState({ email: document.querySelector("#email").value });
    this.setState({
      valorInadimplencia: document.querySelector("#valorInadimplencia").value,
    });
    this.setState({
      dataInadimplencia: document.querySelector("#dataInadimplencia").value,
    });
  }

  criticas() {
    const { nome, nomeReduzido, email, valorInadimplencia, dataInadimplencia } =
      this.state;
    if (!nome)
      return this.setState({ atencao: { mensagem: "Preencha o campo nome!" } });
    if (!nomeReduzido)
      return this.setState({
        atencao: { mensagem: "Preencha o campo nome reduzido!" },
      });
    if (!email)
      return this.setState({
        atencao: { mensagem: "Preencha o campo e-mail!" },
      });
    // if (!valorInadimplencia)
    //   return this.setState({
    //     atencao: { mensagem: "Preencha o campo valor de inadimplência!" },
    //   });
    if (valorInadimplencia > 0 && !dataInadimplencia)
      return this.setState({
        atencao: { mensagem: "Preencha o campo data da inadimplência!" },
      });
    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  fecharFormularioSucesso = () => {
    this.setState({ formularioPronto: true });
  };

  render() {
    const {
      id,
      nome,
      nomeReduzido,
      email,
      valorInadimplencia,
      dataInadimplencia,
      aguardando,
      erro,
      sucesso,
      atencao,
      dadosCliente,
      formularioPronto,
      formularioAtencao,
      formularioErro,
      formularioSucesso,
    } = this.state;

    if (formularioPronto) {
      return <Redirect to={{ pathname: "/sac/clientes" }} />;
    }

    let data = "";

    if (dataInadimplencia != null) {
      const defaultData = dataInadimplencia.substring(0, 10);
      data = defaultData;
    } else {
      data = dataInadimplencia;
    }
    // console.log(data);

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Cliente</h2>
          </div>
        </div>
        <hr />
        <ModalErro
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
          erro={erro}
        ></ModalErro>
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        >
          {" "}
        </ModalAtencao>
        <ModalSucesso
          isOpen={formularioSucesso}
          toggle={() => this.fecharFormularioSucesso()}
          sucesso={sucesso}
        >
          {" "}
        </ModalSucesso>
        <div className="form-group row">
          <div className="col-sm-6">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                className="form-control"
                placeholder={dadosCliente ? "Nome do Cliente" : "Carregado..."}
                disabled={dadosCliente ? false : true}
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-6">
            <FormGroup>
              <Label for="nomeReduzido">Nome reduzido</Label>
              <Input
                type="text"
                value={nomeReduzido}
                name="nomeReduzido"
                id="nomeReduzido"
                className="form-control"
                placeholder={dadosCliente ? "Nome reduzido" : "Carregado..."}
                disabled={dadosCliente ? false : true}
                autoComplete="nomeReduzido"
                onChange={(ev) => this.onChangeInput("nomeReduzido", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-6">
            <FormGroup>
              <Label for="email">E-mail</Label>
              <Input
                type="text"
                value={email}
                name="email"
                id="email"
                className="form-control"
                placeholder={
                  dadosCliente ? "E-mail do Cliente" : "Carregado..."
                }
                disabled={dadosCliente ? false : true}
                autoComplete="email"
                onChange={(ev) => this.onChangeInput("email", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="valorInadimplencia">Valor de Inadimplência</Label>
              <Input
                type="number"
                min="0.01"
                value={valorInadimplencia}
                name="valorInadimplencia"
                id="valorInadimplencia"
                className="form-control"
                placeholder={
                  dadosCliente ? "Valor de Inadiplência" : "Carregado..."
                }
                disabled={dadosCliente ? false : true}
                autoComplete="valorInadimplencia"
                onChange={(ev) => this.onChangeInput("valorInadimplencia", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="dataInadimplencia">Data da Inadimplência</Label>
              <Input
                type="date"
                // value={dataInadimplencia}
                value={data}
                name="dataInadimplencia"
                id="dataInadimplencia"
                className="form-control"
                placeholder={
                  dadosCliente ? "Data da Inadiplência" : "Carregado..."
                }
                disabled={dadosCliente ? false : true}
                autoComplete="dataInadimplencia"
                onChange={(ev) => this.onChangeInput("dataInadimplencia", ev)}
              />
            </FormGroup>
          </div>
        </div>
        <Link onClick={() => this.alterarCliente()} to="#">
          <BotaoConfirmar aguardando={aguardando} />
        </Link>
        <Link to={"/sac/clientes"}>
          <BotaoVoltar />
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cliente: state.cliente.cliente,
});

export default connect(mapStateToProps, actionsClientes)(ClienteAlterar);
