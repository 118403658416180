import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionsDispositivos from '../../domain/actions/actionsDispositivos';
import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem
} from 'reactstrap';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import BotaoPesquisar from '../../components/BotaoPesquisar';
import BotaoAtivar from "../../components/BotaoAtivar";
import BotaoDesativar from "../../components/BotaoDesativar";
import ModalApagar from '../../components/ModalApagar';
import ModalAtencao from '../../components/ModalAtencao';
import ModalAtivar from '../../components/ModalAtivar';
import ModalDesativar from '../../components/ModalDesativar';
import ModalCarregando from '../../components/ModalCarregando';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite'

//style
import '../../assets/styles/listarPagination.scss'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../../assets/styles/tabela.css';

class ListarDispositivos extends Component {

  state = {
    nomeComputador: '',
    idCliente: '',
    mac: '',
    mensagem: "",
    erro: "",
    atencao: "",
    aguardando: false,
    confirmarExclusao: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioAtencao: false,
    page: 0,
    numPages: 0
  }

  //Início rotina de exclusão
  abrirConfirmarExclusao(id) {
    this.setState({ confirmarExclusao: true });
    this.setState({ idParaExcluir: id });
  }

  fecharConfirmarExclusao() {
    this.setState({ confirmarExclusao: false })
  }

  apagarDispositivo() {
    this.setState({ aguardando: true });
    this.props.removerDispositivo(this.state.idParaExcluir, (retorno) => {
      if (retorno.erro.erro) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ aguardando: false });
      } else {
        this.setState({ mensagem: retorno.erro.mensagem });
        this.setState({ aguardando: false });
        this.fecharConfirmarExclusao();
        this.props.limparDispositivos();
        this.pesquisarDispositivos();
      }
    })
  }
  //Fim rotina de exclusão

  //Início rotina de ativação
  ativarDispositivo() {
    this.setState({ aguardando: true });
    this.props.ativarDispositivo({ id: this.state.idParaAtivar, ativo: true }, (retorno) => {
      if (retorno.erro.erro) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ aguardando: false });
      } else {
        this.setState({ mensagem: retorno.erro.mensagem });
        this.setState({ aguardando: false });
        this.fecharConfirmarAtivacao();
        this.props.limparDispositivos();
        this.pesquisarDispositivos();
      }
    });
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false })
  }
  //Fim rotina de ativação

  //Início rotina de desativação
  desativarDispositivo() {
    this.setState({ aguardando: true });
    this.props.desativarDispositivo({ id: this.state.idParaDesativar, ativo: false }, (retorno) => {
      if (retorno.erro.erro) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ aguardando: false });
      } else {
        this.setState({ mensagem: retorno.erro.mensagem });
        this.setState({ aguardando: false });
        this.fecharConfirmarDesativacao();
        this.pesquisarDispositivos();
        this.props.limparDispositivos();
      }
    });
  }

  criticas() {
    const { dataInicial, dataFinal } = this.state;

    return true;
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false })
  }
  //Fim rotina de desativação

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  }

  //Início da rotina de pesquisa
  pesquisarDispositivos() {
    const { mac, nomeComputador, idCliente, page } = this.state;

    if (this.props.location.state) {
      this.props.pesquisarDispositivos({ mac, nomeComputador, clienteId: idCliente, page });
    }
    else {
      this.props.pesquisarDispositivos({ mac, nomeComputador, clienteId: idCliente });
    }
  }

  pesquisarDispositivosBotao() {
    const { mac, nomeComputador, idCliente, page } = this.state;
    this.props.pesquisarDispositivosBotao({ mac, nomeComputador, clienteId: idCliente, page });
  }

  paginationHandler = () => {
    var pages = 1
    if (this.props.dispositivosPesquisados) pages = this.props.dispositivosPesquisados.page.totalPages
    var curPages = []
    for (let i = 0; i < pages; i++) {
      curPages.push(i)
    }
    return curPages.map((page) => {
      return (
        <PaginationItem
          active={this.state.page === page ? true : false}
          className={'paginationItemStyle'}
        >
          <PaginationLink
            className={'paginationLinkStyle'}
            onClick={() => this.setState({ page: page }, this.pesquisarDispositivosBotao)}
          >
            {page + 1}
          </PaginationLink>
        </PaginationItem>
      )
    })

  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  }
  //Fim da rotina de pesquisa    

  componentDidMount() {
    const { location } = this.props;
    location.state ? this.pesquisarDispositivos(location.state.statusId) : this.pesquisarDispositivos('');
    this.props.pesquisarClientes();
  }

  componentDidUpdate(nextProps, prevState) {
    if (this.state.clienteNome !== '' && prevState.clienteNome !== this.state.clienteNome) {
      this.pesquisarDispositivosBotao('');
    } else {
      if (!this.props.dispositivosPesquisados && nextProps.dispositivosPesquisados) this.pesquisarDispositivosBotao('');
    }
  }

  componentWillUnmount() {
    this.props.limparDispositivos();
    this.props.limparDispositivo();
  }

  render() {
    const { nomeComputador, idCliente, mac, dataInicial, dataFinal, mensagem, atencao, aguardando, confirmarExclusao, confirmarAtivacao, confirmarDesativacao, formularioAtencao } = this.state;
    
    var numPages = 1
    if (this.props.dispositivosPesquisados && this.props.dispositivosPesquisados.page.totalPages) numPages = this.props.dispositivosPesquisados.page.totalPages

    var clientes = [];
    if (this.props.clientesPesquisados)
      clientes = this.props.clientesPesquisados;

    var dispositivos = [];
    var pages = 0

    if (this.props.dispositivosPesquisados && this.props.dispositivosPesquisados._embedded) {
      dispositivos = this.props.dispositivosPesquisados._embedded.dispositivos
    };
    var qtde = dispositivos.length
    if (qtde % 2 === 0) {
      qtde += 1
    }

    return (
      <>
        <ModalAtencao isOpen={formularioAtencao} toggle={() => this.fecharFormularioAtencao()} atencao={atencao} />
        {/* Component modal ativar */}
        <ModalAtivar isOpen={confirmarAtivacao} toggle={() => this.fecharConfirmarAtivacao()} ativar='dispositivo' aguardando={aguardando} ativarObjeto={() => this.ativarDispositivo()} />
        {/* Component modal desativar */}
        <ModalDesativar isOpen={confirmarDesativacao} toggle={() => this.fecharConfirmarDesativacao()} desativar='dispositivo' aguardando={aguardando} desativarObjeto={() => this.desativarDispositivo()} />
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Dispositivos</h2>
          </div>
          <div >
            <FormGroup>
              <BotaoPesquisar onClickPesquisar={() => {
                this.setState({ formularioAtencao: !this.criticas() });

                {
                  if (this.criticas()) {
                    this.setState({ page: 0 });
                    this.props.limparDispositivos();
                    this.pesquisarDispositivosBotao('');
                  }
                }
              }} />
            </FormGroup>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-sm-4">
            <FormGroup>
              <Label for="cliente">Cliente</Label>
              <Input
                type="select"
                name="cliente"
                id="cliente"
                className="form-control"
                onChange={(ev) => this.onChangeInput("idCliente", ev)}
              >
                <option value="">{""}</option>
                {clientes.map((cliente) => (
                  <option value={cliente.id}>{cliente.nomeReduzido}</option>
                ))}
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="mac">Mac</Label>
              <Input
                id="mac"
                name="mac"
                onChange={(ev) => this.onChangeInput("mac", ev)}
                type="text"
                value={mac}
                autoComplete="mac"
                placeholder="Filtrar por Mac" />
            </FormGroup>
          </div>
          <div className="col-sm-4">
            <FormGroup>
              <Label for="nomeComputador">Nome Computador</Label>
              <Input
                id="nomeComputador"
                name="nomeComputador"
                onChange={(ev) => this.onChangeInput("nomeComputador", ev)}
                type="text"
                value={nomeComputador}
                autoComplete="nomeComputador"
                placeholder="Filtrar por Nome Compuatador" />
            </FormGroup>
          </div>
        </div>
        {!this.props.dispositivosPesquisados && <ModalCarregando isOpen={true} pagina='Listagem de dispositivos' />}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th className="text-center">Mac</Th>
              <Th >Nome Computador</Th>
              <Th >Cliente</Th>
              <Th className="text-center">Contrato</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              dispositivos.map(
                (dispositivo) => {
                  var parity = qtde % 2
                  qtde = qtde - 1

                  return (
                    <Tr key={dispositivo.id} className={parity === 1 ? "odd_row" : "even_row"}>
                      <Td className="text-center">{dispositivo.id}</Td>
                      <Td className="text-center">{dispositivo.mac}</Td>
                      <Td >{dispositivo.nomeComputador}</Td>
                      <Td >{dispositivo.contrato.cliente.nomeReduzido}</Td>
                      <Td className="text-center">{dispositivo.contrato.id}</Td>
                      <Td className="text-center">
                        <span>
                          {dispositivo.ativo ? (
                            <BotaoDesativar
                              onClick={() =>
                                this.abrirConfirmarDesativacao(dispositivo.id)
                              }
                            />
                          ) : (
                            <BotaoAtivar
                              onClick={() =>
                                this.abrirConfirmarAtivacao(dispositivo.id)
                              }
                            />
                          )}
                        </span>
                      </Td>
                    </Tr>

                  )
                }
              )
            }
          </Tbody>
        </Table>
        <br />
        {this.props.dispositivosPesquisados ?
          <div>
            <Pagination
              className={css(styles.paginationContainer)}
            >
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  first
                  className={'paginationLinkStyle'}
                  onClick={() => {
                    this.setState({ page: 0 }, this.pesquisarDispositivosBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page === 0 || this.state.page === '0' ? true : false}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  previous
                  onClick={() => {
                    this.setState({ page: this.state.page - 1 }, this.pesquisarDispositivosBotao)
                  }}
                />
              </PaginationItem>
              {
                this.paginationHandler()
              }
              <PaginationItem
                className={'paginationItemStyle'}
                disabled={this.state.page !== numPages - 1 ? false : true}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  next
                  onClick={() => {
                    this.setState({ page: this.state.page + 1 }, this.pesquisarDispositivosBotao)
                  }}
                />
              </PaginationItem>
              <PaginationItem
                className={'paginationItemStyle'}
              >
                <PaginationLink
                  className={'paginationLinkStyle'}
                  last
                  onClick={() => {
                    this.setState({ page: numPages - 1 }, this.pesquisarDispositivosBotao)
                  }}
                />
              </PaginationItem>
            </Pagination>
          </div>
          : false
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  }
})


const mapStateToProps = (state) => ({
  clientesPesquisados: state.cliente.clientesPesquisados,
  dispositivosPesquisados: state.dispositivo.dispositivosPesquisados
});

export default connect(mapStateToProps, actionsDispositivos)(ListarDispositivos);