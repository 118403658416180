import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo_sistema from '../../../assets/images/logo_sistema.png'
import { Form, FormGroup, Input } from 'reactstrap'
// import AlertaSucesso from '../../../components/AlertaSucesso'
import BotaoLogin from '../../../components/BotaoLogin'
import ModalAtencao from '../../../components/ModalAtencao'
import ModalErro from '../../../components/ModalErro'
// import ModalSucesso from '../../../components/ModalSucesso'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [atencao, setAtencao] = useState('')
  const [sucesso, setSucesso] = useState('')
  const [erro, setErro] = useState('')
  const [aguardando, setAguardando] = useState(false)
  const [formularioAtencao, setFormularioAtencao] = useState(null)
  const [formularioErro, setFormularioErro] = useState(null)
  const [formularioSucesso, setFormularioSucesso] = useState(false)

  //Ao renderizar componente
  useEffect(() => {
    const { location } = props

    if (location.state) {
      setSucesso(location.state.msgSucesso)
      setFormularioSucesso(location.state.sucesso)
      // setAtencao(location.state.atencao);
    }
    // eslint-disable-next-line
  }, [])

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false)
  }

  const fecharFormularioErro = () => {
    setFormularioErro(false)
  }

  const login = (e) => {
    e.preventDefault()
    setFormularioAtencao(!criticas())
    if (!criticas()) return
    setAguardando(true)
    props.handleLogin({ email, senha }, (retorno) => {
      if (retorno.erro.mensagem) {
        setErro({ mensagem: retorno.erro.mensagem })
        setFormularioErro({ mensagem: retorno.erro.mensagem })
        setAguardando(false)
      } else {
        setErro('')
        setAguardando(false)
      }
    })
  }

  const criticas = () => {
    if (!email) return setAtencao({ mensagem: 'Preencha o campo e-mail!' })
    if (!senha) return setAtencao({ mensagem: 'Preencha o campo senha!' })

    return true
  }

  return (
    <>
      <div className="container-login">
        <div className="login card shadow">
          <Form onSubmit={login} className="form-signin text-center">
            <img
              className="mb-4"
              src={logo_sistema}
              alt="SAC"
              width="72"
              height="72"
            />
            <h1 className="h3 mb-3 font-weight-normal">S.A.C</h1>
            <ModalErro
              isOpen={formularioErro}
              toggle={() => fecharFormularioErro()}
              erro={erro}
            />
            <ModalAtencao
              isOpen={formularioAtencao}
              toggle={() => fecharFormularioAtencao()}
              atencao={atencao}
            />
            {/* <ModalSucesso
              isOpen={formularioSucesso}
              toggle={() => setFormularioSucesso(!formularioSucesso)}
              sucesso={sucesso}
            /> */}

            <FormGroup>
              <Input
                style={{ height: 40 }}
                type="email"
                value={email}
                name="email"
                id="email"
                placeholder="E-mail do usuário"
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Input
                style={{ height: 40 }}
                type="password"
                value={senha}
                name="senha"
                id="senha"
                placeholder="Senha do usuário"
                onChange={(ev) => setSenha(ev.target.value)}
              />
            </FormGroup>

            <BotaoLogin aguardando={aguardando} />

            <p className="text-center mt-2">
              {/* <Link to="/sac/criar-conta">Cadastrar</Link> */}
              {/* {' - '} */}
              <Link to="/sac/recuperar-senha">Esqueceu a senha?</Link>
            </p>
          </Form>
        </div>
      </div>
    </>
  )
}

export default Login
