import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actionsAtendimento from "../../../domain/actions/actionsAtendimento";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

const AtendimentoCadastrar = (props) => {
  //const { usuario } = useSelector((state) => state);

  const [idUsuario, setIdUsuario] = useState(
    localStorage.getItem("usuario_id") || "1"
  );
  const [idNatureza, setIdNatureza] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [idSituacao, setIdSituacao] = useState("");
  const [solicitante, setSolicitante] = useState("");
  const [problema, setProblema] = useState("");
  const [solucao, setSolucao] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");
  const [atencao, setAtencao] = useState("");
  const [aguardando, setAguardando] = useState(false);
  const [formularioPronto, setFormularioPronto] = useState(false);

  const [formularioSucesso, setFormularioSucesso] = useState(false);
  const [formularioAtencao, setFormularioAtencao] = useState(null);
  const [formularioErro, setFormularioErro] = useState(null);

  //Ao montar/entrar componente
  useEffect(() => {
    setarUsuarioLogado();
    pesquisarUsuarios();
    pesquisarNaturezas();
    pesquisarClientes();
    pesquisarStatusAtendimento();
    pesquisarNaturezaPadrao();
    pesquisarStatusAtendimentoPadrao();
  }, []);

  //Ao desmontar/sair componente
  useEffect(() => {
    return props.limparUsuario();
  }, []);

  useEffect((props) => {
    console.log(props);
  }, []);

  const setarUsuarioLogado = () => {
    setIdUsuario(props.usuario.id);
  };

  const pesquisarUsuarios = () => {
    props.pesquisarUsuarios("", "");
  };

  const pesquisarNaturezas = () => {
    props.pesquisarNaturezas();
  };

  const pesquisarClientes = () => {
    props.pesquisarClientes();
  };

  const pesquisarStatusAtendimento = () => {
    props.pesquisarStatusAtendimento();
  };

  const pesquisarNaturezaPadrao = () => {
    var naturezasEncontradas = [];
    if (props.naturezasPesquisados)
      naturezasEncontradas = props.naturezasPesquisados;

    naturezasEncontradas.map((natureza) =>
      natureza.padrao ? setIdNatureza(natureza.id) : ""
    );
  };

  const pesquisarStatusAtendimentoPadrao = () => {
    var statusAtendimentoEncontrados = [];
    if (props.statusAtendimentoPesquisados)
      statusAtendimentoEncontrados = props.statusAtendimentoPesquisados;

    statusAtendimentoEncontrados.map((statusAtendimento) =>
      statusAtendimento.padrao ? setIdSituacao(statusAtendimento.id) : ""
    );
  };

  const fecharFormularioAtencao = () => {
    setFormularioAtencao(false);
  };

  const fecharFormularioErro = () => {
    setFormularioErro(false);
  };

  const fecharFormularioSucesso = () => {
    setFormularioPronto(true);
  };

  const cadastrarAtendimento = () => {
    setFormularioAtencao(!criticas());
    if (!criticas()) return;

    setAguardando(true);
    props.salvarAtendimento(
      {
        solicitante,
        problema,
        solucao,
        status: { id: idSituacao },
        cliente: { id: idCliente },
        natureza: { id: idNatureza },
      },
      (retorno) => {
        if (retorno.erro.mensagem) {
          setAtencao("");
          setSucesso("");
          setErro({ mensagem: retorno.erro.mensagem });
          setAguardando(false);
          setFormularioErro(true);
        } else {
          setAtencao("");
          setErro("");
          setSucesso({ mensagem: "Atendimento inserido com sucesso!" });
          setFormularioSucesso(true);
          setAguardando(false);
        }
      }
    );
  };

  const criticas = () => {
    if (idCliente === "0" || idCliente === "")
      return setAtencao({ mensagem: "O cliente deve ser escolhido." });
    if (!solicitante)
      return setAtencao({ mensagem: "Preencha o campo solicitante!" });

    return true;
  };

  if (formularioPronto) {
    return (
      <Redirect
        to={{
          pathname: "/sac/painel-de-controle",
        }}
      />
    );
  }

  var usuarios = [];
  if (props.usuariosPesquisados) usuarios = props.usuariosPesquisados;

  var naturezas = [];
  if (props.naturezasPesquisados) {
    naturezas = props.naturezasPesquisados;
    if (!idNatureza) {
      setIdNatureza(props.naturezasPesquisados[0].id);
    }
  }

  var clientes = [];
  if (props.clientesPesquisados) clientes = props.clientesPesquisados;

  var statusAtendimento = [];
  if (props.statusAtendimentoPesquisados) {
    statusAtendimento = props.statusAtendimentoPesquisados;
    if (!idSituacao) {
      setIdSituacao(props.statusAtendimentoPesquisados[0].id);
    }
  }

  return (
    <>
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="display-4 titulo">Cadastrar Atendimento</h2>
        </div>
      </div>
      <hr />
      {/* Component modal apagar */}
      <ModalErro
        isOpen={formularioErro}
        toggle={() => fecharFormularioErro()}
        erro={erro}
      />
      <ModalAtencao
        isOpen={formularioAtencao}
        toggle={() => fecharFormularioAtencao()}
        atencao={atencao}
      />
      <ModalSucesso
        isOpen={formularioSucesso}
        toggle={() => fecharFormularioSucesso()}
        sucesso={sucesso}
      />
      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="cliente">Cliente</Label>
            <Input
              onChange={(ev) => setIdCliente(ev.target.value)}
              type="select"
              name="cliente"
              id="cliente"
            >
              <option value="0">{""}</option>
              {clientes.map((cliente) => (
                <option value={cliente.id}>{cliente.nomeReduzido}</option>
              ))}
            </Input>
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="natureza">Natureza</Label>
            <Input
              value={idNatureza}
              onChange={(ev) => setIdNatureza(ev.target.value)}
              type="select"
              name="natureza"
              id="natureza"
            >
              {naturezas.map((natureza) => {
                if (natureza.ativo == true) {
                  return <option value={natureza.id}>{natureza.nome}</option>;
                }
              })}
            </Input>
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="tecnico">Técnico</Label>
            <Input
              value={idUsuario}
              onChange={(ev) => setIdUsuario(ev.target.value)}
              type="select"
              name="tecnico"
              id="tecnico"
            >
              {usuarios.map((usuario) => {
                if (usuario.ativo == true) {
                  return <option value={usuario.id}>{usuario.nome}</option>;
                }
              })}
            </Input>
          </FormGroup>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="solicitante">Solicitante</Label>
            <Input
              type="text"
              value={solicitante}
              name="solicitante"
              id="solicitante"
              onChange={(ev) => setSolicitante(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <FormGroup>
            <Label for="situacao">Situação</Label>
            <Input
              value={idSituacao}
              onChange={(ev) => setIdSituacao(ev.target.value)}
              type="select"
              name="situacao"
              id="situacao"
            >
              {statusAtendimento.map((status) => (
                <option value={status.id}>{status.descricao}</option>
              ))}
            </Input>
          </FormGroup>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-6">
          <FormGroup>
            <Label for="problema">Problema</Label>
            <Input
              className="textarea"
              type="textarea"
              name="problema"
              id="problema"
              onChange={(ev) => setProblema(ev.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <Label for="solucao">Solução</Label>
            <Input
              className="textarea"
              type="textarea"
              name="solucao"
              id="solucao"
              onChange={(ev) => setSolucao(ev.target.value)}
            />
          </FormGroup>
        </div>
      </div>

      <Link onClick={() => cadastrarAtendimento()} to="#">
        <BotaoConfirmar aguardando={aguardando} />
      </Link>
      <Link to={"painel-de-controle"}>
        <BotaoVoltar to="painel-de-controle" />
      </Link>
    </>
  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario.usuarioLogado,
  usuariosPesquisados: state.usuario.usuariosPesquisados,
  naturezasPesquisados: state.natureza.naturezasPesquisados,
  clientesPesquisados: state.cliente.clientesPesquisados,
  statusAtendimentoPesquisados:
    state.statusAtendimento.statusAtendimentoPesquisados,
});

export default connect(
  mapStateToProps,
  actionsAtendimento
)(AtendimentoCadastrar);
