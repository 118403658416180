import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionsAtendimento from "../../../domain/actions/actionsAtendimento";
import { Paginationbar } from "reactstrap-paginationbar";
import { FormGroup, Input, Label } from "reactstrap";
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import BotaoExcluir from "../../../components/BotaoExcluir";
import BotaoPesquisar from "../../../components/BotaoPesquisar";
import ModalApagar from "../../../components/ModalApagar";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalAtivar from "../../../components/ModalAtivar";
import ModalDesativar from "../../../components/ModalDesativar";
import ModalCarregando from "../../../components/ModalCarregando";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";

//style
import "../../../assets/styles/listarPagination.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../assets/styles/tabela.css";
import "../../../assets/styles/pagination.css";

class Listar extends Component {
  state = {
    clienteNome: "",
    statusId: "",
    solicitante: "",
    dataInicial: "", //format(new Date(), 'yyyy-MM-dd', { locale: pt })
    dataFinal: "",
    mensagem: "",
    erro: "",
    atencao: "",
    aguardando: false,
    confirmarExclusao: false,
    idParaExcluir: null,
    confirmarAtivacao: false,
    idParaAtivar: null,
    confirmarDesativacao: false,
    idParaDesativar: null,
    formularioAtencao: false,
    page: 0,
    numPages: 0,
    pesquisa: "",
  };

  ajustaDataInicio = (dataOriginal) => {
    dataOriginal
      ? this.setState({ dataInicial: dataOriginal })
      : this.setState({ dataInicial: "" });
  };

  ajustaDataFim = (dataOriginal) => {
    dataOriginal
      ? this.setState({ dataFinal: dataOriginal })
      : this.setState({ dataFinal: "" });
  };

  //Início rotina de exclusão
  abrirConfirmarExclusao(id) {
    this.setState({ confirmarExclusao: true });
    this.setState({ idParaExcluir: id });
  }

  fecharConfirmarExclusao() {
    this.setState({ confirmarExclusao: false });
  }

  apagarAtendimento() {
    this.setState({ aguardando: true });
    this.props.removerAtendimento(this.state.idParaExcluir, (retorno) => {
      if (retorno.erro.erro) {
        this.setState({ erro: retorno.erro.mensagem });
        this.setState({ aguardando: false });
      } else {
        this.setState({ mensagem: retorno.erro.mensagem });
        this.setState({ aguardando: false });
        this.fecharConfirmarExclusao();
        this.props.limparAtendimentos();
        this.pesquisarAtendimentos();
      }
    });
  }
  //Fim rotina de exclusão

  //Início rotina de ativação
  ativarAtendimento() {
    this.setState({ aguardando: true });
    this.props.ativarAtendimento(
      { id: this.state.idParaAtivar, ativo: true },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarAtivacao();
          this.props.limparAtendimentos();
          this.pesquisarAtendimentos();
        }
      }
    );
  }

  abrirConfirmarAtivacao(id) {
    this.setState({ confirmarAtivacao: true });
    this.setState({ idParaAtivar: id });
  }

  fecharConfirmarAtivacao() {
    this.setState({ confirmarAtivacao: false });
  }
  //Fim rotina de ativação

  //Início rotina de desativação
  desativarAtendimento() {
    this.setState({ aguardando: true });
    this.props.desativarAtendimento(
      { id: this.state.idParaDesativar, ativo: false },
      (retorno) => {
        if (retorno.erro.erro) {
          this.setState({ erro: retorno.erro.mensagem });
          this.setState({ aguardando: false });
        } else {
          this.setState({ mensagem: retorno.erro.mensagem });
          this.setState({ aguardando: false });
          this.fecharConfirmarDesativacao();
          this.pesquisarAtendimentos();
          this.props.limparAtendimentos();
        }
      }
    );
  }

  criticas() {
    const { dataInicial, dataFinal } = this.state;

    if (dataInicial && dataFinal && dataFinal < dataInicial)
      return this.setState({
        atencao: {
          mensagem: "A data final não pode ser menor que a data inicial.",
        },
      });

    return true;
  }

  abrirConfirmarDesativacao(id) {
    this.setState({ confirmarDesativacao: true });
    this.setState({ idParaDesativar: id });
  }

  fecharConfirmarDesativacao() {
    this.setState({ confirmarDesativacao: false });
  }
  //Fim rotina de desativação

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  //Início da rotina de pesquisa
  pesquisarAtendimentos() {
    const { clienteNome, solicitante, dataInicial, dataFinal, page, statusId } =
      this.state;

    if (this.props.location.state) {
      this.props.pesquisarAtendimentos({
        clienteNome,
        solicitante,
        dataInicial,
        dataFinal,
        id: this.props.location.state.statusId,
        page,
      });
    } else {
      this.props.pesquisarAtendimentos({
        clienteNome,
        solicitante,
        dataInicial,
        dataFinal,
        id: "",
      });
    }
  }

  pesquisarAtendimentosBotao() {
    const { clienteNome, solicitante, dataInicial, dataFinal, statusId, page } =
      this.state;

    if (dataInicial <= dataFinal) {
      this.props.pesquisarAtendimentosBotao({
        clienteNome,
        solicitante,
        dataInicial: dataInicial ? dataInicial + "T01:00:00Z" : "",
        dataFinal: dataFinal ? dataFinal + "T23:59:59Z" : "",
        id: this.props.location.state.statusId,
        page,
      });
    } else {
      this.props.pesquisarAtendimentosBotao({
        clienteNome,
        solicitante,
        dataInicial: dataInicial ? dataInicial + "T01:00:00Z" : "",
        dataFinal: dataFinal ? dataFinal + "T23:59:59Z" : "",
        id: this.props.location.state.statusId,
        page,
      });
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };
  //Fim da rotina de pesquisa

  componentDidMount() {
    const { location } = this.props;
    location.state
      ? this.pesquisarAtendimentos(location.state.statusId)
      : this.pesquisarAtendimentos("");
  }

  componentDidUpdate(nextProps, prevState) {
    if (
      this.state.clienteNome !== "" &&
      prevState.clienteNome !== this.state.clienteNome
    ) {
      this.pesquisarAtendimentosBotao("");
    } else {
      if (
        !this.props.location.state.statusId &&
        nextProps.atendimentosPesquisados
      )
        this.pesquisarAtendimentosBotao("");
    }
  }

  componentWillUnmount() {
    this.props.limparAtendimentos();
    this.props.limparAtendimento();
  }

  render() {
    const {
      clienteNome,
      solicitante,
      dataInicial,
      dataFinal,
      mensagem,
      atencao,
      aguardando,
      confirmarExclusao,
      confirmarAtivacao,
      confirmarDesativacao,
      formularioAtencao,
    } = this.state;

    var numElements = 1;
    if (
      this.props.atendimentosPesquisados &&
      this.props.atendimentosPesquisados.page.totalElements
    )
      numElements = this.props.atendimentosPesquisados.page.totalElements;

    var atendimentos = [];
    var pages = 0;

    if (
      this.props.atendimentosPesquisados &&
      this.props.atendimentosPesquisados._embedded
    ) {
      atendimentos = this.props.atendimentosPesquisados._embedded.servicos;
    }
    var qtde = atendimentos.length;
    if (qtde % 2 === 0) {
      qtde += 1;
    }

    return (
      <>
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        />
        {/* Component modal apagar */}
        <ModalApagar
          isOpen={confirmarExclusao}
          toggle={() => this.fecharConfirmarExclusao()}
          apagar="Atendimento"
          aguardando={aguardando}
          apagarObjeto={() => this.apagarAtendimento()}
        />
        {/* Component modal ativar */}
        <ModalAtivar
          isOpen={confirmarAtivacao}
          toggle={() => this.fecharConfirmarAtivacao()}
          ativar="Atendimento"
          aguardando={aguardando}
          ativarObjeto={() => this.ativarAtendimento()}
        />
        {/* Component modal desativar */}
        <ModalDesativar
          isOpen={confirmarDesativacao}
          toggle={() => this.fecharConfirmarDesativacao()}
          desativar="Atendimento"
          aguardando={aguardando}
          desativarObjeto={() => this.desativarAtendimento()}
        />
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Listagem de Atendimentos</h2>
          </div>
          <div>
            <FormGroup>
              <BotaoPesquisar
                onClickPesquisar={() => {
                  this.setState({ formularioAtencao: !this.criticas() });

                  {
                    if (this.criticas()) {
                      this.setState({ page: 0 });
                      this.props.limparAtendimentos();
                      this.pesquisarAtendimentosBotao("");
                    }
                  }
                }}
              />
            </FormGroup>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="col-sm-3">
            <FormGroup>
              <Label for="clienteNome">Cliente</Label>
              <Input
                type="text"
                value={clienteNome}
                name="clienteNome"
                id="clienteNome"
                autoComplete="clienteNome"
                onChange={(ev) => this.onChangeInput("clienteNome", ev)}
                placeholder="Filtar pelo cliente"
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="solicitante">Solicitante</Label>
              <Input
                id="solicitante"
                name="solicitante"
                onChange={(ev) => this.onChangeInput("solicitante", ev)}
                type="text"
                value={solicitante}
                autoComplete="solicitante"
                placeholder="Filtrar por Solicitante"
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="dataInicial">Início</Label>
              <Input
                type="date"
                name="dataInicial"
                id="dataInicial"
                placeholder="Data inicial"
                value={dataInicial}
                onChange={(ev) => {
                  this.ajustaDataInicio(ev.target.value);
                }}
              />
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label for="dataFinal">Fim</Label>
              <Input
                type="date"
                name="dataFinal"
                id="dataFinal"
                placeholder="Data final"
                value={dataFinal}
                onChange={(ev) => {
                  this.ajustaDataFim(ev.target.value);
                }}
              />
            </FormGroup>
          </div>
        </div>
        {!this.props.atendimentosPesquisados && (
          <ModalCarregando isOpen={true} pagina="Listagem de Atendimentos" />
        )}
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">ID</Th>
              <Th>Cliente</Th>
              <Th>Solicitante</Th>
              <Th className="text-center">Dt. Chamado</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {atendimentos.map((atendimento) => {
              var parity = qtde % 2;
              qtde = qtde - 1;

              return (
                <Tr
                  key={atendimento.id}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{atendimento.id}</Td>
                  <Td>
                    <Link to={`/sac/atendimentos-alterar/${atendimento.id}`}>
                      {atendimento.cliente.nome}
                    </Link>
                  </Td>
                  <Td>{atendimento.solicitante}</Td>
                  <Td className="text-center">
                    {format(
                      new Date(atendimento.dataEmissao),
                      "dd-MM-yyyy HH:mm",
                      { locale: pt }
                    )}
                  </Td>
                  <Td className="text-center ">
                    <span>
                      <BotaoExcluir
                        onClick={() =>
                          this.abrirConfirmarExclusao(atendimento.id)
                        }
                      />
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <br />
        {this.props.atendimentosPesquisados ? (
          <div>
            <Paginationbar
              totalItems={numElements}
              pageSize={50}
              current={this.state.page + 1}
              visibility={3}
              ellipsis={true}
              onTurnPage={(e) => {
                this.setState(
                  { page: e.page - 1 },
                  this.pesquisarAtendimentosBotao
                );
              }}
              className="pagination"
            />
          </div>
        ) : (
          false
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

const mapStateToProps = (state) => {
  return {
    atendimentosPesquisados: state.atendimento.atendimentosPesquisados,
  };
};

export default connect(mapStateToProps, actionsAtendimento)(Listar);
