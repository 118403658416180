import { BUSCAR_NATUREZA, LIMPAR_NATUREZA, LIMPAR_NATUREZAS, LISTAR_NATUREZAS, PESQUISAR_NATUREZAS } from '../../core/store/types';

const reducersNatureza = (state = {}, action) => {
    switch (action.type) {
        case BUSCAR_NATUREZA:
            return {
                ...state,
                natureza: action.payload,
            }
        case LIMPAR_NATUREZA:
            return {
                ...state,
                natureza: null,
            }
        case LIMPAR_NATUREZAS:
            return {
                ...state,
                naturezas: null,
                naturezasPesquisados: null
            }
        case LISTAR_NATUREZAS:
            return {
                ...state,
                naturezas: action.payload._embedded.naturezas,
            }
        case PESQUISAR_NATUREZAS:
            return {
                ...state,
                naturezasPesquisados: action.payload._embedded.naturezas,
            }
        default:
            return state;
    }
}
export default reducersNatureza;