import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import * as actionsUsuario from "../../../domain/actions/actionsUsuario";
import { FormGroup, Label, Input } from "reactstrap";
import BotaoConfirmar from "../../../components/BotaoConfirmar";
import BotaoVoltar from "../../../components/BotaoVoltar";
import validator from "validator";
import ModalAtencao from "../../../components/ModalAtencao";
import ModalErro from "../../../components/ModalErro";
import ModalSucesso from "../../../components/ModalSucesso";

class NaturezaAlterar extends Component {
  state = {
    id: "",
    nome: "",
    padrao: false,
    informaConclusao: false,
    invisivel: false,
    erro: "",
    sucesso: "",
    atencao: "",
    aguardando: false,
    dadosNatureza: false,
    formularioPronto: false,
    formularioSucesso: false,
    formularioAtencao: false,
    formularioErro: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getNatureza(id);
  }

  async componentDidUpdate(nextProps) {
    const { id } = this.props.match.params;
    if (!this.props.natureza && nextProps.natureza) this.props.getNatureza(id);
    await this.receberDadosNatureza();
  }

  receberDadosNatureza() {
    const { id } = this.props.match.params;

    if (
      typeof this.props.natureza !== "undefined" &&
      this.props.natureza !== null &&
      this.props.natureza.id == id &&
      !this.state.dadosNatureza
    ) {
      this.setState({ id: this.props.natureza.id });
      this.setState({ nome: this.props.natureza.nome });
      this.setState({ padrao: this.props.natureza.padrao });
      this.setState({ informaConclusao: this.props.natureza.informaConclusao });
      this.setState({ invisivel: this.props.natureza.invisivel });
      this.setState({ dadosNatureza: true });
    }
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  async alterarNatureza() {
    this.setState({ erro: "" });
    await this.receberDadosFormulario();

    this.setState({ formularioAtencao: !this.criticas() });
    if (!this.criticas()) return;

    this.setState({ aguardando: true });
    const { id, nome, padrao, informaConclusao, invisivel } = this.state;

    this.props.alterarNatureza(
      { id, nome, padrao, informaConclusao, invisivel },
      (err) => {
        if (err.erro.mensagem) {
          this.setState({ erro: { mensagem: err.erro.mensagem } });
          this.setState({ aguardando: false });
          this.setState({ formularioErro: true });
        } else {
          this.setState({ aguardando: false });
          this.setState({
            sucesso: { mensagem: "Natureza alterado com sucesso!" },
          });
          this.setState({ formularioSucesso: true });
        }
      }
    );
  }

  receberDadosFormulario() {
    this.setState({ id: document.querySelector("#id").value });
    this.setState({ nome: document.querySelector("#nome").value });
    this.setState({ padrao: document.querySelector("#padrao").value });
    this.setState({
      informaConclusao: document.querySelector("#informaConclusao").value,
    });
    this.setState({ invisivel: document.querySelector("#invisivel").value });
  }

  criticas() {
    const { nome } = this.state;
    if (!nome)
      return this.setState({ atencao: { mensagem: "Preencha o campo nome!" } });
    return true;
  }

  fecharFormularioAtencao = () => {
    this.setState({ formularioAtencao: false });
  };

  fecharFormularioErro = () => {
    this.setState({ formularioErro: false });
  };

  fecharFormularioSucesso = () => {
    this.setState({ formularioPronto: true });
  };

  render() {
    const {
      id,
      nome,
      padrao,
      informaConclusao,
      invisivel,
      aguardando,
      erro,
      sucesso,
      atencao,
      dadosNatureza,
      formularioPronto,
      formularioAtencao,
      formularioErro,
      formularioSucesso,
    } = this.state;

    if (formularioPronto) {
      return <Redirect to={{ pathname: "/sac/naturezas" }} />;
    }

    return (
      <>
        <div className="d-flex">
          <div className="mr-auto p-2">
            <h2 className="display-4 titulo">Natureza</h2>
          </div>
        </div>
        <hr />
        <ModalErro
          isOpen={formularioErro}
          toggle={() => this.fecharFormularioErro()}
          erro={erro}
        />
        <ModalAtencao
          isOpen={formularioAtencao}
          toggle={() => this.fecharFormularioAtencao()}
          atencao={atencao}
        />
        <ModalSucesso
          isOpen={formularioSucesso}
          toggle={() => this.fecharFormularioSucesso()}
          sucesso={sucesso}
        />
        <div className="form-group row">
          <div className="col-sm-2">
            <FormGroup>
              <Label for="id">ID</Label>
              <Input
                type="text"
                value={id}
                name="id"
                id="id"
                className="form-control"
                disabled={true}
              />
            </FormGroup>
          </div>
          <div className="col-sm-5">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                value={nome}
                name="nome"
                id="nome"
                className="form-control"
                placeholder={
                  dadosNatureza ? "Nome da Natureza" : "Carregado..."
                }
                disabled={dadosNatureza ? false : true}
                autoComplete="nome"
                onChange={(ev) => this.onChangeInput("nome", ev)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-1">
            <FormGroup className="checkbox-wrapper">
              <Label className="container" for="padrao">
                Padrão
              </Label>
              <Input
                className={padrao ? "checked" : ""}
                type="checkbox"
                value={padrao}
                name="padrao"
                id="padrao"
                onClick={() => this.setState({ padrao: !this.state.padrao })}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2">
            <FormGroup className="checkbox-wrapper">
              <Label className="container" for="informaConclusao">
                Informa Conclusão
              </Label>
              <Input
                style={{ marginLeft: "3.5rem" }}
                className={informaConclusao ? "checked" : ""}
                type="checkbox"
                value={informaConclusao}
                name="informaConclusao"
                id="informaConclusao"
                onClick={() =>
                  this.setState({
                    informaConclusao: !this.state.informaConclusao,
                  })
                }
              />
            </FormGroup>
          </div>
          <div className="col-sm-2">
            <FormGroup className="checkbox-wrapper">
              <Label className="container" for="invisivel">
                Invisível
              </Label>
              <Input
                //style={{ marginLeft: "3.5rem" }}
                className={invisivel ? "checked" : ""}
                type="checkbox"
                value={invisivel}
                name="invisivel"
                id="invisivel"
                onClick={() =>
                  this.setState({
                    invisivel: !this.state.invisivel,
                  })
                }
              />
            </FormGroup>
          </div>          
        </div>
        <Link onClick={() => this.alterarNatureza()} to="#">
          <BotaoConfirmar aguardando={aguardando} />
        </Link>
        <Link to={"/sac/naturezas"}>
          <BotaoVoltar />
        </Link>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  natureza: state.natureza.natureza,
});

export default connect(mapStateToProps, actionsUsuario)(NaturezaAlterar);
