import axios from "axios";
import {
  BUSCAR_APLICACAO,
  LIMPAR_APLICACAO,
  LIMPAR_APLICACOES,
  // LISTAR_APLICACOES,
  PESQUISAR_APLICACOES,
} from "../../core/store/types";
import { api } from "../../core/config";
import { headers } from "../../core/store/localStorage";
import errorHandler from "../../core/store/errorHandler";

export const limparAplicacao = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_APLICACAO });
  };
};

export const limparAplicacoes = () => {
  return (dispatch) => {
    dispatch({ type: LIMPAR_APLICACOES });
  };
};

export const removerAplicacao = (id, callback) => {
  return (dispatch) => {
    axios.delete(`${api}/v1/aplicacoes/${id}`, headers()).then((response) => {
      callback({ erro: response.data });
    });
  };
};

export const pesquisarAplicacoes = (callback) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/aplicacoes`, headers())
      .then((response) => {
        dispatch({ type: PESQUISAR_APLICACOES, payload: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const salvarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .post(`${api}/v1/aplicacoes`, dadosAplicacao, headers())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const getAplicacao = (id) => {
  return (dispatch) => {
    axios
      .get(`${api}/v1/aplicacoes/${id}`, headers())
      .then((response) => {
        dispatch({ type: BUSCAR_APLICACAO, payload: response.data });
      })
      .catch(errorHandler);
  };
};

export const alterarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/aplicacoes/${dadosAplicacao.id}`,
        { nome: dadosAplicacao.nome, descricao: dadosAplicacao.descricao, chaveAplicacao: dadosAplicacao.chaveAplicacao, padrao: dadosAplicacao.padrao },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const ativarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/aplicacoes/${dadosAplicacao.id}/ativa`,
        { ativo: dadosAplicacao.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};

export const desativarAplicacao = (dadosAplicacao, callback) => {
  return (dispatch) => {
    axios
      .put(
        `${api}/v1/aplicacoes/${dadosAplicacao.id}/desativa`,
        { ativo: dadosAplicacao.ativo },
        headers()
      )
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandler(err)));
  };
};
